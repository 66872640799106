import React, { Fragment } from 'react'
import { Box, HStack, Subheader } from '@revolut/ui-kit'
import styled from 'styled-components'
import { formatTimeAgo } from '@src/utils/format'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import DOMPurify from 'dompurify'
import { JobPostingInterface } from '@src/interfaces/jobPosting'

export const JobPostingDescriptionContent = styled(Box)`
  ol,
  ul {
    padding: 0 0 0 20px;
    margin: 0;
  }
`

type JobPostingContentHeaderProps = {
  jobPosting: JobPostingInterface
}

export const JobPostingContentHeader = ({ jobPosting }: JobPostingContentHeaderProps) => {
  return (
    <>
      <Subheader>
        <Subheader.Title>{jobPosting.name}</Subheader.Title>
      </Subheader>
      <Subheader variant="nested">
        <Subheader.Title>
          <HStack gap="s-8">
            Created
            {jobPosting.creation_date_time
              ? `
            ${formatTimeAgo(jobPosting.creation_date_time)} `
              : null}
            by <UserWithAvatar {...jobPosting.recruiter} />
          </HStack>
        </Subheader.Title>
      </Subheader>
    </>
  )
}

type JobPostingContentProps = {
  jobPosting: JobPostingInterface
}

const JobPostingContent = ({ jobPosting }: JobPostingContentProps) => {
  if (!jobPosting) {
    return null
  }
  return (
    <>
      <JobPostingContentHeader jobPosting={jobPosting} />
      {jobPosting.sections?.map(({ title, content }) => (
        <Fragment key={title}>
          <Subheader>
            <Subheader.Title>{title}</Subheader.Title>
          </Subheader>
          <JobPostingDescriptionContent
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(content),
            }}
          />
        </Fragment>
      ))}
    </>
  )
}

export default JobPostingContent
