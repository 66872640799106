import React, { useEffect, useState } from 'react'
import { Download } from '@revolut/icons'
import { Box, Button, Cell, Color, InputGroup, Link, Text } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

import { selectorKeys } from '@src/constants/api'
import LapeFileUploader from '@components/Inputs/LapeFields/LapeFileUploader'
import LoadingButton from '@components/Button/LoadingButton'
import Tooltip from '@components/Tooltip/Tooltip'
import { downloadDocumentTemplateFile } from '@src/api/documentsTemplates'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { useLapeContext } from '@src/features/Form/LapeForm'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { Grid } from '@src/components/CommonSC/Grid'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import BottomText from '@src/components/Inputs/partials/BottomText'
import { fileSectionName } from '.'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useGetSelectors } from '@src/api/selectors'

const General = () => {
  const { isSubmitting, values } = useLapeContext<DocumentsTemplatesInterface>()
  const [showUpload, setShowUpload] = useState(!values.id)

  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const params = useParams<{ id?: string }>()
  const { query, deleteQueryParam } = useQuery()

  const { data: documentTemplateTypesOptionsData } = useGetSelectors(
    selectorKeys.document_template_types,
  )
  const documentTemplateTypesOptions =
    documentTemplateTypesOptionsData?.filter(({ id }) => id !== 'esignature') || []

  useEffect(() => {
    // intermediate adjustment for smooth transition from old templates to the new ones
    if (query.generate === 'true') {
      deleteQueryParam('generate')
      values.template_type = { id: 'generate', name: 'Generate' }
    }
  }, [])

  useEffect(() => {
    setShowUpload(!values.id)
  }, [isSubmitting])

  useEffect(() => {
    // We can't save file to LocalStorage that is why I remove it if the data comes from there.
    if (values.file && !values.file.name) {
      values.file = undefined
      setShowUpload(true)
    }
  })

  const isGenerateType = values.template_type?.id === 'generate'
  const isRequestType = values.template_type?.id === 'request'

  const renderButtons = () => {
    if (!values.file_name) {
      return <Text variant="bullet">There are no files. Please upload a file.</Text>
    }
    return (
      <Grid flow="column" alignItems="center" justifyContent="start" gap={10}>
        <Text variant="bullet">{values.file_name}</Text>
        <Tooltip placement="top" text="Download the file">
          <LoadingButton
            customIcon={<Download size={20} />}
            onSubmit={() => downloadDocumentTemplateFile(values.id)}
          >
            Download
          </LoadingButton>
        </Tooltip>
      </Grid>
    )
  }

  return (
    <>
      <PageHeader
        title={params.id ? 'Edit document template' : 'New document template'}
        backUrl={
          params.id
            ? pathToUrl(ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW, params)
            : ROUTES.PEOPLE.DOCUMENTS.TEMPLATES
        }
      />

      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Details" />
          <InputGroup>
            <LapeNewInput label="Name" name="name" required />
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              selector={selectorKeys.employee}
            />
            <LapeRadioSelectInput
              label="Category"
              name="category"
              message={
                !isCommercial ? (
                  <Box>
                    Please make sure you select the appropriate document label for the
                    file you are uploading. If you cannot find a label that describes
                    precisely the document type, you can request one on the Revoluters
                    service desk{' '}
                    <Link
                      target="_blank"
                      href="https://revolut.atlassian.net/servicedesk/customer/portal/137/group/362/create/13105"
                    >
                      here
                    </Link>
                  </Box>
                ) : undefined
              }
              selector={selectorKeys.document_categories}
            />
            <LapeRadioSelectInput
              label="Template type"
              name="template_type"
              selector={() => Promise.resolve(documentTemplateTypesOptions)}
            />
            <LapeNewTextArea label="Description" name="description" rows={2} />
          </InputGroup>

          <NewStepperTitle title="Instructions" />
          <InputGroup>
            {isGenerateType && (
              <Cell>
                <Text color={Color.GREY_TONE_50}>
                  Please provide instructions to show on the document signature page.
                </Text>
              </Cell>
            )}
            {isRequestType && (
              <Cell>
                <Text color={Color.GREY_TONE_50}>
                  Please provide instructions to show on the document request page.
                </Text>
              </Cell>
            )}
            <Box>
              <LapeHTMLEditor
                name="instructions"
                placeholder="Instructions"
                height={500}
              />
              <BottomText bottomInfo="Employees will see this when they open the document preview" />
            </Box>
          </InputGroup>

          {values.template_type ? (
            <>
              <NewStepperTitle title={fileSectionName(values)} />
              <InputGroup>
                <Cell>
                  <Text color={Color.GREY_TONE_50}>
                    {isGenerateType
                      ? 'Please attach a document template with the relevant merge fields.'
                      : null}
                    {isRequestType
                      ? 'Please attach a sample document pdf file or image to go with the request instructions.'
                      : null}
                  </Text>
                </Cell>

                {showUpload && (
                  <LapeFileUploader
                    name="file"
                    attachButtonText={`Click to attach ${fileSectionName(
                      values,
                    ).toLowerCase()}`}
                  />
                )}
                {!showUpload && renderButtons()}
                {values.id && !showUpload && (
                  <Button
                    onClick={() => setShowUpload(true)}
                    variant="text"
                    size="sm"
                    p="0"
                  >
                    Reupload template
                  </Button>
                )}
              </InputGroup>
            </>
          ) : null}
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Document template successfully saved"
          previewUrl={ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default General
