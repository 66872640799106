import React, { useEffect } from 'react'
import { selectorKeys } from '@src/constants/api'
import { Group, InputGroup, Link } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { pathToUrl } from '@src/utils/router'
import { LapeCopyButton } from '@src/pages/Forms/FormButtons'
import { Grid } from '@components/CommonSC/Grid'
import {
  HiringProcessInterface,
  HiringStageType,
  OnlineTestPlatform,
} from '@src/interfaces/hiringProccess'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { navigateTo } from '@src/actions/RouterActions'
import { useHistory, useLocation } from 'react-router-dom'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import HiringStageDeleteAction, {
  getBackUrl,
} from '@src/features/HiringStageDeleteAction/HiringStageDeleteAction'
import { useGetSelectors } from '@src/api/selectors'
import { useGetCompanyHiringStage, useGetRoleHiringStage } from '@src/api/hiringProcess'
import StageDetails from '@src/pages/Forms/HiringStage/FormSections/StageDetails'
import LapeScorecardField from '@src/pages/Forms/HiringStage/FormSections/LapeScorecardField'
import EligibleInterviewers from '@src/pages/Forms/HiringStage/FormSections/EligibleInterviewers'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { IdAndName } from '@src/interfaces'

const getAfterCopyLink = (type: HiringStageType) => {
  switch (type) {
    case 'general':
      return pathToUrl(ROUTES.FORMS.HIRING_STAGES.GENERAL, {})
    case 'specialisation':
      return pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION, {})
    case 'role':
      return pathToUrl(ROUTES.FORMS.HIRING_STAGES.ROLE, {})
    default:
      return ''
  }
}

export interface Props {
  companyHiringStageId?: number
  roleHiringStageId?: number
  type: HiringStageType
  specialisation?: Partial<SpecialisationInterface>
}

const usePrefillHiringStage = (
  companyHiringStageId?: number,
  roleHiringStageId?: number,
) => {
  const { data: companyHiringStage, isLoading: loadingCompanyHiringStage } =
    useGetCompanyHiringStage(companyHiringStageId)
  const { data: roleHiringStage, isLoading: loadingRoleHiringStage } =
    useGetRoleHiringStage(roleHiringStageId)
  return {
    prefillHiringStage: companyHiringStage || roleHiringStage,
    loadingPrefillHiringStage: loadingCompanyHiringStage || loadingRoleHiringStage,
  }
}

export type HiringStageHistoryState = {
  history?: string[]
  role?: Partial<RoleInterface>
  specialisation?: Partial<SpecialisationInterface>
  companyHiringStageId?: number
  roleHiringStageId?: number
}

export const HiringStage = ({
  companyHiringStageId,
  roleHiringStageId,
  specialisation,
  type,
}: Props) => {
  const location = useLocation<HiringStageHistoryState | undefined>()
  const { values } = useLapeContext<HiringProcessInterface>()
  const { data: testPlatformOptions } = useGetSelectors<IdAndName<OnlineTestPlatform>>(
    selectorKeys.hiring_stage_test_platforms,
  )

  const history = useHistory<HiringStageHistoryState>()

  const { prefillHiringStage, loadingPrefillHiringStage } = usePrefillHiringStage(
    companyHiringStageId,
    roleHiringStageId,
  )
  useEffect(() => {
    if (prefillHiringStage) {
      values.title = prefillHiringStage.title
      values.stage_type = prefillHiringStage.stage_type
      values.test_platform = prefillHiringStage.test_platform
      values.skills = prefillHiringStage.skills
      values.scorecard_template = prefillHiringStage.scorecard_template
      values.duration = prefillHiringStage.duration
      values.duration_unit = prefillHiringStage.duration_unit
      values.playbook_link = prefillHiringStage.playbook_link
      values.interviewer_type = prefillHiringStage.interviewer_type
      values.interviewers_groups = prefillHiringStage.interviewers_groups
      values.interviewers = prefillHiringStage.interviewers
      values.is_limited_by_seniorities = prefillHiringStage.is_limited_by_seniorities
      if (values.is_limited_by_seniorities) {
        values.seniority_min = prefillHiringStage.seniority_min
        values.seniority_max = prefillHiringStage.seniority_max
      }
      // calling history.replace to remove companyHiringStageId and roleHiringStageId
      // to avoid prefilling again
      history.replace(history.location.pathname, {
        history: history.location.state.history,
        role: history.location.state.role,
        specialisation: history.location.state.specialisation,
      })
    }
  }, [prefillHiringStage])

  const isGeneral = type === 'general'
  const isRole = type === 'role'
  const isSpecialisation = type === 'specialisation'

  const isDisabled =
    !isGeneral &&
    (values?.is_company_standard_round ||
      (isSpecialisation && values?.limit_editing_in_specialisation))

  const isLastStage =
    values?.is_company_standard_round ||
    values?.limit_editing_in_specialisation ||
    isSpecialisation

  useEffect(() => {
    if (isRole && !values.id && location.state?.role) {
      values.role = { id: location.state.role.id!, name: location.state.role.name }
    } else if (isSpecialisation && !values.id && specialisation) {
      values.specialisation = {
        id: specialisation.id!,
        name: specialisation.name,
      }
    }
  }, [])

  const isOnlineTest = values.stage_type?.id === 'online_test'
  const isCVScreening = values.stage_type?.id === 'cv_screening'

  useEffect(() => {
    if (isOnlineTest && testPlatformOptions?.length && !values.test_platform) {
      values.test_platform = testPlatformOptions[0]
    }

    if (!isOnlineTest) {
      values.test_platform = undefined
      values.online_test = undefined
    }
  }, [testPlatformOptions, isOnlineTest])
  const renderAdvancedSettings = () => {
    return (
      <InputGroup>
        <Group>
          {isGeneral ? (
            <LapeNewSwitch
              name="is_company_standard_round"
              itemTypeProps={{ title: 'Company standard round' }}
              disabled
            />
          ) : (
            values?.is_company_standard_round && (
              <LapeNewSwitch
                disabled
                name="is_company_standard_round"
                itemTypeProps={{ title: 'Company standard round' }}
              />
            )
          )}

          <LapeNewSwitch
            disabled={isDisabled}
            name="is_limited_by_seniorities"
            itemTypeProps={{ title: 'Limited by seniorities' }}
          />
          {!isGeneral && (isRole || values?.limit_editing_in_specialisation) && (
            <LapeNewSwitch
              disabled={isDisabled}
              name="limit_editing_in_specialisation"
              itemTypeProps={{ title: 'Limit editing in specialisation' }}
            />
          )}
        </Group>
        {values?.is_limited_by_seniorities && (
          <InputGroup variant="horizontal">
            <LapeRadioSelectInput<HiringProcessInterface>
              name="seniority_min"
              label="Min Seniority"
              selector={selectorKeys.seniority}
            />
            <LapeRadioSelectInput<HiringProcessInterface>
              name="seniority_max"
              label="Max Seniority"
              selector={selectorKeys.seniority}
            />
          </InputGroup>
        )}
      </InputGroup>
    )
  }

  return (
    <>
      <PageBody>
        <AutoStepper>
          <Grid
            mb="s-32"
            flow="column"
            justifyContent="start"
            alignItems="center"
            gap={16}
          >
            {values.id && <HiringStageDeleteAction type={type} />}
            <LapeCopyButton type="hiring-stage" afterSubmitUrl={getAfterCopyLink(type)} />
          </Grid>
          <NewStepperTitle title="Stage details" />
          <StageDetails
            isDisabled={isDisabled}
            isSpecialisation={isSpecialisation}
            showOnlineTestSettings
            scorecard={
              <LapeScorecardField
                isClearable
                isDisabled={!!isDisabled}
                isRequired={values?.is_company_standard_round || isLastStage}
                message={
                  <Link
                    to={pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.SETTINGS)}
                    use={InternalLink}
                    target="_blank"
                  >
                    Add new scorecard
                  </Link>
                }
              />
            }
            isRole={isRole}
          />
          {!isOnlineTest && !isCVScreening && (
            <>
              <NewStepperTitle title="Eligible interviewers" />
              {<EligibleInterviewers isDisabled={isDisabled} isRequired={isLastStage} />}
            </>
          )}
          {!isCVScreening && (
            <>
              <NewStepperTitle title="Advanced settings" />
              {renderAdvancedSettings()}
            </>
          )}
        </AutoStepper>
      </PageBody>
      {!isDisabled && (
        <PageActions>
          <NewSaveButtonWithPopup<HiringProcessInterface>
            successText={values.id ? 'Hiring stage updated' : 'Hiring stage created'}
            onAfterSubmit={() => {
              navigateTo(getBackUrl(type, values), {
                stageId: values.id,
              })
            }}
            disabled={loadingPrefillHiringStage}
            pending={loadingPrefillHiringStage}
            useValidator
          />
        </PageActions>
      )}
    </>
  )
}

export default connect((props: Props) => {
  const location = useLocation<HiringStageHistoryState | undefined>()
  const { companyHiringStageId, roleHiringStageId, specialisation } = location.state ?? {}
  return (
    <HiringStage
      {...props}
      companyHiringStageId={companyHiringStageId}
      roleHiringStageId={roleHiringStageId}
      specialisation={specialisation}
    />
  )
})
