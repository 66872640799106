import React from 'react'
import { Flex, InputGroup, Text } from '@revolut/ui-kit'
import PredefinedLabel from '@src/pages/Careers/CareersApplication/PredefinedSectionLabel'
import { OptionInterface } from '@src/interfaces/selectors'
import { CareersPosition } from '@src/interfaces/careers'
import { useCareersGeneralSectionFields } from '@src/pages/Careers/hooks/useCareersGeneralSectionFields'
import { connect } from 'lape'
import SectionLayout from './components/Section'

type Props = {
  locked?: boolean
  countries?: OptionInterface[]
  position?: CareersPosition
}

const CareersApplicationGeneral = ({ locked, position, countries }: Props) => {
  const fields = useCareersGeneralSectionFields({
    locked,
    position,
    countries,
  })

  return (
    <SectionLayout
      header={
        <Flex justifyContent="space-between" alignItems="center">
          <Text variant="h4" display="block">
            General information
          </Text>
          {locked && <PredefinedLabel />}
        </Flex>
      }
      body={<InputGroup>{fields.map(field => field.node)}</InputGroup>}
      locked={locked}
    />
  )
}

export default connect(CareersApplicationGeneral)
