import {
  Flex,
  HStack,
  Icon,
  Tag,
  Text,
  TextButton,
  Token,
  Link as UiKitLink,
} from '@revolut/ui-kit'
import GraphIconChart from '@src/components/Charts/GraphIconChart/GraphIconChart'
import { ColoredPercent } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { PercentageWrapper } from '@src/components/CommonSC/TableStatistics'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { selectorKeys } from '@src/constants/api'
import {
  CellInsertParams,
  CellTypes,
  ColumnInterface,
  FilterType,
} from '@src/interfaces/data'
import { GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import { EpicOption, KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import capitalize from 'lodash/capitalize'
import React, { ReactNode, useState } from 'react'
import { deleteGoal, fetchGoalGraph } from '@src/api/goals'
import { getStatusColor } from '@src/components/CommonSC/General'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import { EditableColumnInterface } from '@src/components/Table/EditableTable/EditableTable'
import { roundFloat } from '@src/utils/numbers'
import {
  DraftWrap,
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiTargetColumn,
  kpiUnitColumn,
} from './kpi'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { IdAndName, Statuses } from '@src/interfaces'
import { useSubmitFlowHelpers } from '@src/pages/Forms/GoalForm/common/utils'
import { captureException } from '@sentry/react'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { LocationDescriptor } from 'history'
import { GoalStatusDropdown } from '@src/features/Goals/components/GoalStatusDropdown'
import omit from 'lodash/omit'
import KPINameWithSQLError from '@src/components/ColumnInserts/KPINameWithSQLError/KPINameWithSQLError'

// number would be a child row before real values are fetched. Solution is copied from KPIs due to the lack of time
type GoalColumnInterface = ColumnInterface<GoalsInterface | number>

const isGoal = (input: GoalsInterface | number): input is GoalsInterface =>
  typeof input !== 'number'

const isChildRow = (data: GoalsInterface | KpiInterface): data is KpiInterface => {
  return typeof data.update_type !== 'object'
}

const renderUpdateTypeTag = (type?: UpdateTypes) => {
  const value = type === UpdateTypes.sql ? 'SQL' : (type && capitalize(type)) || null

  return value ? (
    <Tag variant="outlined" color={Token.color.deepGrey}>
      {value}
    </Tag>
  ) : (
    ''
  )
}

const renderGoalName = ({ name }: { name?: string }) => {
  return (
    name || (
      <Text color={Token.color.greyTone20}>
        <i>[Unnamed goal]</i>
      </Text>
    )
  )
}

export const goalsNameColumn = (
  getGoalUrl?: (goal: GoalsInterface) => string | LocationDescriptor<unknown>,
): GoalColumnInterface => ({
  title: 'Goals',
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'name',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.text,
  dynamicHyperlinks: data => {
    if (isGoal(data)) {
      if (getGoalUrl) {
        return getGoalUrl(data)
      }
      const route =
        data.status.id === Statuses.draft
          ? ROUTES.FORMS.GOAL.EDIT
          : ROUTES.FORMS.GOAL.PREVIEW

      return pathToUrl(route, { id: data.id })
    }
    return null
  },
  insert: ({ data }) => (isGoal(data) ? renderGoalName({ name: data.name }) : ''),
})

export const goalsInitialValueColumn: GoalColumnInterface = {
  title: 'Initial',
  type: CellTypes.insert,
  idPoint: 'initial_value',
  dataPoint: 'initial_value',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isGoal(data)) {
      return data.kpis[0]?.initial_value
    }
    return ''
  },
}

export const goalsCurrentValueColumn: GoalColumnInterface = {
  title: 'Current',
  type: CellTypes.insert,
  idPoint: 'current_progress',
  dataPoint: 'current_progress',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isGoal(data)) {
      return data.kpis[0]?.current_progress
    }
    return ''
  },
}

export const goalsTargetColumns: GoalColumnInterface = {
  title: 'Target',
  type: CellTypes.insert,
  idPoint: 'target',
  dataPoint: 'target',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isGoal(data)) {
      return data.kpis[0]?.target
    }
    return ''
  },
}

export const goalsStrategyColumn: GoalColumnInterface = {
  title: 'Strategy',
  type: CellTypes.insert,
  idPoint: 'kpi_goal',
  dataPoint: 'kpi_goal',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isGoal(data)) {
      return capitalize(data.kpis[0]?.kpi_goal?.replaceAll('_', ' ') || '')
    }
    return ''
  },
}

export const goalsTypeColumn: GoalColumnInterface = {
  title: 'Type',
  type: CellTypes.insert,
  idPoint: 'update_type',
  dataPoint: 'update_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isGoal(data)) {
      return renderUpdateTypeTag(data.kpis[0]?.update_type)
    }
    return ''
  },
}

export const goalsStatusColumn: GoalColumnInterface = {
  title: 'Status',
  type: CellTypes.insert,
  idPoint: 'status__id',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.goal_statuses,
  insert: ({ data }) => {
    if (isGoal(data)) {
      return <Text color={getStatusColor(data.status.id)}>{data.status.name}</Text>
    }
    return ''
  },
}

export const goalsChangeStatusColumn = (
  onStatusChanged: (newStatus: IdAndName<Statuses>, goalId: number) => void,
): GoalColumnInterface => ({
  title: 'Status',
  type: CellTypes.insert,
  idPoint: 'status__id',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.goal_statuses,
  insert: ({ data }) => {
    if (isGoal(data)) {
      return (
        <GoalStatusDropdown
          goal={data}
          onStatusChanged={status => onStatusChanged(status, data.id)}
        />
      )
    }
    return ''
  },
})

export const goalsApprovalStatusColumn: GoalColumnInterface = {
  title: 'Approval',
  type: CellTypes.insert,
  idPoint: 'approval_status__id',
  dataPoint: 'approval_status',
  sortKey: 'approval_status',
  filterKey: 'approval_status',
  selectorsKey: selectorKeys.goal_approval_statuses,
  insert: ({ data }) => {
    if (
      !isGoal(data) ||
      data.status.id === Statuses.draft ||
      data.status.id === Statuses.archived
    ) {
      return ''
    }

    switch (data.approval_status.id) {
      case ApprovalStatuses.Pending:
        return (
          <Tooltip text="Pending" placement="left" justifyContent="center">
            <Icon name="Time" size={16} color={getStatusColor(data.approval_status.id)} />
          </Tooltip>
        )
      case ApprovalStatuses.RequiresChanges:
        return (
          <Tooltip text="Requires changes" placement="left" justifyContent="center">
            <Icon
              name="ArrowExchange"
              size={16}
              color={getStatusColor(data.approval_status.id)}
            />
          </Tooltip>
        )
      case ApprovalStatuses.Approved:
        return (
          <Tooltip text="Approved" placement="left" justifyContent="center">
            <Icon
              name="Check"
              size={16}
              color={getStatusColor(data.approval_status.id)}
            />
          </Tooltip>
        )
      default:
        return ''
    }
  },
}

export const goalsProgressColumn: GoalColumnInterface = {
  title: 'Progress',
  type: CellTypes.insert,
  idPoint: 'performance',
  dataPoint: 'performance',
  sortKey: 'progress',
  filterKey: 'progress',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isGoal(data)) {
      const progress = data.calibrated_progress ?? data.progress
      return (
        <GraphIconChart id={data.id} fetchData={fetchGoalGraph}>
          <Flex width={75} justifyContent="space-between">
            <PercentageWrapper>
              <ColoredPercent percent={progress * 100} />
              <Icon name="BarChart" size={16} />
            </PercentageWrapper>
          </Flex>
        </GraphIconChart>
      )
    }
    return ''
  },
}

export const goalsOwnerColumn: GoalColumnInterface = {
  type: CellTypes.insert,
  idPoint: 'owner',
  dataPoint: 'owner',
  sortKey: 'owner__full_name',
  filterKey: 'owner_id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => (isGoal(data) ? <UserWithAvatar {...data.owner} /> : ''),
}

export const goalsWeightColumn: GoalColumnInterface = {
  title: 'Weight',
  type: CellTypes.insert,
  idPoint: 'weight',
  dataPoint: 'weight',
  sortKey: 'weight',
  filterKey: 'weight',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (!isGoal(data)) {
      return ''
    }
    return typeof data.weight !== 'undefined' ? `${roundFloat(data.weight, 2)}%` : ''
  },
}

export const goalsInputWeightColumn: EditableColumnInterface<GoalsInterface> = {
  inputType: TableCellInputType.positiveFloat,
  suffix: '%',
  title: 'Weight',
  idPoint: 'weight',
  dataPoint: 'weight',
  sortKey: 'weight',
  filterKey: 'weight',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  isEditable: data => !isChildRow(data),
  cleanZeroOnFocus: true,
}

export const goalsUnitColumn: GoalColumnInterface = {
  title: 'Unit',
  type: CellTypes.insert,
  idPoint: 'unit',
  dataPoint: 'unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isGoal(data)) {
      return data.kpis[0]?.unit
    }
    return ''
  },
}

const DeleteGoalAction = ({
  goal,
  onAfterDelete,
}: {
  goal: GoalsInterface
  onAfterDelete: () => void
}) => {
  const [pending, setPending] = useState(false)
  const { confirm, confirmationDialog, showError } = useSubmitFlowHelpers()

  const handleDelete = async () => {
    try {
      const { status } = await confirm({
        variant: 'compact',
        label: 'Confirm delete',
        body: 'Are you sure you want to delete this goal?',
        yesBtnVariant: 'negative',
      })

      if (status === 'confirmed') {
        setPending(true)

        await deleteGoal(goal.id)

        onAfterDelete()
      }
    } catch (err) {
      captureException(err)
      showError('Failed to delete goal', 'Please, try again')
    } finally {
      setPending(false)
    }
  }
  return (
    <>
      <TextButton
        onClick={ev => {
          ev.preventDefault()
          handleDelete()
        }}
        disabled={pending}
      >
        <Icon name={pending ? 'Loading' : 'Delete'} size={16} color={Token.color.red} />
      </TextButton>
      {confirmationDialog}
    </>
  )
}

export const goalsActionsColumn = ({
  onAfterDelete,
}: {
  onAfterDelete: () => void
}): GoalColumnInterface => ({
  title: '',
  type: CellTypes.insert,
  idPoint: 'actions',
  dataPoint: 'actions',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return isGoal(data) ? (
      <Flex gap="s-8" justifyContent="flex-end">
        <DeleteGoalAction goal={data} onAfterDelete={onAfterDelete} />
      </Flex>
    ) : (
      ''
    )
  },
})

// goal target columns

const isEpic = (row: KpiInterface | GoalKpiDetails | EpicOption): row is EpicOption => {
  return 'key' in row
}

const insertEmptyWhenEpic =
  (insertCb: (props: CellInsertParams<KpiInterface>) => ReactNode) =>
  (props: CellInsertParams<KpiInterface | EpicOption>) => {
    if (isEpic(props.data)) {
      return ''
    }

    return insertCb(props as CellInsertParams<KpiInterface>)
  }

export const goalTargetReviewCycleColumn: ColumnInterface<GoalKpiDetails | EpicOption> = {
  type: CellTypes.insert,
  idPoint: 'review_cycle.cycle_id',
  dataPoint: 'review_cycle',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isEpic(data)) {
      return ''
    }
    // only one target can be inside goal kpi
    const target =
      (data.targets && data.targets[0]) || (data.target_epics && data.target_epics[0])

    if (target?.employee_cycle) {
      return target.employee_cycle.name
    }

    return target?.review_cycle?.name || ' - '
  },
  title: 'Cycle',
}

export const goalTargetUpdateTypeColumn = {
  idPoint: 'update_type',
  dataPoint: 'update_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  type: CellTypes.insert,
  title: 'Type',
  insert: ({ data }) => {
    if (isEpic(data)) {
      return ''
    }

    return renderUpdateTypeTag(data.update_type)
  },
} as ColumnInterface<GoalKpiDetails | EpicOption>

export const goalTargetNameColumn = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  selectorsKey: selectorKeys.kpi_names,
  title: 'Name',
  sortKey: null,
  filterKey: null,
  dynamicHyperlinks: data => {
    if (isEpic(data)) {
      return null
    }
    return kpiGenericNameColumn.dynamicHyperlinks!(omit(data, 'status') as KpiInterface)
  },
  insert: props => {
    const { data } = props
    if (isEpic(data)) {
      return (
        <HStack space="s-12">
          <UiKitLink href={data.url} target="_blank">
            {data.key}:{' '}
          </UiKitLink>
          <Text>{data.name}</Text>
        </HStack>
      )
    }
    return (
      <KPINameWithSQLError error={data.extra?.error}>
        <>
          {data.status.id === Statuses.draft && <DraftWrap>Draft</DraftWrap>}
          {data.name}
        </>
      </KPINameWithSQLError>
    )
  },
} as ColumnInterface<GoalKpiDetails | EpicOption>

export const goalTargetPerformanceColumn = {
  ...kpiPerformanceColumn,
  sortKey: null,
  filterKey: null,
  insert: insertEmptyWhenEpic(kpiPerformanceColumn.insert!),
} as ColumnInterface<GoalKpiDetails | EpicOption>

export const goalTargetInitialValueColumn = {
  ...kpiInitialValueColumn,
  sortKey: null,
  filterKey: null,
  textAlign: 'left',
  insert: insertEmptyWhenEpic(kpiInitialValueColumn.insert!),
} as ColumnInterface<GoalKpiDetails | EpicOption>

export const goalTargetCurrentValueColumn = {
  ...kpiCurrentValueColumn,
  sortKey: null,
  filterKey: null,
  textAlign: 'left',
  insert: insertEmptyWhenEpic(kpiCurrentValueColumn.insert!),
} as ColumnInterface<GoalKpiDetails | EpicOption>

export const goalTargetTargetValueColumn = {
  ...kpiTargetColumn,
  sortKey: null,
  filterKey: null,
  textAlign: 'left',
  insert: insertEmptyWhenEpic(kpiTargetColumn.insert!),
} as ColumnInterface<GoalKpiDetails | EpicOption>

export const goalTargetUnitColumn = {
  ...kpiUnitColumn,
  type: CellTypes.insert,
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => {
    if (isEpic(data)) {
      return ''
    }
    return data[kpiUnitColumn.dataPoint as keyof KpiInterface]
  },
} as ColumnInterface<GoalKpiDetails | EpicOption>

export const goalTargetActionsColumn = ({
  onSelect,
  onDelete,
  pendingDeleteId,
}: {
  onSelect?: (kpi: GoalKpiDetails) => void
  onDelete?: (kpi: GoalKpiDetails) => void
  pendingDeleteId?: number
}): ColumnInterface<GoalKpiDetails | EpicOption> => ({
  type: CellTypes.insert,
  idPoint: 'actions_column',
  dataPoint: 'actions_column',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  textAlign: 'right',
  insert: ({ data }) => {
    if (isEpic(data)) {
      return ''
    }

    return data.update_type === UpdateTypes.cascaded ? null : (
      <Flex gap="s-8" justifyContent="flex-end">
        {!!onSelect && (
          <TextButton onClick={() => onSelect(data)}>
            <Icon name="Pencil" size={16} color={Token.color.greyTone50} />
          </TextButton>
        )}
        {!!onDelete && (
          <TextButton
            disabled={pendingDeleteId !== undefined}
            onClick={() => onDelete(data)}
          >
            <Icon
              name={pendingDeleteId === data.id ? 'Loading' : 'Delete'}
              size={16}
              color={Token.color.greyTone50}
            />
          </TextButton>
        )}
      </Flex>
    )
  },
  title: '',
})

// to be used in relatives such as targets or roadmaps
export const foreignGoalColumn: ColumnInterface<KpiInterface | RoadmapInterface> = {
  title: 'Goal',
  type: CellTypes.insert,
  idPoint: 'goal_id',
  dataPoint: 'goal_name',
  sortKey: 'goal_name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  dynamicHyperlinks: data =>
    data.goal ? pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: data.goal?.id }) : null,
  insert: ({ data }) => (data.goal ? renderGoalName({ name: data.goal?.name }) : ''),
}
