import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Avatar,
  Banner,
  Cell,
  IconButton,
  InputGroup,
  Item,
  MoreBar,
  Subheader,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { IdAndName } from '@src/interfaces'
import { DocumentsCollectionsInterface } from '@src/interfaces/documentsCollections'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { SelectTemplatesSidebar } from '@src/apps/People/Documents/BulkRequest/Edit/Basics/SelectTemplatesSidebar'
import { pathToUrl } from '@src/utils/router'

export const General = () => {
  const params = useParams<{ id?: string }>()
  const { values, submit } = useLapeContext<DocumentsCollectionsInterface>()
  const [isTemplatesSidebarOpen, setIsTemplatesSidebarOpen] = useState(false)
  const [selectedTemplates, setSelectedTemplates] = useState<IdAndName[]>(
    values.document_templates?.map(template => ({
      id: template.id!,
      name: template.name!,
    })) || [],
  )

  return (
    <>
      <PageHeader
        backUrl={
          params.id
            ? pathToUrl(ROUTES.FORMS.DOCUMENT_COLLECTION.PREVIEW, params)
            : ROUTES.APPS.DOCUMENTS.COLLECTIONS
        }
        title={params.id ? 'Edit documents collection' : 'New documents collection'}
      />
      <PageBody>
        <VStack space="s-32">
          <Banner>
            <Banner.Avatar>
              <Avatar useIcon="Lightbulb" />
            </Banner.Avatar>
            <Banner.Content>
              <Banner.Description>
                Collections allow HR managers to efficiently manage and request multiple
                documents or templates simultaneously. A great example is onboarding
                documents, for example, Onboarding Documents for the UK{' '}
              </Banner.Description>
            </Banner.Content>
          </Banner>
          <VStack space="s-16">
            <Subheader>
              <Subheader.Title>What should your collection be called?</Subheader.Title>
            </Subheader>
            <InputGroup>
              <LapeNewInput label="Name" name="name" required />
            </InputGroup>
          </VStack>
          <VStack space="s-16">
            <Subheader>
              <Subheader.Title>Which templates do you want to include?</Subheader.Title>
            </Subheader>
            <Cell>
              <VStack space="s-16" width="100%">
                <MoreBar>
                  <MoreBar.Action
                    onClick={() => setIsTemplatesSidebarOpen(true)}
                    useIcon="Plus"
                  >
                    Add templates
                  </MoreBar.Action>
                </MoreBar>
                {!!selectedTemplates?.length && (
                  <VStack>
                    {selectedTemplates.map(template => (
                      <Item key={template.id} px={0}>
                        <Item.Avatar>
                          <Avatar useIcon="Document" />
                        </Item.Avatar>
                        <Item.Content>
                          <Item.Title>{template.name}</Item.Title>
                        </Item.Content>
                        <Item.Side>
                          <IconButton
                            aria-label={`Delete template ${template.name}`}
                            color={Token.color.greyTone20}
                            onClick={() =>
                              setSelectedTemplates(
                                selectedTemplates.filter(t => t.id !== template.id),
                              )
                            }
                            useIcon="Delete"
                          />
                        </Item.Side>
                      </Item>
                    ))}
                  </VStack>
                )}
              </VStack>
            </Cell>
          </VStack>
        </VStack>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          hideWhenNoChanges={false}
          previewUrl={ROUTES.FORMS.DOCUMENT_COLLECTION.PREVIEW}
          onClick={async () => {
            values.document_templates = selectedTemplates.length
              ? [
                  ...selectedTemplates.map(t => ({
                    id: t.id,
                  })),
                ]
              : []

            return submit()
          }}
          successText="Documents collection successfully saved"
          useValidator
        />
      </PageActions>

      <SelectTemplatesSidebar
        initTemplates={selectedTemplates}
        isOpen={isTemplatesSidebarOpen}
        onClose={() => setIsTemplatesSidebarOpen(false)}
        onSubmit={newTemplates => setSelectedTemplates(newTemplates)}
      />
    </>
  )
}
