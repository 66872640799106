import React from 'react'
import {
  DeliverableJustificationPreview,
  DeliverableOptions,
  PerformanceRating,
  Ratings,
  ReviewScorecardInterface,
  SectionType,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import {
  Badge,
  Box,
  Cell,
  Flex,
  InputGroup,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { get, lowerCase, set } from 'lodash'
import { DeliverablesJustifications } from '@components/ScorecardGeneral/DeliverablesJustifications'
import { RadioButton } from '@components/Inputs/RadioButtons/RadioButtons'
import { PerformanceRatingTitle } from '@src/constants/performance'
import {
  CardContentTypes,
  cardHasMissingValues,
  CommonSectionProps,
  deliverablesRating,
  getRoundedRating,
  PerformanceRatingToGraphNumber,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import BottomText from '@components/Inputs/partials/BottomText'
import { ExceedingExpectationTooltip } from '@src/pages/Forms/EmployeePerformanceLayout/components/ExceedingExpectationTooltip'

interface DeliverablesGradeProps {
  isViewMode: boolean
  isChecked: boolean
  exceedingExpectation: boolean
  ratings: Ratings[]
  option: { key: DeliverableOptions; text: string; description: string[] }
  onChange: () => void
}

export const DeliverablesGrade = ({
  isViewMode,
  isChecked,
  exceedingExpectation,
  ratings,
  option,
  onChange,
}: DeliverablesGradeProps) => {
  const tooltip = useTooltip()

  return (
    <Flex
      backgroundColor={
        exceedingExpectation
          ? Token.color.orange_5
          : isChecked
          ? Token.color.greyTone2
          : undefined
      }
      p="s-16"
      borderRadius={Token.radius.r16}
      justifyContent="space-between"
      data-testid={`deliverables-${lowerCase(option.text)}`}
    >
      <RadioButton
        label={
          <VStack space="s-4" ml="s-16" width="100%">
            <Flex
              alignItems="center"
              gap="s-8"
              width="fit-content"
              {...tooltip.getAnchorProps()}
            >
              <Text variant="primary">{option.text}</Text>
              {!!ratings.length && isViewMode && (
                <Badge
                  minWidth="s-20"
                  height="s-16"
                  backgroundColor={Token.color.deepGrey}
                  style={{ fontWeight: 500 }}
                >
                  {ratings.length}
                  <Tooltip {...tooltip.getTargetProps()} maxWidth={200} placement="top">
                    <VStack>
                      <Text variant="small" fontWeight="bold">
                        {PerformanceRatingTitle[ratings[0].value]}
                      </Text>
                      {ratings.map((rating: Ratings) => (
                        <Text key={rating.value} variant="small">
                          {rating.review?.reviewer?.full_name}
                        </Text>
                      ))}
                    </VStack>
                  </Tooltip>
                </Badge>
              )}
            </Flex>
            {!!option.description?.length && (
              <ul style={{ margin: 0, paddingInlineStart: 16 }}>
                {option.description.map((item, index) => (
                  <li key={index} style={{ color: Token.color.greyTone50 }}>
                    <Text variant="caption">{item}</Text>
                  </li>
                ))}
              </ul>
            )}
          </VStack>
        }
        checked={isChecked}
        disabled={isViewMode}
        disableLabel={false}
        onChange={onChange}
        alignTop
        capitalize={false}
      />
      {!!exceedingExpectation && <ExceedingExpectationTooltip />}
    </Flex>
  )
}

export const Deliverables = connect(
  ({ reviewData, isViewMode = false }: CommonSectionProps) => {
    const { errors, submitFailed } = useLapeContext<ReviewScorecardInterface>()

    const deliverablesGrades =
      reviewData?.deliverables?.cards?.[0].sections?.[0]?.type ===
      SectionType.SingleChoice
        ? reviewData?.deliverables.cards[0].sections[0].options.map(option => {
            const description = deliverablesRating.find(
              rating => rating.key === option.key,
            )?.description || [option.text]
            return {
              key: option.key,
              text: PerformanceRatingTitle[option.key],
              description,
            }
          })
        : []

    const renderError = () => {
      if (!submitFailed) {
        return null
      }

      const hasErrors =
        errors.review_data?.deliverables?.cards?.[0]?.sections &&
        cardHasMissingValues(
          CardContentTypes.DELIVERABLES,
          reviewData?.deliverables?.cards?.[0],
        )

      if (!hasErrors) {
        return null
      }

      return <BottomText error="Please select one of the options" />
    }

    const getFieldDetails = (option: {
      key: string
      text: string
      description: string[]
    }) => {
      const ratingValue = get(reviewData, 'deliverables.cards.0.sections.0.value')
      const expectedValue = get(reviewData, 'deliverables.rating_expectation')
      const isChecked =
        deliverablesGrades.find(item => item.key === ratingValue)?.key === option.key
      const exceedingExpectation =
        isChecked &&
        PerformanceRatingToGraphNumber[option.key as PerformanceRating] >
          PerformanceRatingToGraphNumber[expectedValue as PerformanceRating]

      return { isChecked, exceedingExpectation, ratings: [] }
    }
    const getViewFieldDetails = (option: {
      key: string
      text: string
      description: string[]
    }) => {
      const ratings =
        get(reviewData, 'deliverables.ratings')?.filter((rating: Ratings) => {
          return getRoundedRating(rating.value) === option.key
        }) || []
      const expectedValue = get(reviewData, 'deliverables.rating_expectation')
      const isChecked = !!ratings.length
      const exceedingExpectation =
        isChecked &&
        PerformanceRatingToGraphNumber[option.key as PerformanceRating] >
          PerformanceRatingToGraphNumber[expectedValue as PerformanceRating]

      return { isChecked, exceedingExpectation, ratings }
    }

    return (
      <Cell>
        <VStack space="s-16" data-testid="deliverables-section">
          <Text variant="h4">Contribution and impact made towards deliverables</Text>
          {(!isViewMode || !!reviewData?.deliverables?.justifications?.length) && (
            <Box
              border={`1px solid ${Token.color.greyTone10}`}
              borderRadius={Token.radius.r16}
            >
              <DeliverablesJustifications
                viewMode={isViewMode}
                viewJustifications={
                  isViewMode
                    ? (reviewData?.deliverables
                        ?.justifications as DeliverableJustificationPreview[])
                    : undefined
                }
              />
            </Box>
          )}
          {renderError()}
          <InputGroup>
            {deliverablesGrades.map((option, optionIdx) => {
              const { isChecked, exceedingExpectation, ratings } = isViewMode
                ? getViewFieldDetails(option)
                : getFieldDetails(option)
              return (
                <DeliverablesGrade
                  key={optionIdx}
                  isViewMode={isViewMode}
                  isChecked={isChecked}
                  exceedingExpectation={exceedingExpectation}
                  ratings={ratings}
                  option={option}
                  onChange={() => {
                    reviewData?.deliverables?.cards?.forEach((_, ind) => {
                      set(
                        reviewData,
                        `deliverables.cards.${ind}.sections.0.value`,
                        option.key,
                      )
                    })
                  }}
                />
              )
            })}
          </InputGroup>
        </VStack>
      </Cell>
    )
  },
)
