import { API, selectorKeys } from '../constants/api'
import { apiWithoutHandling, onboardingHandleError } from './index'
import { OptionInterface } from '../interfaces/selectors'
import { QueryKeyT, useFetch } from '@src/utils/reactQuery'
import { ApiError, ApiVersion } from '@src/interfaces'
import { UseQueryOptions } from 'react-query'
import { FilterByInterface, SortByInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getSelectors = async (
  type: selectorKeys,
  apiVersion?: ApiVersion,
  apiHandler = apiWithoutHandling,
) =>
  apiHandler
    .get<{ options: OptionInterface[] }>(
      API.SELECTORS,
      {
        params: {
          name: type,
        },
      },
      apiVersion,
    )
    .catch(onboardingHandleError)

export const useGetSelectors = <T = OptionInterface>(
  type: selectorKeys | null,
  apiVersion?: ApiVersion,
  queryOption?: UseQueryOptions<{ options: T[] }, ApiError, { options: T[] }, QueryKeyT>,
) => {
  const context = useFetch<{ options: T[] }>(
    type ? API.SELECTORS : null,
    apiVersion,
    {
      params: {
        name: type,
      },
    },
    undefined,
    queryOption || undefined,
  )

  return { ...context, data: context.data?.options }
}

interface UseGetSelectorsWithFiltersProps<T> {
  type: selectorKeys | null
  apiVersion?: ApiVersion
  queryOptions?: UseQueryOptions<{ options: T[] }, ApiError, { options: T[] }, QueryKeyT>
  filters?: FilterByInterface[]
  sortBy?: SortByInterface[]
}

export const useGetSelectorsWithFilters = <T = OptionInterface>({
  type,
  apiVersion,
  queryOptions,
  filters,
  sortBy,
}: UseGetSelectorsWithFiltersProps<T>) => {
  const context = useFetch<{ options: T[] }>(
    type ? API.SELECTORS : null,
    apiVersion,
    {
      params: {
        name: type,
        ...filterSortPageIntoQuery(sortBy, filters, undefined),
      },
    },
    undefined,
    queryOptions || undefined,
  )

  return { ...context, data: context.data?.options }
}
