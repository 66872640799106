import React, { useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'
import { createChain, Color, Flex } from '@revolut/ui-kit'

import { MatrixCellInsertParams } from '@components/Table/MatrixTable'
import {
  performanceCriteriaColumn,
  performanceRatingColumn,
  performanceSkillsColumn,
} from '@src/constants/columns/performance'
import {
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewSummaryInterface,
  SummarySkillCardInterface,
  SummarySkillCardJustificationsInterface,
} from '@src/interfaces/performance'
import SummaryCardRating from '@components/ColumnInserts/CardRating/SummaryCardRating'
import { CellTypes } from '@src/interfaces/data'
import { competencyToColor, getNormalizedSeniorityName } from '@src/utils/competency'
import Icon from '@components/Icon/Icon'
import { getSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { getExpectedRatings } from '@src/api/probationReview'
import { ExpectedSkillRatingInterface } from '@src/interfaces/probationReview'
import WarningMessage from '@components/WarningMessage/WarningMessage'
import { relationToString } from '@src/features/Scorecard/constants'
import RecommendationSummary from '@src/pages/Forms/EmployeePerformance/RecommendationSummary'
import { colorGetter } from '@src/styles/colors'
import { RevolutTheme } from '@src/styles/theme'
import SummaryCard from '@src/pages/Forms/EmployeePerformance/components/SummaryCard'
import { CompetencyLevels } from '@src/features/CompetencyMatrixTable/utils'
import { Grid } from '@components/CommonSC/Grid'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import SummaryCommentsPopup from './SummaryCommentsPopup'
import KpiCard from './KPICard'
import { SummaryJustificationsPopup } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummaryJustificationsPopup'
import AdjustableMatrixTable from '@components/Table/AdjustableMatrixTable'

interface Props {
  data?: ReviewSummaryInterface
  cycleId?: string
  cycleName?: string
  employeeId?: number
  employeeSeniorityId?: number
  category?: ReviewCategory
  showCompetencies?: boolean
  wide?: boolean
  hideRecommendationBanner?: boolean
  skillsAndValuesOnly?: boolean
}

const Competency = styled.span`
  font-style: italic;
`

const SeniorityHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const Navigation = styled.div`
  display: flex;
  margin-right: -10px;
`

const IconWrapper = styled.div<{ disabled?: boolean }>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background: none;
  transition: background 150ms;
  border-radius: 4px;
  padding: 0 2px;

  &:hover {
    background: ${colorGetter(Color.GREY_20_OPAQUE_90)};
  }
`

export const Warning = styled(WarningMessage)`
  margin: 8px 0;
  background: ${colorGetter(Color.GREY_TONE_2)};
  border-radius: 12px;
`

const rows = (
  cycleId: string,
  employeeId: number,
  type: PerformanceReviewTypes,
  category: ReviewCategory,
  showCompetencies: boolean,
  wide?: boolean,
) => {
  const nameColumn =
    type === PerformanceReviewTypes.deliverables
      ? performanceCriteriaColumn
      : performanceSkillsColumn

  const noCompetenciesWidth = wide ? 330 : 300

  return {
    cells: [
      {
        ...nameColumn,
        width: showCompetencies ? 190 : noCompetenciesWidth,
      },
      {
        ...performanceRatingColumn,
        insert: ({ data }: MatrixCellInsertParams<SummarySkillCardInterface>) => {
          return (
            <SummaryCardRating
              data={data}
              cycleId={cycleId}
              employeeId={employeeId}
              type={type}
              category={category}
            />
          )
        },
        width: 170,
      },
    ],
  }
}

const getCompetencyLevel = (
  expectedRatings: ExpectedSkillRatingInterface[],
  type: PerformanceReviewTypes,
  seniorityId: number,
  skillId?: number,
) => {
  const expectedRating = expectedRatings.find(r => r.seniority.id === seniorityId)

  if (!expectedRating) {
    return ''
  }

  switch (type) {
    case PerformanceReviewTypes.cultureFit:
      if (!skillId) {
        return ''
      }
      return expectedRating.expected_ratings_by_culture_skill[skillId]
    case PerformanceReviewTypes.deliverables:
      return expectedRating.expected_deliverables_rating
    case PerformanceReviewTypes.skills:
      if (!skillId) {
        return ''
      }
      return expectedRating.expected_ratings_by_functional_skill[skillId]
  }

  return ''
}

const rowsCompetency = (
  expectedRatings: ExpectedSkillRatingInterface[],
  type: PerformanceReviewTypes,
  seniorities: SeniorityInterface[],
  seniorityId: number,
  changeSeniority: (increase: boolean) => void,
) => {
  return {
    cells: [
      {
        type: CellTypes.insert,
        idPoint: 'rating',
        dataPoint: 'rating',
        title: (
          <SeniorityHeader>
            {getNormalizedSeniorityName(
              seniorities.find(s => s.id === seniorityId)!.name,
            )}
            <Navigation>
              <IconWrapper
                onClick={() => changeSeniority(false)}
                disabled={seniorities[0]?.id === seniorityId}
              >
                <Icon type={'ArrowBack'} size={'tiny'} />
              </IconWrapper>
              <IconWrapper
                onClick={() => changeSeniority(true)}
                disabled={seniorities[seniorities.length - 1]?.id === seniorityId}
              >
                <Icon type={'ArrowForward'} size={'tiny'} />
              </IconWrapper>
            </Navigation>
          </SeniorityHeader>
        ),
        background: (data: SummarySkillCardInterface, theme: RevolutTheme) => {
          const rating = getCompetencyLevel(
            expectedRatings,
            type,
            seniorityId,
            data.skill_id,
          )
          const level = CompetencyLevels.find(lev => lev.id === rating)
          if (level) {
            return competencyToColor(level.id, theme)
          }
          return null
        },
        insert: ({ data }: MatrixCellInsertParams<SummarySkillCardInterface>) => {
          const rating = getCompetencyLevel(
            expectedRatings,
            type,
            seniorityId,
            data.skill_id,
          )
          if (!rating) {
            return <Competency>N/A</Competency>
          }
          const level = CompetencyLevels.find(lev => lev.id === rating)
          return <Competency>{level?.name}</Competency>
        },
        width: 120,
      },
    ],
  }
}

const Cards = ({
  data,
  cycleId,
  employeeId,
  employeeSeniorityId,
  category,
  showCompetencies = false,
  wide = true,
  hideRecommendationBanner = false,
  cycleName,
  skillsAndValuesOnly = false,
}: Props) => {
  const [expectedRatings, setExpectedRatings] = useState<ExpectedSkillRatingInterface[]>()
  const [seniorityId, setSeniorityId] = useState<number | undefined>(employeeSeniorityId)
  const [seniorities, setSeniorities] = useState<SeniorityInterface[]>()

  useEffect(() => {
    if (!showCompetencies || !employeeId || !cycleId) {
      return
    }
    const fetchData = async () => {
      const response = await getExpectedRatings(employeeId, cycleId)
      setExpectedRatings(response.data)
    }
    const fetchSeniorities = async () => {
      const response = await getSelectors(selectorKeys.seniority)
      const sortedSeniorities = (response.data.options as SeniorityInterface[]).sort(
        (a, b) => a.level - b.level,
      )
      setSeniorities(sortedSeniorities)
    }

    fetchSeniorities()
    fetchData()
  }, [showCompetencies, employeeId, cycleId])

  const changeSeniority = (increase: boolean) => {
    if (!seniorities) {
      return
    }

    const index = seniorities.findIndex(s => s.id === seniorityId)
    if (increase && index < seniorities.length - 1) {
      setSeniorityId(seniorities[index + 1].id)
    }

    if (!increase && index > 0) {
      setSeniorityId(seniorities[index - 1].id)
    }
  }

  const calculatedDeliverables = useMemo(() => {
    const list: SummarySkillCardInterface[] = []

    if (!data?.summary?.calculated_deliverables_ratings) {
      return list
    }

    const getJustifications = (key: string) => {
      const justifications: SummarySkillCardJustificationsInterface[] = []
      return (
        data?.summary?.extra_deliverables_overall_feedback?.reduce((acc, feedback) => {
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          if (feedback.value?.[key]) {
            acc.push({
              /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              value: feedback.value?.[key].map((val: string) => ({ comment: val })),
              review: feedback.review,
            })
          }
          return acc
        }, justifications) || []
      )
    }

    const deliverables = data.summary.calculated_deliverables_ratings

    return [
      {
        name: 'Complexity',
        rating: deliverables.complexity?.rating,
        ratings: deliverables.complexity?.ratings,
        sections: [],
        justifications: getJustifications('complexity'),
      },
      {
        name: 'Speed',
        rating: deliverables.speed?.rating,
        ratings: deliverables.speed?.ratings,
        sections: [],
        justifications: getJustifications('speed'),
      },
      {
        name: 'Quality',
        rating: deliverables.quality?.rating,
        ratings: deliverables.quality?.ratings,
        sections: [],
        justifications: getJustifications('quality'),
      },
    ] as SummarySkillCardInterface[]
  }, [data?.summary?.calculated_deliverables_ratings])

  const { data: performanceSettings } = useGetPerformanceSettings()

  if (
    cycleId === undefined ||
    employeeId === undefined ||
    !category ||
    !seniorityId ||
    !data?.summary
  ) {
    return null
  }

  const leftJustificationsNames: { [key: string]: string } = {}
  data.summary.deliverables?.justifications?.forEach(item => {
    if (
      !leftJustificationsNames[item.review.reviewer.id] &&
      item.review.reviewer.display_name
    ) {
      leftJustificationsNames[item.review.reviewer.id] = `${
        item.review.reviewer.display_name
      } (${relationToString(item.review.reviewer_relation, true)})`
    }
  })

  if (skillsAndValuesOnly) {
    return (
      <Grid gap={32} data-testid="cards">
        {data.summary.functional_skills && performanceSettings?.enable_skill_assessment && (
          <SummaryCard
            title={`Skills (${cycleName})`}
            rating={data.summary.functional_skills.rating}
            tooltipRatings={data.summary.functional_skills.ratings}
          >
            <Flex justifyContent="space-between">
              <AdjustableMatrixTable
                rows={rows(
                  cycleId,
                  employeeId,
                  PerformanceReviewTypes.skills,
                  category,
                  showCompetencies,
                  wide,
                )}
                data={data.summary.functional_skills.cards}
                data-testid="skills-table"
                autoResize
              />
              {showCompetencies && expectedRatings && seniorities && (
                <AdjustableMatrixTable
                  rows={rowsCompetency(
                    expectedRatings,
                    PerformanceReviewTypes.skills,
                    seniorities,
                    seniorityId,
                    changeSeniority,
                  )}
                  data={data.summary.functional_skills.cards}
                  data-testid="competency-table"
                  autoResize
                />
              )}
            </Flex>
            {!!data.summary.functional_skills?.skipped_section_justifications.length && (
              <Warning multiline>
                {data.summary.functional_skills.skipped_section_justifications.map(
                  (value, index, arr) => (
                    <React.Fragment key={index}>
                      {value.review.reviewer.display_name} (
                      {relationToString(value.review.reviewer_relation, true)})
                      {index !== arr.length - 1 ? ', ' : ' '}
                    </React.Fragment>
                  ),
                )}
                skipped this section
                <SummaryCommentsPopup
                  data={data.summary.functional_skills.skipped_section_justifications}
                  title="Skills"
                />
              </Warning>
            )}
          </SummaryCard>
        )}
        {data.summary.culture_skills?.cards && performanceSettings?.enable_values && (
          <SummaryCard
            title={`Values (${cycleName})`}
            rating={data.summary.culture_skills?.rating}
            tooltipRatings={data.summary.culture_skills?.ratings}
          >
            <Flex justifyContent="space-between">
              <AdjustableMatrixTable
                rows={rows(
                  cycleId,
                  employeeId,
                  PerformanceReviewTypes.cultureValuesFit,
                  category,
                  showCompetencies,
                  wide,
                )}
                data={data.summary.culture_skills?.cards}
                data-testid="skills-table"
                autoResize
              />
              {showCompetencies && expectedRatings && seniorities && (
                <AdjustableMatrixTable
                  rows={rowsCompetency(
                    expectedRatings,
                    PerformanceReviewTypes.cultureValuesFit,
                    seniorities,
                    seniorityId,
                    changeSeniority,
                  )}
                  data={data.summary.culture_skills?.cards}
                  data-testid="competency-table"
                  autoResize
                />
              )}
            </Flex>
          </SummaryCard>
        )}
        {data.summary?.culture_values?.cards && performanceSettings?.enable_values && (
          <SummaryCard
            rating={data.summary.culture_values?.rating}
            title={`Values (${cycleName})`}
            tooltipRatings={data.summary.culture_values?.ratings}
          >
            <Flex justifyContent="space-between">
              <AdjustableMatrixTable
                data={data.summary.culture_values?.cards}
                data-testid="skills-table"
                rows={rows(
                  cycleId,
                  employeeId,
                  PerformanceReviewTypes.cultureFit,
                  category,
                  showCompetencies,
                  wide,
                )}
                autoResize
              />
              {showCompetencies && expectedRatings && seniorities && (
                <AdjustableMatrixTable
                  data={data.summary.culture_values?.cards}
                  data-testid="competency-table"
                  rows={rowsCompetency(
                    expectedRatings,
                    PerformanceReviewTypes.cultureValuesFit,
                    seniorities,
                    seniorityId,
                    changeSeniority,
                  )}
                  autoResize
                />
              )}
            </Flex>
          </SummaryCard>
        )}
      </Grid>
    )
  }

  return (
    <Grid gap={32} data-testid="cards">
      {data.summary.calculated_deliverables_ratings &&
        !isEmpty(data.summary.calculated_deliverables_ratings) && (
          <SummaryCard
            title="Deliverables"
            rating={data.summary.calculated_deliverables_ratings.rating?.rating}
            tooltipRatings={data.summary.calculated_deliverables_ratings.rating?.ratings}
          >
            <Flex justifyContent="space-between">
              <AdjustableMatrixTable
                rows={rows(
                  cycleId,
                  employeeId,
                  PerformanceReviewTypes.deliverables,
                  category,
                  showCompetencies,
                  wide,
                )}
                data={calculatedDeliverables}
                data-testid="skills-table"
                autoResize
              />
              {showCompetencies && expectedRatings && seniorities && (
                <AdjustableMatrixTable
                  rows={rowsCompetency(
                    expectedRatings,
                    PerformanceReviewTypes.deliverables,
                    seniorities,
                    seniorityId,
                    changeSeniority,
                  )}
                  data={calculatedDeliverables}
                  data-testid="competency-table"
                  autoResize
                />
              )}
            </Flex>
          </SummaryCard>
        )}
      {data.summary.kpis_section?.kpi_items && (
        <SummaryCard
          title="KPI"
          rating={data.summary.kpis_section.rating}
          tooltipRatings={data.summary.kpis_section.ratings}
        >
          <Flex justifyContent="space-between">
            <KpiCard
              data={data.summary.kpis_section.kpi_items}
              employeeId={employeeId}
              id={cycleId}
              category={category}
              isView
              wide={wide}
            />
          </Flex>
        </SummaryCard>
      )}
      {!!data.summary.kpis_section?.comments?.length && (
        <Warning multiline>
          {data.summary.kpis_section.comments.map((value, index, arr) => (
            <React.Fragment key={index}>
              {value.review.reviewer.display_name} (
              {relationToString(value.review.reviewer_relation, true)})
              {index !== arr.length - 1 ? ', ' : ' '}
            </React.Fragment>
          ))}
          left a comment.
          <SummaryCommentsPopup
            data={data.summary.kpis_section.comments}
            title="KPI"
            label="View comments"
            subtitle="Comments"
          />
        </Warning>
      )}
      {data.summary.deliverables && (
        <SummaryCard
          title="Deliverables"
          rating={data.summary.deliverables.rating}
          tooltipRatings={data.summary.deliverables.ratings}
          showIndividualContributorTooltip
        >
          <Flex justifyContent="space-between">
            <AdjustableMatrixTable
              rows={rows(
                cycleId,
                employeeId,
                PerformanceReviewTypes.deliverables,
                category,
                showCompetencies,
                wide,
              )}
              data={data.summary.deliverables.cards}
              data-testid="skills-table"
              autoResize
            />
            {showCompetencies && expectedRatings && seniorities && (
              <AdjustableMatrixTable
                rows={rowsCompetency(
                  expectedRatings,
                  PerformanceReviewTypes.deliverables,
                  seniorities,
                  seniorityId,
                  changeSeniority,
                )}
                data={data.summary.deliverables.cards}
                data-testid="competency-table"
                autoResize
              />
            )}
          </Flex>
          {!!data.summary.deliverables?.skipped_section_justifications.length && (
            <Warning multiline>
              {data.summary.deliverables.skipped_section_justifications.map(
                (value, index, arr) => (
                  <React.Fragment key={index}>
                    {value.review.reviewer.display_name} (
                    {relationToString(value.review.reviewer_relation, true)})
                    {index !== arr.length - 1 ? ', ' : ' '}
                  </React.Fragment>
                ),
              )}
              skipped this section
              <SummaryCommentsPopup
                data={data.summary.deliverables.skipped_section_justifications}
                title="Deliverables"
              />
            </Warning>
          )}
          {!!data.summary.deliverables?.justifications?.length && (
            <Warning multiline>
              {Object.values(leftJustificationsNames).map((el, index, arr) => (
                <React.Fragment key={index}>
                  {el}
                  {index !== arr.length - 1 ? ', ' : ' '}
                </React.Fragment>
              ))}
              left a comment.
              <SummaryJustificationsPopup
                data={data.summary.deliverables.justifications}
              />
            </Warning>
          )}
        </SummaryCard>
      )}
      {data.summary.functional_skills && performanceSettings?.enable_skill_assessment && (
        <SummaryCard
          title="Skills"
          rating={data.summary.functional_skills.rating}
          tooltipRatings={data.summary.functional_skills.ratings}
        >
          <Flex justifyContent="space-between">
            <AdjustableMatrixTable
              rows={rows(
                cycleId,
                employeeId,
                PerformanceReviewTypes.skills,
                category,
                showCompetencies,
                wide,
              )}
              data={data.summary.functional_skills.cards}
              data-testid="skills-table"
              autoResize
            />
            {showCompetencies && expectedRatings && seniorities && (
              <AdjustableMatrixTable
                rows={rowsCompetency(
                  expectedRatings,
                  PerformanceReviewTypes.skills,
                  seniorities,
                  seniorityId,
                  changeSeniority,
                )}
                data={data.summary.functional_skills.cards}
                data-testid="competency-table"
                autoResize
              />
            )}
          </Flex>
          {!!data.summary.functional_skills?.skipped_section_justifications.length && (
            <Warning multiline>
              {data.summary.functional_skills.skipped_section_justifications.map(
                (value, index, arr) => (
                  <React.Fragment key={index}>
                    {value.review.reviewer.display_name} (
                    {relationToString(value.review.reviewer_relation, true)})
                    {index !== arr.length - 1 ? ', ' : ' '}
                  </React.Fragment>
                ),
              )}
              skipped this section
              <SummaryCommentsPopup
                data={data.summary.functional_skills.skipped_section_justifications}
                title="Skills"
              />
            </Warning>
          )}
        </SummaryCard>
      )}
      {data.summary.culture_skills?.cards && performanceSettings?.enable_values && (
        <SummaryCard
          title="Culture Fit"
          rating={data.summary.culture_skills?.rating}
          tooltipRatings={data.summary.culture_skills?.ratings}
        >
          <Flex justifyContent="space-between">
            <AdjustableMatrixTable
              rows={rows(
                cycleId,
                employeeId,
                PerformanceReviewTypes.cultureValuesFit,
                category,
                showCompetencies,
                wide,
              )}
              data={data.summary.culture_skills?.cards}
              data-testid="skills-table"
              autoResize
            />
            {showCompetencies && expectedRatings && seniorities && (
              <AdjustableMatrixTable
                rows={rowsCompetency(
                  expectedRatings,
                  PerformanceReviewTypes.cultureValuesFit,
                  seniorities,
                  seniorityId,
                  changeSeniority,
                )}
                data={data.summary.culture_skills?.cards}
                data-testid="competency-table"
                autoResize
              />
            )}
          </Flex>
          {!!data.summary.culture_skills?.skipped_section_justifications.length && (
            <Warning multiline>
              {createChain(', ')(
                data.summary.culture_skills.skipped_section_justifications.map(
                  value =>
                    `${value.review.reviewer.display_name} (${relationToString(
                      value.review.reviewer_relation,
                      true,
                    )})`,
                ),
              )}{' '}
              skipped this section
              <SummaryCommentsPopup
                data={data.summary.culture_skills.skipped_section_justifications}
                title="Culture Fit"
              />
            </Warning>
          )}
        </SummaryCard>
      )}
      {data.summary?.manager_values?.cards && (
        <SummaryCard
          rating={data.summary.manager_values?.rating}
          title="Skills"
          tooltipRatings={data.summary.manager_values?.ratings}
        >
          <Flex justifyContent="space-between">
            <AdjustableMatrixTable
              data={data.summary.manager_values?.cards}
              data-testid="skills-table"
              rows={rows(
                cycleId,
                employeeId,
                PerformanceReviewTypes.managerValuesSkills,
                category,
                showCompetencies,
                wide,
              )}
              autoResize
            />
            {showCompetencies && expectedRatings && seniorities && (
              <AdjustableMatrixTable
                data={data.summary.manager_values?.cards}
                data-testid="competency-table"
                rows={rowsCompetency(
                  expectedRatings,
                  PerformanceReviewTypes.managerValuesSkills,
                  seniorities,
                  seniorityId,
                  changeSeniority,
                )}
                autoResize
              />
            )}
          </Flex>
          {!!data.summary.manager_values?.skipped_section_justifications.length && (
            <Warning multiline>
              {createChain(', ')(
                data.summary.manager_values.skipped_section_justifications.map(
                  value =>
                    `${value.review.reviewer.display_name} (${relationToString(
                      value.review.reviewer_relation,
                      true,
                    )})`,
                ),
              )}{' '}
              skipped this section
              <SummaryCommentsPopup
                data={data.summary.manager_values.skipped_section_justifications}
                title="Skills"
              />
            </Warning>
          )}
        </SummaryCard>
      )}
      {data.summary?.culture_values?.cards && performanceSettings?.enable_values && (
        <SummaryCard
          rating={data.summary.culture_values?.rating}
          title="Culture Fit"
          tooltipRatings={data.summary.culture_values?.ratings}
        >
          <Flex justifyContent="space-between">
            <AdjustableMatrixTable
              data={data.summary.culture_values?.cards}
              data-testid="skills-table"
              rows={rows(
                cycleId,
                employeeId,
                PerformanceReviewTypes.cultureFit,
                category,
                showCompetencies,
                wide,
              )}
              autoResize
            />
            {showCompetencies && expectedRatings && seniorities && (
              <AdjustableMatrixTable
                data={data.summary.culture_values?.cards}
                data-testid="competency-table"
                rows={rowsCompetency(
                  expectedRatings,
                  PerformanceReviewTypes.cultureValuesFit,
                  seniorities,
                  seniorityId,
                  changeSeniority,
                )}
                autoResize
              />
            )}
          </Flex>
          {!!data.summary.culture_values?.skipped_section_justifications.length && (
            <Warning multiline>
              {createChain(', ')(
                data.summary.culture_values.skipped_section_justifications.map(
                  value =>
                    `${value.review.reviewer.display_name} (${relationToString(
                      value.review.reviewer_relation,
                      true,
                    )})`,
                ),
              )}{' '}
              skipped this section
              <SummaryCommentsPopup
                data={data.summary.culture_values.skipped_section_justifications}
                title="Culture Fit"
              />
            </Warning>
          )}
        </SummaryCard>
      )}
      {(data.summary.line_manager_extra_section ||
        data.summary.functional_manager_extra_section ||
        !!data.summary.peer_extra_sections?.length) && (
        <SummaryCard title="Recommendation">
          <RecommendationSummary
            employeeId={employeeId}
            id={cycleId}
            lmData={data.summary.line_manager_extra_section}
            fmData={data.summary.functional_manager_extra_section}
            peersData={data.summary.peer_extra_sections}
            hideBanner={hideRecommendationBanner}
            wide={wide}
            type={null}
          />
        </SummaryCard>
      )}
    </Grid>
  )
}

export default Cards
