import React, { useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  DndContext,
  useDraggable,
  Modifier,
  DragStartEvent,
  DragMoveEvent,
} from '@dnd-kit/core'
import { Absolute, Box, Flex, Text, Token } from '@revolut/ui-kit'

import {
  DocumentsTemplateFieldInterface,
  DocumentsTemplateMoneyFieldInterface,
  ESignatureTemplateInterface,
  DocumentsTemplateTextFieldInterface,
} from '@src/interfaces/documentsTemplates'
import { formatDate, formatMoney } from '@src/utils/format'
import { PdfPreviewScale } from '@src/features/PdfPreview/common'
import {
  TemplateField,
  fieldTypes,
  IndexedFieldKey,
  ErrorsByFieldsKeys,
  getFieldInfoFromIndexedKey,
  getFieldName,
  createIndexedFieldKey,
  getFieldsByType,
} from './common'
import { EditorViewportParams, useScrollFieldAreaIntoView } from './hooks'

type FieldAreaProps = TemplateField & Pick<DragAndDropOverlayProps, 'scale'>

const resizeEventIdPostfix = 'resize'
const dragEventIdPostfix = 'drag'
const postfixDelimiter = '__'

const isResizeEvent = (id?: string) => id?.endsWith(resizeEventIdPostfix)
const isDragEvent = (id?: string) => id?.endsWith(dragEventIdPostfix)

const getDragEventId = (fieldId: string) =>
  `${fieldId}${postfixDelimiter}${dragEventIdPostfix}`
const getResizeEventId = (fieldId: string) =>
  `${fieldId}${postfixDelimiter}${resizeEventIdPostfix}`

const getFieldId = (id?: string) => id?.split(postfixDelimiter)[0]

const Draggable = ({
  id,
  data,
  scale,
  children,
  contentBorder,
  contentBackground,
  disabled,
}: React.PropsWithChildren<
  FieldAreaProps & {
    contentBorder?: string
    contentBackground?: string
  }
>) => {
  const scaled = (size: number) => size * scale.value

  const contentRef = useRef<HTMLDivElement>(null)

  const {
    active,
    setNodeRef: resizeSetNodeRef,
    listeners: resizeListeners,
    attributes: resizeAttributes,
    transform: resizeTransform,
  } = useDraggable({
    id: getResizeEventId(id),
    data: { fieldData: data, contentRef },
    disabled,
  })

  const {
    setNodeRef: dragSetNodeRef,
    listeners: dragListeners,
    attributes: dragAttributes,
    transform: dragTransform,
  } = useDraggable({
    id: getDragEventId(id),
    data: { fieldData: data, contentRef },
    disabled,
  })

  const isDragging = isDragEvent(active?.id) && getFieldId(active?.id) === id
  const isResizing = isResizeEvent(active?.id) && getFieldId(active?.id) === id

  const dragTransformX = dragTransform?.x || 0
  const dragTransformY = dragTransform?.y || 0

  const resizeTransformX = resizeTransform?.x || 0
  const resizeTransformY = resizeTransform?.y || 0

  const resizePosXCompensated = resizeTransformX / 2
  const resizePosYCompensated = resizeTransformY / 2

  const draggableWrapperStyle = isDragging
    ? {
        cursor: 'grabbing',
        boxShadow: `1px 1px 1px ${Token.color.greyTone20}`,
        transform: `translate(${dragTransformX}px, ${dragTransformY}px)`,
      }
    : {
        cursor: isResizing ? 'nwse-resize' : disabled ? 'pointer' : 'grab',
        transform: `translate(${resizePosXCompensated}px, ${resizePosYCompensated}px)`,
      }

  const resizeHandlerStyle = isResizing
    ? {
        transform: `translate(${resizePosXCompensated}px, ${resizePosYCompensated}px)`,
      }
    : {}

  const whRatio = data.width / data.height
  const scaleResizeMultiplier = whRatio / 2
  const contentTranslateOnResize = `translate(${
    resizeTransformY * scaleResizeMultiplier - resizePosXCompensated
  }px)`

  const scaleXByResize = 1 + resizeTransformX / (data.width * scale.value)
  const scaleYByResize = 1 + resizeTransformY / (data.height * scale.value)
  const contentScaleOnResize = `scale(${scaleYByResize})`

  return (
    <Absolute
      ref={dragSetNodeRef}
      {...dragListeners}
      {...dragAttributes}
      style={draggableWrapperStyle}
      top={scaled(data.y_position)}
      left={scaled(data.x_position)}
      data-testid={getDragEventId(id)}
    >
      <Absolute
        ref={resizeSetNodeRef}
        {...resizeListeners}
        {...resizeAttributes}
        style={{
          cursor: 'nwse-resize',
          display: disabled ? 'none' : undefined,
          ...resizeHandlerStyle,
        }}
        top={scaled(data.height)}
        left={scaled(data.width)}
        width={5}
        height={5}
        backgroundColor={Token.color.blue_80}
        borderRadius={Token.radius.round}
        data-testid={getResizeEventId(id)}
      />
      {isResizing && (
        <Absolute
          width={scaled(data.width)}
          height={scaled(data.height)}
          style={{
            transform: `${contentTranslateOnResize} ${contentScaleOnResize}`,
          }}
        >
          {children}
        </Absolute>
      )}
      <Box
        ref={contentRef}
        width={scaled(data.width)}
        height={scaled(data.height)}
        border={contentBorder}
        bg={contentBackground}
        style={{
          transform: `scale(${scaleXByResize}, ${scaleYByResize})`,
        }}
      >
        {!isResizing && children}
      </Box>
    </Absolute>
  )
}

type FieldPreviewProps = FieldAreaProps & {
  onClick?: () => void
  editorViewport: EditorViewportParams
}
const FieldPreview = (props: FieldPreviewProps) => {
  const { id, data, isSelected, scale, onClick, editorViewport, errors } = props
  const { idx: fieldIdx, type } = getFieldInfoFromIndexedKey(id)

  const scrollWrapperRef = useScrollFieldAreaIntoView(props, editorViewport)

  const formatFieldCustomValue = () => {
    switch (type) {
      case 'date': {
        const fieldData = data as DocumentsTemplateTextFieldInterface
        return fieldData.custom_value ? formatDate(fieldData.custom_value) : undefined
      }
      case 'money': {
        const fieldData = data as DocumentsTemplateMoneyFieldInterface
        return formatMoney(fieldData.custom_value, fieldData.currency?.iso_code)
      }
      default: {
        const fieldData = data as DocumentsTemplateTextFieldInterface
        return fieldData.custom_value
      }
    }
  }

  const getTextContent = () => {
    switch (data.source_type.id) {
      case 'to_be_filled':
        return data.placeholder
      case 'custom_value':
        return formatFieldCustomValue()
      case 'sql_source':
        return data.sql_source?.name || 'SQL Source'
      default:
        return undefined
    }
  }

  const hasErrors = !isEmpty(errors)
  const borderColor = isSelected ? Token.color.blue_70 : Token.color.blue_50
  const borderErrColor = isSelected ? Token.color.red_70 : Token.color.red_50
  const contentBgColor = isSelected ? Token.color.blue_20 : Token.color.blue_5
  const contentBgErrColor = isSelected ? Token.color.red_20 : Token.color.red_5

  const textContent = getTextContent()
  const placeholder = getFieldName(data, type, fieldIdx)
  const scaledFontSize = `${Math.floor(data.height * scale.value * 0.8)}px`

  return (
    <Draggable
      {...props}
      contentBorder={`1px solid ${hasErrors ? borderErrColor : borderColor}`}
      contentBackground={hasErrors ? contentBgErrColor : contentBgColor}
    >
      <Flex
        style={{ lineHeight: 'unset' }}
        height="100%"
        alignItems="center"
        px="s-4"
        onClick={onClick}
        ref={scrollWrapperRef}
      >
        <Text
          whiteSpace="pre"
          lineHeight="normal"
          style={{
            fontFamily: 'Helvetica, sans-serif', // Needed for compliance with docusign interface
          }}
          fontSize={scaledFontSize}
          color={textContent ? Token.color.blue : Token.color.blue_50}
        >
          {typeof textContent === 'string'
            ? textContent || placeholder
            : textContent ?? placeholder}
        </Text>
      </Flex>
    </Draggable>
  )
}

const restrictFieldResize: Modifier = ({ active, transform }) => {
  const fieldData = active?.data.current?.fieldData

  if (!isResizeEvent(active?.id) || !fieldData) {
    return transform
  }
  const minResize = { w: 20, h: 10 }
  const maxResize = { w: 512, h: 128 }

  return {
    ...transform,
    x:
      fieldData.width + transform.x <= minResize.w
        ? minResize.w - fieldData.width
        : fieldData.width + transform.x >= maxResize.w
        ? maxResize.w - fieldData.width
        : transform.x,
    y:
      fieldData.height + transform.y <= minResize.h
        ? minResize.h - fieldData.height
        : fieldData.height + transform.y >= maxResize.h
        ? maxResize.h - fieldData.height
        : transform.y,
  }
}

const restrictFieldDrag: Modifier = ({
  active,
  transform,
  containerNodeRect: boundingRect,
  draggingNodeRect: rect,
}) => {
  if (!isDragEvent(active?.id) || !rect || !boundingRect) {
    return transform
  }
  const restrictedTransform = { ...transform }

  if (rect.top + transform.y <= boundingRect.top) {
    restrictedTransform.y = boundingRect.top - rect.top
  } else if (rect.bottom + transform.y >= boundingRect.top + boundingRect.height) {
    restrictedTransform.y = boundingRect.top + boundingRect.height - rect.bottom
  }

  if (rect.left + transform.x <= boundingRect.left) {
    restrictedTransform.x = boundingRect.left - rect.left
  } else if (rect.right + transform.x >= boundingRect.left + boundingRect.width) {
    restrictedTransform.x = boundingRect.left + boundingRect.width - rect.right
  }

  return restrictedTransform
}

type DragAndDropOverlayProps = {
  values: ESignatureTemplateInterface
  pageNum: number | undefined
  scale: PdfPreviewScale
  width: number | undefined
  height: number | undefined
  selectedFieldKey: IndexedFieldKey | undefined
  setSelectedFieldKey: (newKey: IndexedFieldKey | undefined) => void
  errorsByFieldsKeys: ErrorsByFieldsKeys | undefined
  editorViewport: EditorViewportParams
  disabled?: boolean
  noScrollIntoView?: boolean
}
export const DragAndDropOverlay = ({
  values,
  pageNum = 1,
  scale,
  width,
  height,
  selectedFieldKey,
  setSelectedFieldKey,
  errorsByFieldsKeys,
  editorViewport,
  disabled,
  noScrollIntoView,
}: DragAndDropOverlayProps) => {
  const overlayRef = useRef<HTMLDivElement>(null)
  const positionBufferRef = useRef<{ x: number | undefined; y: number | undefined }>({
    x: undefined,
    y: undefined,
  })
  const resizeBufferRef = useRef<{
    width: number | undefined
    height: number | undefined
  }>({
    width: undefined,
    height: undefined,
  })
  const overlay = overlayRef.current
  const positionBuffer = positionBufferRef.current
  const resizeBuffer = resizeBufferRef.current

  const updatePosBufData = (e: DragStartEvent | DragMoveEvent) => {
    const draggableContent = e.active.data.current?.contentRef.current

    if (!positionBuffer || !overlay || !draggableContent) {
      return
    }
    positionBuffer.x =
      draggableContent.getBoundingClientRect().left - overlay.getBoundingClientRect().left
    positionBuffer.y =
      draggableContent.getBoundingClientRect().top - overlay.getBoundingClientRect().top
  }

  const updateResizeBufData = (e: DragStartEvent | DragMoveEvent) => {
    const draggableContent = e.active.data.current?.contentRef.current

    if (!resizeBuffer || !draggableContent) {
      return
    }
    resizeBuffer.width = draggableContent.getBoundingClientRect().width
    resizeBuffer.height = draggableContent.getBoundingClientRect().height
  }

  return (
    <Absolute ref={overlayRef} width={width} height={height} data-testid="dnd-overlay">
      <DndContext
        autoScroll={{ enabled: false }}
        modifiers={[restrictFieldResize, restrictFieldDrag]}
        onDragStart={e => {
          setSelectedFieldKey(getFieldId(e.active.id))

          updatePosBufData(e)
          updateResizeBufData(e)
        }}
        onDragMove={e => {
          updatePosBufData(e)
          updateResizeBufData(e)
        }}
        onDragEnd={e => {
          const fieldData = e.active.data.current
            ?.fieldData as DocumentsTemplateFieldInterface

          if (!fieldData || !positionBuffer.x || !positionBuffer.y) {
            return
          }

          fieldData.x_position = Math.round(positionBuffer.x / scale.value)
          fieldData.y_position = Math.round(positionBuffer.y / scale.value)

          if (resizeBuffer.width && resizeBuffer.height && isResizeEvent(e.active.id)) {
            fieldData.width = Math.round(resizeBuffer.width / scale.value)
            fieldData.height = Math.round(resizeBuffer.height / scale.value)
          }
        }}
      >
        {fieldTypes.map(fieldType => (
          <React.Fragment key={fieldType}>
            {getFieldsByType(fieldType, values).map((fieldData, idx) => {
              if (fieldData.page_number !== pageNum) {
                return null
              }
              const key = createIndexedFieldKey(fieldType, idx, pageNum - 1)

              return (
                <FieldPreview
                  id={key}
                  key={key}
                  data={fieldData}
                  scale={scale}
                  isSelected={key === selectedFieldKey}
                  setSelected={() => setSelectedFieldKey(key)}
                  setUnselected={() => setSelectedFieldKey(undefined)}
                  errors={errorsByFieldsKeys?.[key]}
                  disabled={disabled}
                  onClick={() => {
                    setSelectedFieldKey(key)
                  }}
                  noScrollIntoView={noScrollIntoView}
                  editorViewport={editorViewport}
                />
              )
            })}
          </React.Fragment>
        ))}
      </DndContext>
    </Absolute>
  )
}
