import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/EmployeePerformance/Form'
import { upwardsReviewRequests } from '@src/api/performanceReview'
import * as EmployeePerformanceCultureFit from '@src/pages/Forms/EmployeePerformance/CultureFit'
import * as EmployeePerformanceSkills from '@src/pages/Forms/EmployeePerformance/Skills'
import * as Summary from '@src/pages/Forms/EmployeePerformance/Summary'
import { connect } from 'lape'
import { Bar, Flex, Sticky } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import PerformanceValidationDialog from '@src/features/Popups/PerformanceValidationDialog'
import {
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import Component from '@src/pages/Forms/EmployeePerformance/components/Component'
import SaveDraftButton from '@src/pages/Forms/EmployeePerformance/components/SaveDraftButton'
import NextButton from '@src/pages/Forms/EmployeePerformance/components/NextButton'
import SubmitButton from '@src/pages/Forms/EmployeePerformance/components/SumbitButton'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Navigation from '@src/pages/Forms/EmployeePerformance/components/Navigation'
import PerformanceCycleClosed from '@components/ScorecardGeneral/PerformanceCycleClosed'
import { TabsInterface } from '@src/interfaces/data'
import PerformancePage from '@src/pages/Forms/EmployeePerformance/components/PerformancePage'
import { usePutFirstScorecardOpening } from '@src/utils/performance'

const getTabs = (
  params: {
    id: string
    employeeId?: string
  },
  isCultureValues: boolean,
  isManagerValues: boolean,
): TabsInterface<ReviewScorecardInterface>[] => [
  {
    title: 'Management behaviours',
    path: ROUTES.FORMS.UPWARDS_REVIEW.SKILLS,
    to: pathToUrl(ROUTES.FORMS.UPWARDS_REVIEW.SKILLS, params),
    validator: EmployeePerformanceSkills.validator,
    component: EmployeePerformanceSkills.default,
    invalidTabMessage: () => (
      <PerformanceValidationDialog
        type={
          isManagerValues
            ? PerformanceReviewTypes.managerValuesSkills
            : PerformanceReviewTypes.skills
        }
      />
    ),
    visibilityCheck: data => !!data?.review_data?.functional_skills || isManagerValues,
  },
  {
    title: 'Culture fit',
    path: ROUTES.FORMS.UPWARDS_REVIEW.CULTURE_FIT,
    to: pathToUrl(ROUTES.FORMS.UPWARDS_REVIEW.CULTURE_FIT, params),
    validator: EmployeePerformanceCultureFit.validator,
    component: EmployeePerformanceCultureFit.default,
    invalidTabMessage: () => (
      <PerformanceValidationDialog
        type={
          isCultureValues
            ? PerformanceReviewTypes.cultureValuesFit
            : PerformanceReviewTypes.cultureFit
        }
      />
    ),
    visibilityCheck: data =>
      !!data?.review_data?.culture_values || !!data?.review_data?.culture_skills,
  },
  {
    title: 'Summary',
    path: ROUTES.FORMS.UPWARDS_REVIEW.SUMMARY,
    to: pathToUrl(ROUTES.FORMS.UPWARDS_REVIEW.SUMMARY, params),
    validator: Summary.validator,
    component: Summary.default,
    invalidTabMessage: () => (
      <PerformanceValidationDialog type={PerformanceReviewTypes.summary} />
    ),
    visibilityCheck: () => true,
  },
]

const UpwardsReview = () => {
  const params = useParams<{ id: string; employeeId?: string }>()
  const { values } = useLapeContext<ReviewScorecardInterface>()
  usePutFirstScorecardOpening(values, ReviewCategory.Upwards)

  const isCultureValues = !!values.review_data?.culture_values
  const isManagerValues = !!values.review_data?.manager_values
  const tabs = getTabs(params, isCultureValues, isManagerValues).filter(tab =>
    tab.visibilityCheck(values),
  )

  return (
    <PerformancePage>
      {!values.current_user_can_edit_review ? (
        <PerformanceCycleClosed employeeId={values.reviewed_employee.id} />
      ) : (
        <>
          <Navigation tabs={tabs} mb="s-16" />
          <Flex flex="1 0">
            <Switch>
              {tabs.map(tab => (
                <Route exact path={tab.path} key={tab.path}>
                  <Component tab={tab} />
                </Route>
              ))}
            </Switch>
          </Flex>
          <Sticky bottom={24}>
            <Flex justifyContent="center" width={628}>
              <Bar>
                <SaveDraftButton tabs={tabs} type="upwards-review" />
                <SubmitButton
                  tabs={tabs}
                  type="upwards-review"
                  notificationMessage="Upwards review submitted"
                />
                <NextButton tabs={tabs} />
              </Bar>
            </Flex>
          </Sticky>
        </>
      )}
    </PerformancePage>
  )
}

const UpwardsReviewForm = () => {
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()

  return (
    <Form
      api={upwardsReviewRequests}
      storageKey={`${ReviewCategory.Upwards}-${id}-${employeeId}`}
    >
      <UpwardsReview />
    </Form>
  )
}

export default connect(UpwardsReviewForm)
