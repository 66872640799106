import React, { useState } from 'react'
import { connect } from 'lape'
import { Flex, VStack, Widget } from '@revolut/ui-kit'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import {
  CardContentTypes,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { PageActions } from '@components/Page/PageActions'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { SkillsView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Sections/SkillsView'
import { CultureValuesView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Sections/CultureValuesView'
import Filters from '@src/pages/Forms/EmployeePerformance/components/Filters'
import { useParams } from 'react-router-dom'
import { SkillsCardView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Cards/SkillsCardView'
import { CultureValuesCardView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Cards/CultureValuesCardView'
import EditButton from '@src/pages/Forms/EmployeePerformanceViewLayout/EditButton'
import { ProbationDeliverablesCardView } from '@src/pages/Forms/ProbationViewLayout/Cards/ProbationDeliverablesCardView'
import { ProbationDeliverables } from '@src/pages/Forms/ProbationLayout/Sections/ProbationDeliverables'
import { getRecommendations } from '@src/pages/Forms/EmployeePerformanceViewLayout/ScorecardContentView'
import { BarRaiserView } from '@src/pages/Forms/EmployeePerformanceViewLayout/BarRaiserView'
import { StyledSticky } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { FeedbackView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Sections/FeedbackView'

const renderRightColumContent = (
  values: ReviewSummaryInterface,
  selectedField?: SelectedFieldInterface,
) => {
  if (!selectedField?.type || !values.summary) {
    return null
  }
  const reviewData = values.summary

  switch (selectedField.type) {
    case CardContentTypes.KPI:
      return <KPI isViewMode reviewData={reviewData} />
    case CardContentTypes.DELIVERABLES:
      return (
        <ProbationDeliverables
          reviewData={reviewData}
          category={values.reviews[0]?.category}
          cycleId={values.reviews[0]?.performance_checkpoint?.cycle_id}
          checkpointNum={values.reviews[0]?.probation_checkpoint?.number}
          comments={
            values.summary?.calculated_deliverables_ratings?.recommended_rating_comment
              ?.ratings
          }
          isViewMode
        />
      )
    case CardContentTypes.SKILLS:
      return <SkillsView selectedField={selectedField} />
    case CardContentTypes.VALUES:
      return <CultureValuesView selectedField={selectedField} />
    default:
      return null
  }
}

export const ProbationViewContent = connect(() => {
  const params = useParams<{ id: string }>()
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const [selectedField, setSelectedField] = useState<SelectedFieldInterface | undefined>()
  const cycleId = params.id

  if (!values.summary) {
    return null
  }

  const recommendations = getRecommendations(values)

  return (
    <>
      <Flex flexDirection="column" width="100%" flex={1} minHeight={0}>
        <TwoColumnsLayout
          leftFlex={1.4}
          left={
            <VStack space="s-16">
              <Widget p="s-16" pb={0}>
                <Filters
                  cycleId={cycleId}
                  onFilterChange={() => setSelectedField(undefined)}
                />
              </Widget>
              <ProbationDeliverablesCardView
                selectedField={selectedField}
                setSelectedField={setSelectedField}
              />
              <SkillsCardView
                selectedField={selectedField}
                setSelectedField={setSelectedField}
              />
              <CultureValuesCardView
                selectedField={selectedField}
                setSelectedField={setSelectedField}
              />
              {!!recommendations.length && (
                <BarRaiserView recommendations={recommendations} />
              )}
              <FeedbackView
                recommendationData={recommendations?.[0]?.recommendation}
                reviews={recommendations?.map(el => el.recommendation)}
              />
              <PageActions maxWidth="100%">
                <Flex justifyContent="center">
                  <EditButton />
                </Flex>
              </PageActions>
            </VStack>
          }
          right={
            <StyledSticky top={70}>
              {renderRightColumContent(values, selectedField)}
            </StyledSticky>
          }
        />
      </Flex>
    </>
  )
})
