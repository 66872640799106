import { Token } from '@revolut/ui-kit'

import {
  DocumentsBulkRequestEmployeeValidationStatus,
  DocumentsBulkRequestInterface,
  DocumentsBulkRequestStatus,
} from '@src/interfaces/documents'
import { documentsBulkRequestFormRequests } from '@src/api/documents'
import { useEffect } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'

export type CommonTabProps = {
  nextTabPath: string
  bulkRequestData: DocumentsBulkRequestInterface | undefined
  updateBulkRequestData?: (data: DocumentsBulkRequestInterface) => void
}

export const getRefreshRequestData =
  (requestId: number | string, handler: (data: DocumentsBulkRequestInterface) => void) =>
  () =>
    documentsBulkRequestFormRequests.get({ id: String(requestId) }).then(res => {
      handler(res.data)
      return res.data
    })

export const getEmployeeValidationStatusColor = (
  status: DocumentsBulkRequestEmployeeValidationStatus,
): string => {
  switch (status.id) {
    case 'validation_pending':
      return Token.color.warning
    case 'validation_completed':
    case 'submit_completed':
      return Token.color.success
    case 'validation_failed':
      return Token.color.error
    default:
      return Token.color.foreground
  }
}

export const getDocumentsBulkRequestStatusColor = (
  status: DocumentsBulkRequestStatus,
): string => {
  switch (status.id) {
    case 'validation_completed':
    case 'validation_pending':
    case 'validation_in_progress':
    case 'submit_in_progress':
    case 'ready_to_be_submitted':
      return Token.color.warning
    case 'submit_completed':
      return Token.color.success
    default:
      return Token.color.foreground
  }
}

export const useRefreshRequestStatus = (onUpdate?: () => void) => {
  const { values, reset } = useLapeContext<DocumentsBulkRequestInterface>()
  const refreshRequestData = getRefreshRequestData(values.id, d => reset(d))

  useEffect(() => {
    let tmo: NodeJS.Timeout
    let int: NodeJS.Timer

    const cleanUp = () => {
      clearTimeout(tmo)
      clearInterval(int)
    }
    if (values.status.id !== 'validation_in_progress') {
      return cleanUp()
    }
    // refresh status after 500ms delay before setting interval to make sure it's not a quick validation process
    tmo = setTimeout(() => {
      refreshRequestData().then(data => {
        if (data.status.id !== 'ready_to_be_submitted') {
          int = setInterval(() => {
            onUpdate?.()
            refreshRequestData().then(refetchedData => {
              if (refetchedData.status.id !== 'validation_in_progress') {
                cleanUp()
              }
            })
          }, 3000)
        }
      })
    }, 500)

    return cleanUp
  }, [values.status.id])
}
