import React from 'react'
import { connect } from 'lape'
import capitalize from 'lodash/capitalize'
import pluralize from 'pluralize'
import {
  Avatar,
  Cell,
  Group,
  IconButton,
  Item,
  MoreBar,
  Spinner,
  TableWidget,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import Form from '@src/features/Form/Form'
import {
  documentsBulkRequestFormRequests,
  getDocumentsBulkRequestEligibleEmployeesTableRequests,
} from '@src/api/documents'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  DocumentsBulkRequestEligibleEmployeeInterface,
  DocumentsBulkRequestEligibleEmployeesStatsInterface,
  DocumentsBulkRequestInterface,
} from '@src/interfaces/documents'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  documentsBulkRequestEligibleEmployeeCountryColumn,
  documentsBulkRequestEligibleEmployeeDepartmentColumn,
  documentsBulkRequestEligibleEmployeeNameColumn,
  documentsBulkRequestEligibleEmployeeSeniorityColumn,
  documentsBulkRequestEmployeeValidationStatusColumn,
} from '@src/constants/columns/documentsBulkRequestEligibleEmployees'
import { useTable } from '@components/Table/hooks'
import Stat from '@components/Stat/Stat'
import { FormPreview } from '@components/FormPreview/FormPreview'

import { getDocumentsBulkRequestStatusColor, useRefreshRequestStatus } from './common'

const ROW = {
  cells: [
    {
      ...documentsBulkRequestEligibleEmployeeNameColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestEligibleEmployeeCountryColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestEligibleEmployeeDepartmentColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestEligibleEmployeeSeniorityColumn,
      width: 180,
    },
    {
      ...documentsBulkRequestEmployeeValidationStatusColumn,
      width: 200,
    },
  ].filter(Boolean),
}

const Preview = connect(() => {
  const { values } = useLapeContext<DocumentsBulkRequestInterface>()

  const ReviewAndSubmitButton = (
    <MoreBar.Action
      disabled={values.status.id !== 'ready_to_be_submitted'}
      useIcon="ArrowThinRight"
      use={InternalLink}
      to={pathToUrl(ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.REVIEW, {
        id: values.id,
      })}
    >
      Review and submit
    </MoreBar.Action>
  )

  const table = useTable<
    DocumentsBulkRequestEligibleEmployeeInterface,
    DocumentsBulkRequestEligibleEmployeesStatsInterface
  >(
    getDocumentsBulkRequestEligibleEmployeesTableRequests(values.id),
    undefined,
    undefined,
    { disable: !values.id },
  )

  useRefreshRequestStatus(table.refresh)

  const attachedDrafts = values.document_drafts || []
  const attachedTemplates = values.document_templates || []

  return (
    <>
      <PageHeader
        title={values.name}
        subtitle={
          <Text color={getDocumentsBulkRequestStatusColor(values.status)}>
            {capitalize(values.status.name)}
          </Text>
        }
        backUrl={ROUTES.APPS.DOCUMENTS.BULK_REQUEST.LIST}
        routerAction={PageHeaderRouterAction.goBack}
      />
      <PageBody>
        <VStack space="s-24">
          {values.status.id === 'validation_in_progress' && (
            <ActionWidget
              title="Eligible employees are being validated for this request"
              text='Validation process can take a while.
              When it is finished, you will be able to proceed to the "Review" step and submit the request.'
              avatar={<Spinner color={Token.color.blue} />}
            >
              <MoreBar>{ReviewAndSubmitButton}</MoreBar>
            </ActionWidget>
          )}
          {!values.is_valid && values.status.id === 'validation_pending' && (
            <ActionWidget
              title="Employees validation has failed"
              text="Please review and edit eligible employees table to start the validation process again"
            >
              <MoreBar.Action
                useIcon="ArrowThinRight"
                use={InternalLink}
                to={pathToUrl(ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.REVIEW, {
                  id: values.id,
                })}
              >
                Review and edit
              </MoreBar.Action>
            </ActionWidget>
          )}
          {values.status.id === 'ready_to_be_submitted' && (
            <ActionWidget
              title="Request validation is completed"
              text="You can review eligible employees and submit the request"
              avatar={<Avatar useIcon="Check" color={Token.color.blue} />}
            >
              <MoreBar>{ReviewAndSubmitButton}</MoreBar>
            </ActionWidget>
          )}
          {values.status.id === 'submit_in_progress' && (
            <ActionWidget
              title="Request is waiting to complete"
              avatar={
                <Avatar useIcon="16/SandWatch" progress={0.6} color={Token.color.blue} />
              }
            />
          )}
          <FormPreview title="General" data={values}>
            <Group>
              <FormPreview.Item title="Created by" field="created_by" type="employee" />
              <FormPreview.Item
                title="Created on"
                field="creation_date_time"
                type="date"
              />
              <FormPreview.Item title="Issuer" field="issuer" type="employee" />
              <FormPreview.Item
                title="Eligible employees count"
                field="employees_count"
              />
            </Group>
          </FormPreview>
          {!!attachedTemplates.length && (
            <FormPreview title="Document templates">
              <Cell p={0}>
                <VStack width="100%">
                  {attachedTemplates.map(({ id, name, template_type }) => (
                    <Item key={id}>
                      <Item.Content>
                        <Item.Title>{name}</Item.Title>
                        <Item.Description>
                          {template_type?.id === 'esignature'
                            ? 'E-signature document template'
                            : 'Document template'}
                        </Item.Description>
                      </Item.Content>
                      <Item.Side>
                        <IconButton
                          useIcon="LinkExternal"
                          color={Token.color.blue}
                          size={16}
                          use={InternalLink}
                          target="_blank"
                          to={pathToUrl(ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW, { id })}
                        />
                      </Item.Side>
                    </Item>
                  ))}
                </VStack>
              </Cell>
            </FormPreview>
          )}
          {!!attachedDrafts.length && (
            <FormPreview title="Document drafts">
              <Cell p={0}>
                <VStack width="100%">
                  {attachedDrafts.map(({ id, name }) => (
                    <Item key={id}>
                      <Item.Content>
                        <Item.Title>{name}</Item.Title>
                        <Item.Description>Document request</Item.Description>
                      </Item.Content>
                    </Item>
                  ))}
                </VStack>
              </Cell>
            </FormPreview>
          )}
          <FormPreview title="Eligible employees">
            <TableWidget>
              {['validation_pending', 'validation_in_progress'].includes(
                values.status.id,
              ) && (
                <TableWidget.Info>
                  <Stat val={table.stats?.total} label="Total" />
                  <Stat
                    val={table.stats?.validations_pending}
                    color={Token.color.warning}
                    label="Validating"
                  />
                  <Stat
                    val={table.stats?.validations_failed}
                    label={pluralize('Warning', table.stats?.validations_failed)}
                    color={Token.color.warning}
                  />
                  <Stat
                    val={table.stats?.validations_completed}
                    label="OK"
                    color={Token.color.success}
                  />
                </TableWidget.Info>
              )}
              <TableWidget.Table>
                <AdjustableTable<
                  DocumentsBulkRequestEligibleEmployeeInterface,
                  DocumentsBulkRequestEligibleEmployeesStatsInterface
                >
                  useWindowScroll
                  name={TableNames.BulkRequestDocumentsEligibleEmployees}
                  row={ROW}
                  {...table}
                  noDataMessage="All eligible employees will appear here"
                  dataType="Eligible employee"
                />
              </TableWidget.Table>
            </TableWidget>
          </FormPreview>
        </VStack>
      </PageBody>
    </>
  )
})

export const PreviewDocumentsBulkRequest = () => (
  <Form api={documentsBulkRequestFormRequests}>
    <Preview />
  </Form>
)
