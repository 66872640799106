import React, { useState } from 'react'
import { connect } from 'lape'
import { Flex, VStack, Widget } from '@revolut/ui-kit'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import {
  CardContentTypes,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { DeliverablesCardView } from './Cards/DeliverablesCardView'
import { SkillsCardView } from './Cards/SkillsCardView'
import { CultureValuesCardView } from './Cards/CultureValuesCardView'
import { BarRaiserView } from './BarRaiserView'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { AdditionalQuestions } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/AdditionalQuestions'
import { Deliverables } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import Filters from '@src/pages/Forms/EmployeePerformance/components/Filters'
import { useParams } from 'react-router-dom'
import { PageActions } from '@components/Page/PageActions'
import EditButton from '@src/pages/Forms/EmployeePerformanceViewLayout/EditButton'
import { SkillsView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Sections/SkillsView'
import { CultureValuesView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Sections/CultureValuesView'
import { StyledSticky } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { FeedbackView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Sections/FeedbackView'

const renderRightColumContent = (
  values: ReviewSummaryInterface,
  selectedField?: SelectedFieldInterface,
) => {
  if (!selectedField?.type || !values.summary) {
    return null
  }
  const reviewData = values.summary

  switch (selectedField.type) {
    case CardContentTypes.KPI:
      return <KPI isViewMode reviewData={reviewData} />
    case CardContentTypes.DELIVERABLES:
      return <Deliverables isViewMode reviewData={reviewData} />
    case CardContentTypes.SKILLS:
      return <SkillsView selectedField={selectedField} />
    case CardContentTypes.VALUES:
      return <CultureValuesView selectedField={selectedField} />
    default:
      return null
  }
}

export const getRecommendations = (values: ReviewSummaryInterface) => {
  const lmRecommendation = values.summary?.line_manager_extra_section
  const fmRecommendation = values.summary?.functional_manager_extra_section
  const peerRecommendations = values.summary?.peer_extra_sections
  const probationPipRecommendation = values.summary?.recommendation_extra_sections
  const obsoletePipRecommendation = values.summary?.pip_extra_sections

  const recommendations = []

  if (lmRecommendation) {
    recommendations.push({
      recommendation: lmRecommendation,
      type: RecommendationTypes.LM,
    })
  }
  if (fmRecommendation) {
    recommendations.push({
      recommendation: fmRecommendation,
      type: RecommendationTypes.FM,
    })
  }
  if (peerRecommendations?.length) {
    peerRecommendations.forEach(peerRecommendation => {
      recommendations.push({
        recommendation: peerRecommendation,
        type: RecommendationTypes.PEER,
      })
    })
  }
  if (probationPipRecommendation?.length) {
    probationPipRecommendation.forEach(peerRecommendation => {
      recommendations.push({
        recommendation: peerRecommendation,
        type: RecommendationTypes.PIP,
      })
    })
  }
  if (obsoletePipRecommendation?.length) {
    obsoletePipRecommendation.forEach(peerRecommendation => {
      recommendations.push({
        recommendation: peerRecommendation,
        type: RecommendationTypes.PIP_V1,
      })
    })
  }

  return recommendations
}

export const ScorecardContentView = connect(() => {
  const params = useParams<{ id: string }>()
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const [selectedField, setSelectedField] = useState<SelectedFieldInterface | undefined>()
  const cycleId = params.id

  if (!values.summary) {
    return null
  }

  const recommendations = getRecommendations(values)

  return (
    <Flex flexDirection="column" width="100%" flex={1} minHeight={0}>
      <TwoColumnsLayout
        leftFlex={1.4}
        left={
          <VStack space="s-16">
            <Widget p="s-16" pb={0}>
              <Filters
                cycleId={cycleId}
                onFilterChange={() => setSelectedField(undefined)}
              />
            </Widget>
            <DeliverablesCardView
              selectedField={selectedField}
              setSelectedField={setSelectedField}
            />
            <SkillsCardView
              selectedField={selectedField}
              setSelectedField={setSelectedField}
            />
            <CultureValuesCardView
              selectedField={selectedField}
              setSelectedField={setSelectedField}
            />
            {!!recommendations.length && (
              <BarRaiserView recommendations={recommendations} />
            )}
            <FeedbackView
              recommendationData={recommendations?.[0]?.recommendation}
              reviews={recommendations?.map(el => el.recommendation)}
            />
            <AdditionalQuestions data={values.summary} isViewMode />
            <PageActions maxWidth="100%">
              <Flex justifyContent="center">
                <EditButton />
              </Flex>
            </PageActions>
          </VStack>
        }
        right={
          <StyledSticky top={70}>
            {renderRightColumContent(values, selectedField)}
          </StyledSticky>
        }
      />
    </Flex>
  )
})
