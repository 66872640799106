import React, { useEffect, useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { connect } from 'lape'
import { setAutoFreeze } from 'immer'

import NotificationSystem from '@components/Notification/NotificationSystem'
import { selectAuthenticated, selectUser } from '@src/store/auth/selectors'
import {
  API,
  COOKIE,
  Environments,
  SESSION_STORAGE,
  WSS_ENDPOINT,
  env,
} from '@src/constants/api'
import {
  logOutAction,
  setAuthenticatedAction,
  setFeatureFlagsAction,
  setPermissionsAction,
  setSubscriptionStateAction,
  setUserAction,
} from '@src/store/auth/actions'
import { UserInterface } from '@src/interfaces/user'
import AdminInterface from '@src/features/AdminInterface/AdminInterface'
import { Feedback } from '@src/features/Feedback/Feedback'
import { WebsocketType } from '@src/interfaces/data'
import DevWarningBar from '@src/features/DevWarningBar/DevWarningBar'
import { DemoModeWarningBar } from '@src/features/DemoMode/DemoModeWarningBar'

import { PUBLIC_PREFIX, ROUTES, SIGNUP, WORKSPACES } from '@src/constants/routes'
import MainSections from './MainSections/MainSections'
import LoginRedirect from './Login/LoginRedirect'
import { entriesToObject } from '@src/utils/queryParamsHooks'
import { apiWithoutHandling } from '@src/api'
import { setFeedbackOpen } from '@src/store/feedback/actions'
import ClosePopup from '@src/pages/Login/ClosePopup'
import { finishUpload } from '@src/store/performance/actions'
import { UploadResultInterface } from '@src/interfaces/supportTool'
import notificationState from '@src/store/notifications/state'
import EngagementQuestionPage from '@src/pages/Engagement/EngagementQuestionPage'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import Onboarding from './Onboarding'
import LoginRouter from '@src/pages/Login/Router'
import MyProfile from './MyProfile/MyProfile'
import { IdStatuses } from '@src/interfaces/employees'
import globalSearchState from '@components/GlobalSearchSidebar/GlobalSearchSidebarState'
import GlobalSearchSidebar from '@components/GlobalSearchSidebar/GlobalSearchSidebar'
import PublicRouter from '@src/pages/Public/PublicRouter'
import PermissionTransferRouter from './PermissionTransfer'
import GrantPermissionsRedirect from '@src/pages/GrantPermissionsRedirect/GrantPermissionsRedirect'
import { WhoAmIInterface } from '@src/interfaces/auth'
import { MainLayout } from '@src/features/MainLayout/MainLayout'
import AppRouter from '@src/pages/AppRouter'
import { HelpCenter } from '@src/components/HelpCenter/HelpCenter'
import { BannerInterface } from '@src/interfaces/banners'
import { useQueryClient } from 'react-query'
import {
  AppRedirectRouter,
  DASHBOARD_ROUTE,
  LEGACY_TABLE_ROUTE,
} from './AppRedirectRouter'
import { getWSToken } from '@src/api/login'
import SignupPage from '@src/pages/SignUp'
import { Flex } from '@revolut/ui-kit'
import { RevbetsPage } from './Revbets/Revbets'
import {
  SystemNotificationsUnreadInterface,
  SystemNotificationDescriptionInterface,
} from '@src/interfaces/systemNotifications'
import { signupState } from '@src/pages/SignUp/common'
import { cookiesApi } from '@src/utils/cookies'
import { Authenticated } from '@src/store/auth/constants'
import { Plans } from '@src/pages/Forms/Plans/Plans'
import { useDevSetPipeline } from '@src/pages/Login/common'
import {
  sendNotification,
  useNotificationPermission,
} from '@src/features/Notifications/browserNotifications'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { AccountClosed } from '@src/pages/AccountClosed/AccountClosed'
import { AccountRestricted } from '@src/pages/AccountRestricted/AccountRestricted'
import { TestingPeriodEnded } from '@src/pages/TestingPeriodEnded/TestingPeriodEnded'
import { LandingPage } from '@src/pages/Landing/Landing'
import { isCommercialRoot } from '@src/utils'
import OnboardingChecklist from './OnboardingChecklist'
import TestingCycleWarningBar from '@src/features/TestingCycleWarningBar/TestingCycleWarningBar'
import { SandboxPage } from '@src/pages/Sandbox/SandboxPage'
import { useWorkspaceContext } from '@src/features/Workspaces/WorkspaceContext'
import { useSaveLocationHistory } from '@src/utils/useSaveLocationHistory'
import { useCurrentTenantInfo } from '@src/api/tenants'

setAutoFreeze(false)

const App = () => {
  const authenticated = useSelector(selectAuthenticated)
  const user = useSelector(selectUser)
  const [isLoading, setIsLoading] = useState(true)
  const [isAccountClosed, setIsAccountClosed] = useState(false)
  const [isAccountRestricted, setIsAccountRestricted] = useState(false)
  const queryClient = useQueryClient()
  useNotificationPermission(authenticated)
  const dispatch = useDispatch()

  const tenantInfo = useCurrentTenantInfo()

  const workspaceContext = useWorkspaceContext()

  const isMain = useRouteMatch({
    path: ROUTES.MAIN,
    strict: true,
    sensitive: true,
  })
  const isPublic = useRouteMatch({
    path: PUBLIC_PREFIX,
    strict: true,
    sensitive: true,
  })
  const isSignup = useRouteMatch({
    path: SIGNUP,
    strict: true,
    sensitive: true,
  })
  const isLogin = useRouteMatch({
    path: ROUTES.LOGIN.ANY,
    strict: true,
    sensitive: true,
  })
  const isWorkspace = useRouteMatch({
    path: WORKSPACES.ANY,
    strict: true,
    sensitive: true,
  })
  const onboardingRouteMatch = useRouteMatch(ROUTES.ONBOARDING.ANY)
  const isOnboarding = !!onboardingRouteMatch
  const isLandingPage =
    isMain &&
    !authenticated &&
    !workspaceContext?.workspace &&
    (isCommercialRoot() || env === Environments.developmentCommercialRoot)
  const isTwoFactorLogin =
    sessionStorage.getItem(SESSION_STORAGE.TWO_FACTOR_LOGIN) != null

  useDevSetPipeline()
  useSaveLocationHistory()

  useEffect(() => {
    if (!isPublic && !isOnboarding && !isSignup && !isLandingPage && !isWorkspace) {
      authenticatedEffect()
    } else if (isLandingPage && isTwoFactorLogin) {
      setIsLoading(false)
      navigateTo(ROUTES.TWO_FACTOR_LOGIN)
    } else {
      setIsLoading(false)
    }
  }, [authenticated, isPublic, isOnboarding, isSignup, isWorkspace])

  // hack around the fact that logout is redux action
  useEffect(() => {
    if (notificationState.shouldLogOut && !isPublic) {
      dispatch(logOutAction())
      notificationState.shouldLogOut = false
    }
  }, [notificationState.shouldLogOut, isPublic])

  const authenticatedEffect = async () => {
    setIsLoading(true)

    if (isTwoFactorLogin) {
      setIsLoading(false)
      navigateTo(ROUTES.TWO_FACTOR_LOGIN)
      return
    }

    if (!authenticated) {
      if (!isLogin) {
        dispatch(logOutAction())
      }
      /** Need to hide the full page spinner for `/login` routes */
      setIsLoading(false)
      return
    }

    try {
      const data = await apiWithoutHandling.get<WhoAmIInterface>(API.WHOAMI)
      if (data?.data?.employee) {
        dispatch(setUserAction(data?.data?.employee))
      }

      if (data?.data?.authenticated) {
        const permissions = data.data?.permissions
        const newFeatureFlags = data.data?.feature_flags
        const subscriptionState = data.data?.subscription_state

        workspaceContext?.saveActiveWorkspace()

        cookiesApi.set(COOKIE.AUTHENTICATED, Authenticated.authenticated)
        dispatch(setSubscriptionStateAction(subscriptionState))
        dispatch(setAuthenticatedAction(true))
        dispatch(setPermissionsAction(permissions))
        dispatch(setFeatureFlagsAction(newFeatureFlags))

        const query = entriesToObject(
          Array.from(new URLSearchParams(window.location.search).entries()),
        )

        if (query.openFeedback) {
          dispatch(setFeedbackOpen(true))
        }

        if (data.data.subscription_state === 'closed') {
          setIsAccountClosed(true)
        }
        if (data.data.subscription_state === 'restricted') {
          setIsAccountRestricted(true)
        }

        Sentry.setUser({ id: `${user.id}` })

        try {
          const wsToken = await getWSToken()

          const webSocket = new WebSocket(
            `${WSS_ENDPOINT()}?token=${encodeURIComponent(wsToken.data.wss_token)}`,
          )

          webSocket.onmessage = e => {
            const event = JSON.parse(e.data)
            if (event.type === WebsocketType.Permission) {
              const newUser: UserInterface = event.data
              dispatch(setPermissionsAction(newUser?.user_permissions))
            }
            if (event.type === WebsocketType.PerformanceGradesUpload) {
              const result: UploadResultInterface = event.data
              dispatch(finishUpload(result.success))
            }
            if (
              event.type === WebsocketType.BannerAdd ||
              event.type === WebsocketType.BannerRemove
            ) {
              const result: BannerInterface = event.data
              queryClient.setQueryData([API.BANNERS, 'v1', null], result)
            }
            if (event.type === WebsocketType.NotificationCountChanged) {
              const result: SystemNotificationsUnreadInterface = event.data
              queryClient.setQueryData(
                [`${API.SYSTEM_NOTIFICATIONS}/unread`, 'v1', null],
                result,
              )
            }
            if (event.type === WebsocketType.NotificationAdd) {
              const result: SystemNotificationDescriptionInterface = event.data
              sendNotification(result)
            }
          }
        } catch {
          Sentry.captureMessage('GET WebSocket token failed')
        }
      }

      if (data?.data?.employee?.status.id === IdStatuses.onboarding) {
        navigateReplace(pathToUrl(ROUTES.ONBOARDING.START, { id: data.data.employee.id }))
      }
    } catch (e) {
      console.warn(e)
    } finally {
      setIsLoading(false)
    }
  }

  if (isAccountClosed) {
    return (
      <>
        <DevWarningBar />
        <AdminInterface />
        <AccountClosed />
      </>
    )
  }

  if (isAccountRestricted) {
    return (
      <>
        <DevWarningBar />
        <AdminInterface />
        <AccountRestricted />
      </>
    )
  }

  if (tenantInfo?.demo_mode_expired) {
    return (
      <>
        <DevWarningBar />
        <AdminInterface />
        <Switch>
          <Route exact path={ROUTES.PLANS.ANY}>
            <Plans />
          </Route>
          <Route>
            <TestingPeriodEnded />
          </Route>
        </Switch>
      </>
    )
  }

  if (isPublic) {
    return (
      <>
        <DevWarningBar />
        <AdminInterface />
        <PublicRouter />
      </>
    )
  }

  if (isSignup || signupState.cookie != null) {
    return (
      <>
        <DevWarningBar />
        <NotificationSystem />
        <AdminInterface />
        <SignupPage />
      </>
    )
  }

  if (isLoading) {
    return (
      <Flex width="100%" height="100vh" alignItems="center" justifyContent="center">
        <PageLoading />
      </Flex>
    )
  }

  return (
    <>
      <DevWarningBar />
      <TestingCycleWarningBar />
      <NotificationSystem />
      <Feedback />
      <AdminInterface />
      <Switch>
        <Route exact path={ROUTES.REVBETS}>
          <RevbetsPage />
        </Route>
        {isLandingPage && (
          <Route exact path={ROUTES.MAIN}>
            <LandingPage />
          </Route>
        )}
        <Route exact path={ROUTES.FORMS.EMPLOYEE.MY_PROFILE} component={MyProfile} />
        <Route exact path={ROUTES.ENGAGEMENT_PAGE} component={EngagementQuestionPage} />
        <Route exact path={ROUTES.LOGIN.REDIRECT} component={LoginRedirect} />
        <Route exact path={ROUTES.SANDBOX} component={SandboxPage} />
        <Route
          exact
          path={ROUTES.GRANT_PERMISSIONS_REDIRECT}
          component={GrantPermissionsRedirect}
        />
        <Route exact path={ROUTES.CLOSE_POPUP} component={ClosePopup} />
        <Route
          exact
          path={[
            ROUTES.MAIN,
            ROUTES.PEOPLE.ANY,
            ROUTES.RECRUITMENT.ANY,
            ROUTES.ORGANISATION.ANY,
            ROUTES.FUNCTION.ANY,
            ROUTES.HUB.ANY,
            ROUTES.PINNED,
          ]}
        >
          <MainLayout>
            <MainSections />
          </MainLayout>
        </Route>
        <Route exact path={ROUTES.ONBOARDING.ANY} component={Onboarding} />
        <Route
          path={[ROUTES.LOGIN.MAIN, WORKSPACES.ANY, ROUTES.TWO_FACTOR_LOGIN]}
          component={LoginRouter}
        />
        <Route exact path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ANY}>
          <MainLayout>
            <PermissionTransferRouter />
          </MainLayout>
        </Route>
        <Route exact path={ROUTES.PLANS.ANY}>
          <Plans />
        </Route>
        <Route path={ROUTES.ONBOARDING_CHECKLIST.ANY}>
          <DemoModeWarningBar />
          <OnboardingChecklist />
        </Route>
        <Route
          path={[
            DASHBOARD_ROUTE,
            LEGACY_TABLE_ROUTE,
            ROUTES.FORMS.TEAM.GENERAL,
            ROUTES.FORMS.DEPARTMENT.GENERAL,
            ROUTES.FUNCTION.SKILLS,
            ROUTES.FORMS.INTERVIEW_DASHBOARD.PENDING,
            ROUTES.FORMS.INTERVIEW_DASHBOARD.COMPLETED,
            ROUTES.FORMS.INTERVIEW_TOOL_SCORECARD.ANY,
            ROUTES.FORMS.INTERVIEW_TOOL_CANDIDATE.ANY,
            ROUTES.PERFORMANCE.SUPPORT_TOOL.ANY,
            ROUTES.PERFORMANCE.REVIEW_CYCLES,
            ROUTES.PLATFORM_ONBOARDING.SETUP_STEPS.ANY,
            ROUTES.FORMS.EMPLOYEE.KPI.ANY,
            ROUTES.FORMS.EMPLOYEE.ENGAGEMENT.DRIVERS,
            ROUTES.FORMS.EMPLOYEE.ENGAGEMENT.QUESTIONS,
            ROUTES.FORMS.EMPLOYEE.ENGAGEMENT.FEEDBACK,
            ROUTES.FORMS.EMPLOYEE.TALENT.ANY,
          ]}
        >
          <AppRedirectRouter />
        </Route>
        <Route path={ROUTES.ANY}>
          <MainLayout>
            <AppRouter />
          </MainLayout>
        </Route>
      </Switch>
      <GlobalSearchSidebar
        isOpen={globalSearchState.open}
        showAction={globalSearchState.showAction}
        onClose={() => {
          globalSearchState.open = false
          globalSearchState.showAction = false
        }}
      />
      {!isOnboarding && <HelpCenter />}
    </>
  )
}

export default connect(App)
