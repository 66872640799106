import React from 'react'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  GradeOption,
  kpiRating,
  deliverablesRating,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'

export const ProbationDeliverablesCardView = connect(
  ({ selectedField, setSelectedField }: CommonCardProps) => {
    const { values } = useLapeContext<ReviewSummaryInterface>()
    const hasKPI = !!values.summary?.kpis_section

    return (
      <>
        {hasKPI ? (
          <Card
            data={values}
            type={CardContentTypes.KPI}
            title="Goals and deliverables"
            icon="Target"
            fields={[
              {
                field: 'summary.kpis_section.rating',
                title: 'Contribution and impact',
                grades: kpiRating as GradeOption[],
              },
            ]}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
            isViewMode
          />
        ) : (
          <Card
            data={values}
            type={CardContentTypes.DELIVERABLES}
            title="Goals and deliverables"
            icon="Target"
            fields={[
              {
                field:
                  'summary.calculated_deliverables_ratings.recommended_rating.rating',
                title: 'Contribution and impact',
                grades: deliverablesRating as GradeOption[],
              },
            ]}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
            isViewMode
          />
        )}
      </>
    )
  },
)
