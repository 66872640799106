import { goBack } from '@src/actions/RouterActions'
import React, { useState } from 'react'
import { StatusPopup, useStatusPopup, VStack, Box, TextArea } from '@revolut/ui-kit'
import { CrossSmall, IconComponentType } from '@revolut/icons'
import { FieldOptions, Statuses } from '@src/interfaces'
import { SettingsButtonsInstanceProps } from '@src/features/SettingsButtons'
import { EntityPermissions } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { MutationResult } from '@src/features/SettingsButtons/types'
import { UNKNOWN_ERROR } from '@src/pages/Forms/QueryForm/constants'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import SettingButton, { SettingsButtonProps } from '../SettingsButton'

interface RejectButtonProps extends SettingsButtonsInstanceProps, SettingsButtonProps {
  data: {
    field_options: FieldOptions
    id: number
  }
  api: () => MutationResult
  afterSubmitUrl?: string
  statusFieldName?: string
  rejectionFieldName: string
  label?: string
  icon?: IconComponentType
  onBeforeSubmit?: () => void
  onAfterSubmit?: () => void
}

export const RejectButton = ({
  data,
  api,
  useMoreButton,
  afterSubmitUrl,
  isVisible = true,
  globalPermissions,
  entityPermissions = EntityPermissions.Approve,
  rejectionFieldName,
  statusFieldName = 'status',
  label = 'Reject',
  icon = CrossSmall,
  onBeforeSubmit,
  onAfterSubmit,
}: RejectButtonProps) => {
  const statusPopup = useStatusPopup()
  const [openedDialog, setOpenedDialog] = useState(false)
  const [rejectionReason, setRejectionReason] = useState<string | undefined>(undefined)
  const { mutateAsync: updateEntity, isLoading } = api()
  const permissions = useSelector(selectPermissions)

  const canReject =
    data.field_options?.actions?.includes(entityPermissions) ||
    globalPermissions?.some(p => permissions.includes(p))

  const reject = async () => {
    setOpenedDialog(false)

    statusPopup.show(
      <StatusPopup variant="loading">
        <StatusPopup.Title>Rejecting...</StatusPopup.Title>
      </StatusPopup>,
    )

    try {
      onBeforeSubmit?.()
      await updateEntity([
        data.id,
        { [statusFieldName]: Statuses.rejected, [rejectionFieldName]: rejectionReason },
      ])

      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Rejected successfully</StatusPopup.Title>
        </StatusPopup>,
      )

      if (afterSubmitUrl) {
        goBack(afterSubmitUrl)
      }
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>{error?.data?.message || UNKNOWN_ERROR}</StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      onAfterSubmit?.()
    }
  }

  if (!isVisible || !canReject) {
    return null
  }

  return (
    <>
      <ConfirmationDialog
        open={openedDialog}
        onClose={() => {
          setOpenedDialog(false)
        }}
        onReject={() => {
          setOpenedDialog(false)
        }}
        onConfirm={reject}
        label="Rejection reason"
        body={
          <VStack space="s-24">
            <Box>
              <TextArea
                rows={2}
                label="Enter reason for rejection"
                value={rejectionReason}
                onChange={e => setRejectionReason(e.currentTarget.value)}
              />
            </Box>
          </VStack>
        }
        submitDisabled={!rejectionReason}
        yesMessage="Confirm"
        noMessage="Cancel"
      />
      <SettingButton
        useMoreButton={useMoreButton}
        useIcon={icon}
        variant="negative"
        pending={isLoading}
        onClick={e => {
          e.preventDefault()
          return setOpenedDialog(true)
        }}
        data-testid="reject-btn"
      >
        {label}
      </SettingButton>
    </>
  )
}
