import React from 'react'
import { useTable } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import {
  FilterByInterface,
  SORT_DIRECTION,
  SortByInterface,
  Stats,
} from '@src/interfaces/data'
import { kpisRequests } from '@src/api/kpis'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { Statuses } from '@src/interfaces'
import { useTableReturnType } from '@components/Table/hooks'
import { getKpiPerformanceGraph } from '@src/api/department'
import { RowInterface } from '@src/interfaces/data'
import Tooltip from '@components/Tooltip/Tooltip'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import IconButton from '@components/ButtonIcon/IconButton'
import {
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiPerformanceColumnWithLooker,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
} from '@src/constants/columns/kpi'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { KPISettingDates } from '@src/features/Popups/ImportantKpiMessage'
import useKPIWarning from '@src/hooks/UseKPIWarning'
import { Box, IconButton as NewIconButton, MoreBar, TableWidget } from '@revolut/ui-kit'
import { Info, Pencil, Warning } from '@revolut/icons'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useQuery } from '@src/utils/queryParamsHooks'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { TableNames } from '@src/constants/table'
import { rowHighlight } from '@src/features/KPI'
import { useRenderRefreshKPIsTableNote } from '@src/utils/kpi'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { KpiHelpButton } from '@src/features/KPI/KpiHelpButton'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { foreignGoalColumn } from '@src/constants/columns/goals'
import { selectorKeys } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

interface StructureProps {
  data: DepartmentInterface
  table: useTableReturnType<KpiInterface, Stats>
  warnAboutMandatoryKPIs: boolean
}

const departmentRow = (allowedLooker: boolean): RowInterface<KpiInterface> => ({
  highlight: data => rowHighlight(data.target_status),
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...kpiGenericNameColumn,
    },
    {
      ...foreignGoalColumn,
      width: 100,
    },
    allowedLooker
      ? { ...kpiPerformanceColumnWithLooker, width: 130 }
      : {
          ...kpiPerformanceColumn,
          width: 130,
        },
    {
      ...kpiInitialValueColumn,
      width: 60,
    },
    {
      ...kpiCurrentValueColumn,
      width: 60,
    },
    {
      ...kpiTargetColumn,
      width: 60,
    },
    {
      ...kpiUnitColumn,
      width: 60,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...ownerNameWithAvatarColumn,
      width: 150,
    },
    {
      ...kpiUpdateTypeColumn,
      width: 60,
    },
  ],
})

const KPI = ({ data, table, warnAboutMandatoryKPIs }: StructureProps) => {
  const { query } = useQuery<{ review_cycle__offset: string }>()
  const featureFlags = useSelector(selectFeatureFlags)
  const { showDepartment, reviewCycleData } = useKPIWarning()
  const allowedLooker = featureFlags.includes(FeatureFlags.Allowed_Looker)
  const canAdd = data.field_options.permissions?.includes(
    PermissionTypes.CanAddKPIsDepartment,
  )
  const user = useSelector(selectUser)

  const canChangeKpiWeight = data.field_options.permissions?.includes(
    PermissionTypes.ChangeKpiWeight,
  )

  const canRefreshKpis = data.field_options.permissions?.includes(
    PermissionTypes.CanRefreshKpis,
  )

  const { refreshNote, refreshButton } = useRenderRefreshKPIsTableNote(
    table,
    canRefreshKpis,
  )

  const handleEditKPIs = () => {
    const baseUrl = pathToUrl(ROUTES.FORMS.ASSIGN_KPIS.DEPARTMENT, { id: data.id })
    const offset = query.review_cycle__offset?.split(',')?.[0] || 0

    navigateTo(`${baseUrl}?review_cycle__offset=${offset}`)
  }

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL, {}), {
      initialValues: getKPIFormInitialValues(user, {
        department: { name: data.name, id: data.id },
      }),
    })
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <CycleFilter
          type={CycleFilterType.NewUI}
          onFilterChange={table.onFilterChange}
          columnName="cycle__id"
          selector={selectorKeys.review_cycles}
          filter={table.filterBy}
          filterInputType={FilterSelectType.SingleSelect}
        />
        <OverallProgress
          value={table?.stats?.avg_performance}
          id={data.id}
          fetchData={getKpiPerformanceGraph}
          tooltip="Overall progress is calculated as the weighted average of all approved KPIs."
        />
        {warnAboutMandatoryKPIs && (
          <Tooltip
            placement="right"
            text="This profile has mandatory KPIs which must have 100% progress by cycle end, otherwise Overall Progress will be set to zero."
          >
            <IconButton>
              <Warning color="red" />
            </IconButton>
          </Tooltip>
        )}
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          {canAdd && (
            <MoreBar.Action onClick={handleNewRow} useIcon="Plus">
              Add New KPI
            </MoreBar.Action>
          )}
          {canChangeKpiWeight && (
            <MoreBar.Action useIcon={Pencil} onClick={handleEditKPIs}>
              Edit KPIs
            </MoreBar.Action>
          )}
          {refreshButton}
          <KpiHelpButton kpiChampions={data.kpi_champions} />
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Filters>
        {showDepartment && (
          <Tooltip
            placement="left"
            body={
              <Box minWidth={600} color="background" pb="s-16" pr="s-16" pl="s-32">
                {reviewCycleData && <KPISettingDates cycle={reviewCycleData} />}
              </Box>
            }
          >
            <NewIconButton useIcon={Info} aria-label="Info" color="red" size={32} />
          </Tooltip>
        )}
      </TableWidget.Filters>
      <TableWidget.Table>
        <AdjustableTable<KpiInterface>
          expandableType="chevron"
          name={TableNames.DepartmentKPIs}
          useWindowScroll
          dataType="KPI"
          row={departmentRow(allowedLooker)}
          {...table}
          fetchChildren={(parentIndexes, id) => {
            return table.fetchChildren(parentIndexes, id, [
              {
                filters: [{ name: `${data.id}`, id: data.id }],
                columnName: 'team__department__id',
                nonResettable: true,
              },
            ])
          }}
          onRowClick={onKPITableRowClick}
          noDataMessage="Please add your department KPIs to see them here."
          useFetchedChildren
        />
        {refreshNote}
      </TableWidget.Table>
    </TableWidget>
  )
}
interface StructureProps {
  data: DepartmentInterface
}

export const getKpiFilters = (
  data: DepartmentInterface,
  cycle: ReviewCyclesInterface,
): FilterByInterface[] => [
  {
    filters: [{ name: `${data.id}`, id: data.id }],
    columnName: 'department__id',
    nonResettable: true,
    nonInheritable: true,
  },
  {
    filters: [
      { name: Statuses.pending, id: Statuses.pending },
      { name: Statuses.approved, id: Statuses.approved },
      { name: Statuses.future, id: Statuses.future },
      { name: Statuses.completed, id: Statuses.completed },
    ],
    columnName: 'target_status',
  },
  {
    filters: [{ name: cycle.name, id: cycle.id }],
    columnName: 'cycle__id',
    nonResettable: true,
  },
]

export const kpiSorting: SortByInterface[] = [
  {
    sortBy: 'weight',
    direction: SORT_DIRECTION.ASC,
    nonResettable: true,
  },
  {
    sortBy: 'has_children',
    direction: SORT_DIRECTION.ASC,
  },
]

export const DepartmentTargets = ({
  data,
  cycle,
}: {
  data: DepartmentInterface
  cycle: ReviewCyclesInterface
}) => {
  const table = useTable<KpiInterface, Stats>(
    kpisRequests,
    getKpiFilters(data, cycle),
    kpiSorting,
  )

  return (
    <KPI
      data={data}
      table={table}
      warnAboutMandatoryKPIs={!!table?.stats?.mandatory_kpi_incomplete}
    />
  )
}
