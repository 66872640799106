import React from 'react'
import { useSelector } from 'react-redux'
import { Button, HStack, TableWidget } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { documentsCollectionsRequests } from '@src/api/documentsCollections'
import {
  documentsCollectionsActionsColumn,
  documentsCollectionsTemplatesCountColumn,
  documentsCollectionsName,
} from '@src/constants/columns/documentsCollections'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { DocumentsCollectionsInterface } from '@src/interfaces/documentsCollections'
import { pathToUrl } from '@src/utils/router'
import { TableActionButton } from '@components/Button/TableActionButton'
import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/Table/AdjustableTable'
import Stat from '@src/components/Stat/Stat'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

const getRow = (canEdit: boolean): RowInterface<DocumentsCollectionsInterface> => ({
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT_COLLECTION.PREVIEW, { id })),
  cells: [
    {
      ...documentsCollectionsName,
      width: 350,
    },
    {
      ...documentsCollectionsTemplatesCountColumn,
      width: 200,
    },
    {
      ...documentsCollectionsActionsColumn,
      width: 200,
      insert: ({ data }) => {
        return (
          <HStack space="s-12">
            {canEdit && (
              <TableActionButton
                onClick={e => {
                  e.stopPropagation()
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.DOCUMENT_COLLECTION.GENERAL, {
                      id: data.id,
                    }),
                  )
                }}
              >
                Edit
              </TableActionButton>
            )}
            <TableActionButton
              onClick={e => {
                e.stopPropagation()
                navigateTo(
                  pathToUrl(ROUTES.FORMS.DOCUMENT_COLLECTION.PREVIEW, {
                    id: data.id,
                  }),
                )
              }}
            >
              View
            </TableActionButton>
          </HStack>
        )
      },
    },
  ],
})

export const DocumentsCollections = () => {
  const permissions = useSelector(selectPermissions)

  const canAddDocumentsCollections = permissions?.includes(
    PermissionTypes.AddDocumentCollection,
  )
  const canEditDocumentsCollections = permissions?.includes(
    PermissionTypes.ChangeDocumentCollection,
  )

  const table = useTable<DocumentsCollectionsInterface>(documentsCollectionsRequests)

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat label="Total" mb="s-16" val={table.loading ? undefined : table.count} />
      </TableWidget.Info>
      <TableWidget.Actions>
        {canAddDocumentsCollections && (
          <Button
            onClick={() => {
              navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT_COLLECTION.GENERAL))
            }}
            size="sm"
            useIcon="Plus"
            variant="secondary"
          >
            Create new collection
          </Button>
        )}
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable
          name={TableNames.DocumentCollections}
          noDataMessage="All documents collections will appear here"
          row={getRow(canEditDocumentsCollections)}
          useWindowScroll
          {...table}
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
