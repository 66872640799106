import {
  Bullet,
  DetailsCell,
  HStack,
  Token,
  VStack,
  Text,
  IconButton,
  Box,
} from '@revolut/ui-kit'
import React, { PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

export const BorderedCell = styled(DetailsCell)`
  border: 1px solid ${Token.color.greyTone10};
  padding-bottom: 0;
`

// UI-kit expandable cell mount hidden content only when expanded
// this approach doesn't work well when we need persistent state or mounting has async tasks and loading state
// perhaps ui-kit may add such persistent variant for the ExpandableCell component
// thread is started in slack: https://team-revolut.slack.com/archives/CDXMY0GF9/p1710435853768639
export const PersistentExpandableCell = ({
  title,
  description,
  index,
  onDelete,
  side,
  expanded,
  onToggle,
  children,
}: PropsWithChildren<{
  title: ReactNode
  description: ReactNode
  index: number
  side: ReactNode
  onDelete?: () => Promise<void>
  expanded: boolean
  onToggle: () => void
}>) => {
  const [mounted, setMounted] = useState(expanded)
  useEffect(() => {
    if (expanded && !mounted) {
      setMounted(true)
    }
  }, [expanded])

  return (
    <BorderedCell>
      <CellHeader
        index={index}
        title={title}
        description={description}
        onDelete={onDelete}
        side={side}
        expanded={expanded}
        onToggle={onToggle}
      />
      <DetailsCell.Note height={expanded ? 'auto' : 0} overflow="hidden">
        <Box mt="s-16" mb="s-16">
          {mounted && children}
        </Box>
      </DetailsCell.Note>
    </BorderedCell>
  )
}

export function CellHeader({
  index,
  title,
  description,
  side,
  expanded,
  onToggle,
  onDelete,
}: {
  index: number
  title: ReactNode
  description: ReactNode
  side?: ReactNode
  expanded: boolean
  onToggle: () => void
  onDelete?: () => {}
}) {
  const [pending, setPending] = useState(false)

  const handleDelete = onDelete
    ? async () => {
        try {
          setPending(true)
          await onDelete()
        } finally {
          setPending(false)
        }
      }
    : undefined
  return (
    <>
      <DetailsCell.Title>
        <HStack space="s-12" align="center">
          <Bullet
            color={Token.color.white}
            variant="filled"
            backgroundColor={Token.color.greyTone20}
            size={18}
          >
            {index}
          </Bullet>
          <VStack>
            <Text variant="primary" color={Token.color.foreground}>
              {title}
            </Text>
            <Text variant="caption" color={Token.color.greyTone50}>
              {description}
            </Text>
          </VStack>
        </HStack>
      </DetailsCell.Title>
      <DetailsCell.Content>
        <HStack space="s-24" align="center" height="100%">
          <Box>{side}</Box>
          <HStack space="s-8" align="center" height="100%">
            <IconButton
              useIcon={expanded ? 'ChevronUp' : 'ChevronDown'}
              color={Token.color.greyTone20}
              onClick={onToggle}
            />
            {!!handleDelete && (
              <IconButton
                disabled={pending}
                size={18}
                useIcon={pending ? 'Loading' : 'Delete'}
                onClick={handleDelete}
                color={Token.color.greyTone20}
              />
            )}
          </HStack>
        </HStack>
      </DetailsCell.Content>
    </>
  )
}
