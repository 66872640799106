import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useRef,
} from 'react'
import { TargetFormInterface } from '../SidebarForms/AddGoalTargetForm'
import { FormValidatorContextType } from '@src/features/Form/FormValidator'

interface FormObserverContextInterface {
  registerForm: (form: TargetFormInterface, validator: FormValidatorContextType) => void
  unregisterForm: (id: number) => void
  getForms: () => TargetFormInterface[]
  getFormById: (id: number | undefined) => TargetFormInterface | null
  getFormValidator: (form: TargetFormInterface) => FormValidatorContextType | null
}

const FormObserverContext = createContext<FormObserverContextInterface>({
  registerForm: () => {},
  unregisterForm: () => {},
  getForms: () => [],
  getFormById: () => null,
  getFormValidator: () => null,
})

export const FormObserverProvider = ({ children }: PropsWithChildren<{}>) => {
  const forms = useRef<Map<TargetFormInterface, FormValidatorContextType>>(new Map())

  const context = useMemo<FormObserverContextInterface>(() => {
    return {
      registerForm: (form, validator) => {
        if (!forms.current?.has(form)) {
          forms.current.set(form, validator)
        }
      },
      unregisterForm: id => {
        const [form] =
          [...forms.current.entries()].find(entry => entry[0].values.id === id) || []
        form && forms.current.delete(form)
      },
      getForms: () => (forms.current ? [...forms.current.keys()] : []),
      getFormById: (id: number | undefined) => {
        const current = forms.current
        if (!current) {
          return null
        }

        for (let [form] of current.entries()) {
          if (form.values.id === id) {
            return form
          }
        }

        return null
      },
      getFormValidator: form => {
        const current = forms.current
        if (!current) {
          return null
        }

        return current.get(form) || null
      },
    }
  }, [])

  return (
    <FormObserverContext.Provider value={context}>
      {children}
    </FormObserverContext.Provider>
  )
}

export const useFormObserver = () => {
  return useContext(FormObserverContext)
}
