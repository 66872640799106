import React from 'react'
import {
  ChangeScorecardInterface,
  ReviewScorecardInterface,
  ScorecardError,
  SummarySkillCardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  SelectedFieldInterface,
  updateValueRating,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { getNormalizedCards, getUpdatedRatingCard } from '@src/utils/performance'
import set from 'lodash/set'
import { SkillsCommon } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/common/SkillsCommon'

export interface SkillsProps {
  selectedField: SelectedFieldInterface
}

export const Skills = connect(({ selectedField }: SkillsProps) => {
  const { values, errors, fields, submitFailed } =
    useLapeContext<ReviewScorecardInterface>()

  if (selectedField.cardIndex === undefined) {
    return null
  }

  const hasManagerValues = !!values.review_data.manager_values
  const managerValuesCards = values.review_data?.manager_values?.cards || []
  const skillsCards = hasManagerValues
    ? managerValuesCards
    : getNormalizedCards(values.review_data?.functional_skills?.cards || [])

  const errorsList = !submitFailed
    ? undefined
    : hasManagerValues
    ? (errors?.review_data?.manager_values?.cards as ScorecardError[])
    : (errors?.review_data?.functional_skills?.cards as ScorecardError[])

  const touched = Object.values(fields).some(state => state.touched)

  const onChange = async ({
    cardIndex,
    sectionIndex,
    optionIndex,
    value,
  }: ChangeScorecardInterface) => {
    set(
      values,
      `${selectedField.field}.sections.${sectionIndex}.items.${optionIndex}.value`,
      value,
    )

    const updatedCard = await getUpdatedRatingCard(
      values.id,
      values.category,
      values.reviewed_employee.id!,
      values.review_data?.functional_skills?.cards?.[cardIndex],
    )

    if (updatedCard) {
      set(values, `${selectedField.field}.rating`, updatedCard.rating)
    }
  }

  const onChangeManagerValues = async ({
    sectionIndex,
    value,
  }: ChangeScorecardInterface) => {
    set(values, `${selectedField.field}.sections.${sectionIndex}.value`, value)

    updateValueRating({
      values,
      path: selectedField.field,
    })
  }

  return (
    <SkillsCommon
      skillsCards={skillsCards as SummarySkillCardInterface[]}
      selectedField={selectedField}
      hasManagerValues={hasManagerValues}
      errors={errors}
      onChange={onChange}
      onChangeManagerValues={onChangeManagerValues}
      errorsList={errorsList}
      touched={touched}
    />
  )
})
