import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, Spacer, Subheader, VStack } from '@revolut/ui-kit'

import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageActions } from '@components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  DocumentsTemplatesInterface,
  DocumentsTemplateType,
  DocumentsTemplateTypeId,
} from '@src/interfaces/documentsTemplates'
import { pathToUrl } from '@src/utils/router'
import { navigateReplace } from '@src/actions/RouterActions'
import { selectUser } from '@src/store/auth/selectors'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import BottomText from '@components/Inputs/partials/BottomText'
import { PageBody } from '@src/components/Page/PageBody'
import LapeSingleCheckbox from '@components/Inputs/LapeFields/LapeSingleCheckbox'
import { CommonTabProps } from '.'

type Props = {
  templateTypeId: DocumentsTemplateTypeId
}
export const TabBasics = ({ nextTabPath, templateTypeId }: CommonTabProps & Props) => {
  const currentUser = useSelector(selectUser)
  const { values } = useLapeContext<DocumentsTemplatesInterface>()

  useEffect(() => {
    values.owner = { id: currentUser.id }
    values.template_type = { id: templateTypeId } as DocumentsTemplateType
  }, [])

  return (
    <PageBody>
      <VStack space="s-8">
        <Box>
          <Subheader>
            <Subheader.Title>What should your template be called?</Subheader.Title>
          </Subheader>
          <LapeNewInput required name="name" label="Name" />
        </Box>
        <Box>
          <Subheader>
            <Subheader.Title>
              Which category does this template belong to?
            </Subheader.Title>
          </Subheader>
          <LapeRadioSelectInput
            required={false}
            name="category"
            label="Category"
            selector={selectorKeys.document_categories}
          />
        </Box>
        <Box>
          <Subheader>
            <Subheader.Title>
              Do you want to include instructions for employees how to fulfil this
              request?
            </Subheader.Title>
          </Subheader>
          <Box>
            <LapeHTMLEditor name="instructions" placeholder="Instructions" height={200} />
            <BottomText
              bottomInfo={
                'Insert the instructions for employees how to request from this policy.' +
                ' For example include information on what kind of attachment or comment is required.'
              }
            />
          </Box>
        </Box>
        {values.template_type?.id === 'esignature' && (
          <Box>
            <Subheader>
              <Subheader.Title>
                Do you want to allow employees to self serve from this template?
              </Subheader.Title>
            </Subheader>
            <VStack space="s-16">
              <LapeSingleCheckbox name="enable_self_serve" label="Enable self serve" />
              {values.enable_self_serve && (
                <LapeRadioSelectInput
                  name="issuer"
                  label="Issuer"
                  selector={selectorKeys.employee}
                />
              )}
            </VStack>
          </Box>
        )}
      </VStack>
      <Spacer height={128} />
      <PageActions>
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          onAfterSubmit={res =>
            navigateReplace(pathToUrl(nextTabPath, { id: String(res.id) }))
          }
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </PageBody>
  )
}
