import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useParams } from 'react-router-dom'
import { goBack } from '@src/actions/RouterActions'
import { successNotification } from '@src/actions/NotificationActions'
import { pathToUrl } from '@src/utils/router'
import React, { useState } from 'react'
import { useToggleContext } from '@revolut/ui-kit'
import { CrossSmall, IconComponentType } from '@revolut/icons'
import LapeDescriptionDialog from '@src/features/Popups/LapeDescriptionDialog'
import { FieldOptions, Statuses } from '@src/interfaces'
import { SettingsButtonsInstanceProps } from '@src/features/SettingsButtons'
import { EntityPermissions } from '@src/store/auth/types'
import { AxiosError } from 'axios'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import SettingButton, { SettingsButtonProps } from '../SettingsButton'

interface RejectButtonProps extends SettingsButtonsInstanceProps, SettingsButtonProps {
  afterSubmitUrl?: string
  onBeforeSubmit?: () => void
  onAfterSubmit?: () => void
  submit?: () => Promise<any>
  onSubmitFailed?: (error: AxiosError) => void
  statusFieldName?: string
  dialog: {
    title: string
    description?: string
    placeholder: string
    fieldName: string
    useConfirmationDialog?: boolean
  }
  notification?: {
    updateMsg: string
    path?: string
  }
  label?: string
  icon?: IconComponentType
}

const LapeRejectButton = ({
  useMoreButton,
  afterSubmitUrl,
  notification,
  onAfterSubmit,
  isVisible = true,
  globalPermissions,
  entityPermissions = EntityPermissions.Approve,
  onBeforeSubmit,
  submit,
  onSubmitFailed,
  dialog,
  statusFieldName = 'status',
  label = 'Reject',
  icon = CrossSmall,
}: RejectButtonProps) => {
  const [loading, setLoading] = useState(false)
  const [openedDialog, setOpenedDialog] = useState(false)
  const form =
    useLapeContext<{ status?: Statuses; field_options: FieldOptions; id: number }>()
  const toggle = useToggleContext()
  const permissions = useSelector(selectPermissions)

  const params = useParams()
  const canReject =
    form.values.field_options?.actions?.includes(entityPermissions) ||
    globalPermissions?.some(p => permissions.includes(p))

  const submitWithForm = async () => {
    onBeforeSubmit?.()
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const fallbackStatus = form.values[statusFieldName]
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    form.values[statusFieldName] = Statuses.rejected

    try {
      setLoading(true)
      const result = await form.submit()
      form.reset(result)
      form.changelog = undefined
      if (afterSubmitUrl) {
        goBack(afterSubmitUrl)
      }
      if (notification) {
        successNotification(
          notification.updateMsg,
          notification.path
            ? pathToUrl(notification.path, { ...params, id: result?.id })
            : undefined,
        )
      }
      return result
    } catch (error) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      form.values[statusFieldName] = fallbackStatus
      onSubmitFailed?.(error)
    } finally {
      setLoading(false)
    }

    return null
  }

  const reject = async () => {
    onBeforeSubmit?.()
    setLoading(true)

    try {
      await (submit ? submit() : submitWithForm())
      onAfterSubmit?.()
    } catch (error) {
      onSubmitFailed?.(error)
    } finally {
      setLoading(false)
      setOpenedDialog(false)
    }

    return Promise.resolve()
  }

  if (!isVisible || !canReject) {
    return null
  }

  return (
    <>
      <LapeDescriptionDialog
        isOpened={openedDialog}
        loading={loading}
        onSubmit={reject}
        onClose={() => {
          toggle?.(false)
          setOpenedDialog(false)
        }}
        data={dialog}
      />
      <SettingButton
        useMoreButton={useMoreButton}
        useIcon={icon}
        variant="negative"
        pending={loading}
        onClick={e => {
          e.preventDefault()
          return setOpenedDialog(true)
        }}
        data-testid="reject-btn"
      >
        {label}
      </SettingButton>
    </>
  )
}

export default connect(LapeRejectButton)
