import React, { useEffect, useRef, useState } from 'react'
import * as pdfJsLib from 'pdfjs-dist'
import {
  Flex,
  HStack,
  Text,
  IconButton,
  Token,
  Icon,
  Sticky,
  Relative,
  Spinner,
  Box,
  ProductWidgetSkeleton,
  VStack,
  StatusWidget,
} from '@revolut/ui-kit'

import { formatPercentage } from '@src/utils/format'
import {
  PdfPreviewPagination,
  PdfPreviewScale,
  usePagination,
  useRenderPdf,
  useScale,
} from './common'

pdfJsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'

type PdfPreviewToolbarProps = {
  scale: PdfPreviewScale
  page: PdfPreviewPagination
  borderRadius: string
  actions?: React.ReactNode
}
export const PdfPreviewToolbar = ({
  scale,
  page,
  borderRadius,
  actions,
}: PdfPreviewToolbarProps) => {
  return (
    <Relative
      borderTopLeftRadius={borderRadius}
      borderTopRightRadius={borderRadius}
      style={{
        boxShadow: `0px 5px 10px ${Token.color.foreground_10}`,
        zIndex: 10,
      }}
    >
      <VStack
        bg={Token.color.background}
        borderTopLeftRadius={borderRadius}
        borderTopRightRadius={borderRadius}
      >
        <Flex
          width="100%"
          p="s-20"
          justifyContent="space-between"
          borderTopLeftRadius={borderRadius}
          borderTopRightRadius={borderRadius}
        >
          {page && (
            <HStack align="center" space="s-12">
              <IconButton
                size={15}
                useIcon="ChevronLeft"
                disabled={page.prevDisabled}
                onClick={page.prev}
                color={page.prevDisabled ? Token.color.greyTone50 : Token.color.blue}
                aria-label="Previous page"
              />
              <Text size="sm" color={Token.color.greyTone50}>
                Page {page.current} of {page.total}
              </Text>
              <IconButton
                size={15}
                useIcon="ChevronRight"
                disabled={page.nextDisabled}
                onClick={page.next}
                color={page.nextDisabled ? Token.color.greyTone50 : Token.color.blue}
                aria-label="Next page"
              />
            </HStack>
          )}
          <HStack align="center" space="s-12">
            <IconButton
              size={15}
              useIcon="Minus"
              disabled={scale.downDisabled}
              onClick={scale.down}
              color={scale.downDisabled ? Token.color.greyTone50 : Token.color.blue}
            />
            <HStack align="center" space="s-4">
              <Icon size={15} name="Search" color={Token.color.greyTone50} />
              <Text size="sm" color={Token.color.greyTone50}>
                {formatPercentage(scale.value)}
              </Text>
            </HStack>
            <IconButton
              size={15}
              useIcon="Plus"
              disabled={scale.upDisabled}
              onClick={scale.up}
              color={scale.upDisabled ? Token.color.greyTone50 : Token.color.blue}
            />
          </HStack>
        </Flex>
        {actions}
      </VStack>
    </Relative>
  )
}

const DocumentLoader = ({ height }: { height?: number }) => (
  <Flex
    flexDirection="column"
    height={height}
    width="100%"
    alignItems="center"
    justifyContent="center"
  >
    <Spinner size={32} color={Token.color.blue} />
  </Flex>
)

type Props = {
  fileUrl: string | undefined
  containerRef?: React.RefObject<HTMLDivElement>
  renderPageOverlay?: (
    scale: PdfPreviewScale,
    pageNum: number | undefined,
    viewportWidth: number | undefined,
    viewportHeight: number | undefined,
  ) => React.ReactNode
  toolbarStickyOffset?: number
  toolbarActions?: React.ReactNode
  onPageNumChange?: (newPageNum: number, totalPages: number | undefined) => void
  renderErrorBanner?: (e?: Error) => React.ReactElement
  forcePageNum?: number
  documentContainerRef?: React.RefObject<HTMLDivElement>
  toolbarContainerRef?: React.RefObject<HTMLDivElement>
}
export const PdfPreview = ({
  fileUrl,
  containerRef,
  renderPageOverlay,
  toolbarStickyOffset,
  toolbarActions,
  onPageNumChange,
  renderErrorBanner,
  forcePageNum,
  documentContainerRef,
  toolbarContainerRef,
}: Props) => {
  const [viewport, setViewPort] = useState<pdfJsLib.PageViewport>()
  const [pdfRenderingError, setPdfRenderingError] = useState<Error>()

  const borderRadius = Token.radius.r16
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const scale = useScale({})
  const page = usePagination({})

  useEffect(() => {
    if (forcePageNum) {
      page.setCurrent(forcePageNum)
    }
  }, [forcePageNum])

  useEffect(() => {
    onPageNumChange?.(page.current, page.total)
  }, [page.current, page.total])

  const pdfRenderCtx = useRenderPdf({
    url: fileUrl,
    canvasRef,
    scale: scale.value,
    parentWidth: containerRef?.current?.offsetWidth,
    pageNum: page.current,
    onLoadDocument: pdfDoc => {
      page.setTotal(pdfDoc.numPages)
    },
    onStartRendering: renderCtx => {
      setViewPort(renderCtx.viewport)
    },
    onError: (e: Error) => {
      setPdfRenderingError(e)
    },
  })

  const isDocReady = pdfRenderCtx.isMounted && !pdfRenderingError

  const renderPendingState = () => {
    if (pdfRenderingError) {
      return (
        renderErrorBanner?.(pdfRenderingError) || (
          <StatusWidget>
            <StatusWidget.Image image="https://assets.revolut.com/assets/3d-images/3D083.png" />
            <StatusWidget.Title>Failed to preview pdf file</StatusWidget.Title>
          </StatusWidget>
        )
      )
    }
    if (!pdfRenderCtx.isMounted || !fileUrl) {
      return <ProductWidgetSkeleton />
    }
    return null
  }
  return (
    <>
      {renderPendingState()}
      <Box display={isDocReady ? undefined : 'none'}>
        <VStack
          bg={Token.color.greyTone8}
          borderRadius={borderRadius}
          pb={pdfRenderCtx.isMounted ? 's-12' : undefined}
        >
          <Sticky
            top={toolbarStickyOffset}
            width="100%"
            zIndex={100}
            ref={toolbarContainerRef}
          >
            <PdfPreviewToolbar
              scale={scale}
              page={page}
              borderRadius={borderRadius}
              actions={toolbarActions}
            />
          </Sticky>
          <Flex
            flexDirection="column"
            width="100%"
            overflow="scroll"
            ref={documentContainerRef}
          >
            {pdfRenderCtx.pending && <DocumentLoader height={viewport?.height} />}
            <Relative display={pdfRenderCtx.pending ? 'none' : undefined} mx="auto">
              {renderPageOverlay?.(
                scale,
                page.current,
                viewport?.width,
                viewport?.height,
              )}
              <canvas ref={canvasRef} />
            </Relative>
          </Flex>
        </VStack>
      </Box>
    </>
  )
}
