import React from 'react'
import { useParams } from 'react-router-dom'
import Form from '@src/features/EmployeePerformance/Form'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCategory, ReviewScorecardInterface } from '@src/interfaces/performance'
import PerformanceCycleClosed from '@components/ScorecardGeneral/PerformanceCycleClosed'
import PerformancePage from '@src/pages/Forms/EmployeePerformance/components/PerformancePage'
import Page404 from '@src/pages/Page404/Page404'
import PerformanceErrorWidget from '@src/pages/Forms/EmployeePerformance/components/PerformanceErrorWidget'
import { usePutFirstScorecardOpening } from '@src/utils/performance'
import PerformanceHeaderCompact from '@src/pages/Forms/EmployeePerformance/components/PerformanceHeader'
import { ProbationContent } from '@src/pages/Forms/ProbationLayout/ProbationContent'
import { pipReviewLayoutRequests } from '@src/api/pip'

const Pip = () => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  usePutFirstScorecardOpening(values, ReviewCategory.PIP_V2)

  if (!values.review_data) {
    return <PerformanceErrorWidget />
  }

  if (!values.reviewed_employee?.id) {
    return <Page404 />
  }

  return (
    <PerformancePage showHeader={false}>
      {!values.current_user_can_edit_review ? (
        <>
          <PerformanceHeaderCompact />
          <PerformanceCycleClosed employeeId={values.reviewed_employee.id} />
        </>
      ) : (
        <ProbationContent />
      )}
    </PerformancePage>
  )
}

export const PipLayout = connect(() => {
  const params = useParams<{ id: string; employeeId: string }>()

  return (
    <Form
      api={pipReviewLayoutRequests}
      storageKey={`${ReviewCategory.PIP_V2}-${params.id}-${params.employeeId}`}
    >
      <Pip />
    </Form>
  )
})
