import React from 'react'
import { ErrorWidget, VStack } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import OnboardingChecklistSection from '@src/pages/OnboardingChecklist/OnboardingChecklistLandingPage/OnboardingChecklistSection'
import { onboardingCheckpointsSectionConfig } from '@src/pages/OnboardingChecklist/common/constants'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { useGetOnboardingCheckpoints } from '@src/api/onboardingChecklist'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { navigateTo } from '@src/actions/RouterActions'
import { OnboardingChecklistHeader } from '../components/OnboardingChecklistHeader'
import { FormattedMessage } from 'react-intl'

const Header = () => (
  <OnboardingChecklistHeader
    title={
      <FormattedMessage
        id="onboarding.general.onboardingChecklistTitle"
        defaultMessage="Onboarding checklist"
      />
    }
    description="Complete configuration steps to unlock tool full potential. Too many steps? Ask your team to help you. Start now!"
    backUrl={ROUTES.MAIN}
  />
)

const LandingPage = () => {
  useSetDocumentTitle('Onboarding')

  const { isLoading, isError } = useGetOnboardingCheckpoints()

  if (isLoading) {
    return <PageLoading />
  }

  if (isError) {
    return (
      <PageWrapper>
        <Header />
        <PageBody>
          <ErrorWidget>
            <ErrorWidget.Image />
            <ErrorWidget.Title />
            <ErrorWidget.Action onClick={() => navigateTo(ROUTES.MAIN)}>
              Back to main page
            </ErrorWidget.Action>
          </ErrorWidget>
        </PageBody>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <Header />
      <PageBody>
        <VStack space="s-16">
          <OnboardingChecklistSection
            title={onboardingCheckpointsSectionConfig.initialSetup.title}
            onboardingConfig={onboardingCheckpointsSectionConfig.initialSetup.config}
          />

          <OnboardingChecklistSection
            title={onboardingCheckpointsSectionConfig.organisation.title}
            subtitle="You can configure details here, but you can also skip if you don’t have data or add it later in the app"
            onboardingConfig={onboardingCheckpointsSectionConfig.organisation.config}
          />

          <OnboardingChecklistSection
            title={onboardingCheckpointsSectionConfig.people.title}
            subtitle="You can configure details here, but you can also skip if you don’t have data or add it later in the app"
            onboardingConfig={onboardingCheckpointsSectionConfig.people.config}
          />

          <OnboardingChecklistSection
            title={onboardingCheckpointsSectionConfig.performance.title}
            onboardingConfig={onboardingCheckpointsSectionConfig.performance.config}
          />

          <OnboardingChecklistSection
            title={onboardingCheckpointsSectionConfig.recruitment.title}
            onboardingConfig={onboardingCheckpointsSectionConfig.recruitment.config}
          />

          <OnboardingChecklistSection
            title={onboardingCheckpointsSectionConfig.finish.title}
            onboardingConfig={onboardingCheckpointsSectionConfig.finish.config}
          />
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}

export default LandingPage
