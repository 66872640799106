import {
  TabBar,
  VStack,
  Text,
  Flex,
  Ellipsis,
  Box,
  DataPoint,
  Tag,
  HStack,
} from '@revolut/ui-kit'
import { useGoalTargetPerformanceGraph } from '@src/api/goals'
import {
  BaseChartInner,
  timeRangeTabs,
} from '@src/components/Charts/BaseChart/BaseChartInner'
import { getStatusColor } from '@src/components/CommonSC/General'
import { PerformanceTimeRange } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import React, { useState } from 'react'

export const TargetsCharts = () => {
  const { values } = useLapeContext<GoalsInterface>()
  const targets = values.kpis
  const [selected, setSelected] = useState<GoalKpiDetails | undefined>(targets[0])
  const defaultTimeRangeType = PerformanceTimeRange.month
  const [timeRangeType, setTimeRangeType] =
    useState<PerformanceTimeRange>(defaultTimeRangeType)
  const { data } = useGoalTargetPerformanceGraph(selected?.id, timeRangeType)

  const maxTabWidth = 150

  return (
    <VStack space="s-16">
      <Box width="100%">
        <TabBar
          maxWidth="100%"
          variant="segmented fit"
          value={String(selected?.id)}
          onChange={id => id && setSelected(targets.find(t => String(t.id) === id))}
        >
          {targets.map(t => (
            <TabBar.Item to={String(t.id)} key={t.id}>
              <Text variant="h6" maxWidth={maxTabWidth}>
                <Ellipsis tooltip="auto">{t.name}</Ellipsis>
              </Text>
            </TabBar.Item>
          ))}
        </TabBar>
      </Box>

      <VStack height="100%" mt="s-16">
        {selected ? (
          <VStack space="s-16">
            <Flex justifyContent="space-between">
              <DataPoint>
                <DataPoint.Value>
                  <HStack gap="s-8" align="center">
                    <Text variant="h4">{selected.current_progress}</Text>
                    <Tag variant="outlined" color={getStatusColor(selected.status.id)}>
                      {selected.status.name}
                    </Tag>
                  </HStack>
                </DataPoint.Value>
                <DataPoint.Label>Current progress</DataPoint.Label>
              </DataPoint>
              <TabBar variant="segmented">
                {timeRangeTabs.map(timeRange => {
                  const isSelected = timeRangeType === timeRange
                  return (
                    <TabBar.Item
                      key={timeRange}
                      aria-selected={isSelected}
                      onClick={() => {
                        // onGraphTimeRangeChange?.(timeRange)
                        setTimeRangeType(timeRange)
                      }}
                    >
                      <Text
                        use="div"
                        fontSize="small"
                        px="s-12"
                        color={isSelected ? 'light-blue' : 'grey-tone-50'}
                        fontWeight={500}
                      >
                        {timeRange[0]?.toUpperCase()}
                      </Text>
                    </TabBar.Item>
                  )
                })}
              </TabBar>
            </Flex>
            <Flex
              flexDirection="column"
              minHeight={0}
              style={{ position: 'relative' }}
              height={{ all: 570, md: 400, sm: 250 }}
            >
              <BaseChartInner
                data={data}
                id={selected.id}
                graphTimeRange={timeRangeType}
              />
            </Flex>
          </VStack>
        ) : null}
      </VStack>
    </VStack>
  )
}
