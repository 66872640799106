import React from 'react'
import {
  ChangeScorecardInterface,
  PerformanceReviewTypes,
  PreviousReviewInterface,
  ReviewCategory,
  ScorecardError,
  SkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import ScorecardSection from '@components/ScorecardGeneral/ScorecardSection'
import {
  CardContentTypes,
  cardHasMissingValues,
  countSuperpowers,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import ScorecardValueBasedSection from '@components/ScorecardGeneral/ScorecardValueBasedSection'

interface Props {
  valuesCards: ValueBasedCardInterface[] | SkillCardInterface[]
  selectedField: SelectedFieldInterface
  isViewMode?: boolean
  hasCultureValues?: boolean
  previousReviews?: PreviousReviewInterface[]
  allErrors?: ScorecardError[] | null
  onChange?: (params: ChangeScorecardInterface) => void
  onChangeCultureValues?: (params: ChangeScorecardInterface) => void
  touched?: boolean
}

export const CultureValuesCommon = connect(
  ({
    valuesCards,
    isViewMode = false,
    selectedField,
    hasCultureValues,
    allErrors,
    onChange,
    onChangeCultureValues,
    previousReviews,
    touched,
  }: Props) => {
    if (selectedField.cardIndex === undefined) {
      return null
    }

    const card = valuesCards[selectedField.cardIndex]

    if (!card) {
      return null
    }

    const missingValues = cardHasMissingValues(
      CardContentTypes.VALUES,
      card as SkillCardInterface,
    )

    return hasCultureValues ? (
      <ScorecardValueBasedSection
        mt={0}
        card={card as ValueBasedCardInterface}
        cardIndex={selectedField.cardIndex}
        category={ReviewCategory.Performance}
        errors={allErrors?.[selectedField.cardIndex]}
        key={`${(card as ValueBasedCardInterface).value_id}`}
        onChange={onChangeCultureValues}
        sectionErrors={allErrors}
        superpowersCount={countSuperpowers(valuesCards as ValueBasedCardInterface[])}
        touched={touched}
        type={PerformanceReviewTypes.cultureValuesFit}
        valueId={(card as ValueBasedCardInterface).value_id}
        viewMode={isViewMode}
        hideCount
      />
    ) : (
      <ScorecardSection
        mt={0}
        noWidgetMargin
        skillId={`${
          (card as SkillCardInterface).skill_id ||
          (card as SkillCardInterface).criteria_key
        }`}
        cardIndex={selectedField.cardIndex}
        key={`${
          (card as SkillCardInterface).skill_id ||
          (card as SkillCardInterface).criteria_key
        }`}
        disabled={isViewMode}
        type={PerformanceReviewTypes.cultureFit}
        category={ReviewCategory.Performance}
        onChange={onChange}
        viewMode={isViewMode}
        errors={allErrors?.[selectedField.cardIndex]}
        sectionErrors={allErrors}
        touched={!!allErrors?.[selectedField.cardIndex] || touched}
        previousReviews={previousReviews}
        card={card as SkillCardInterface}
        onSetResultLoading={() => {}}
        showErrorLabel={missingValues}
        hideCountAndPrefill
        showExceeding
      />
    )
  },
)
