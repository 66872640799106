import React, { useEffect, useRef } from 'react'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  getRoundedRating,
  GradeOption,
  kpiRating,
  deliverablesRating,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'

export const ProbationDeliverablesCard = connect(
  ({ selectedField, setSelectedField, onHelpClick }: CommonCardProps) => {
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const hasKPI = !!values.review_data.kpis_section
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (
        !values.review_data.kpis_section?.recommended_rating &&
        values.review_data.kpis_section?.rating
      ) {
        set(
          values,
          'review_data.kpis_section.recommended_rating',
          getRoundedRating(values.review_data.kpis_section?.rating),
        )
      }
    }, [values.review_data.kpis_section])

    useEffect(() => {
      if (
        errors.review_data?.calculated_deliverables_ratings ||
        errors.review_data?.kpis_section
      ) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [
      errors.review_data?.calculated_deliverables_ratings,
      errors.review_data?.kpis_section,
    ])

    return (
      <>
        {hasKPI ? (
          <Card
            data={values}
            type={CardContentTypes.KPI}
            title="Goals and deliverables"
            icon="Target"
            fields={[
              {
                field: 'review_data.kpis_section',
                title: 'Contribution and impact',
                grades: kpiRating as GradeOption[],
              },
            ]}
            onSelectGrade={grade => {
              set(values, `review_data.kpis_section.recommended_rating`, grade.key)
            }}
            isGradeSelectedRule={(field, grade) => {
              const ratingValue = get(values, field)?.recommended_rating
              return !!ratingValue && ratingValue === grade.key
            }}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
            headerRef={ref}
            onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
          />
        ) : (
          <Card
            data={values}
            type={CardContentTypes.DELIVERABLES}
            title="Goals and deliverables"
            icon="Target"
            fields={[
              {
                field: 'review_data.calculated_deliverables_ratings',
                title: 'Contribution and impact',
                grades: deliverablesRating as GradeOption[],
              },
            ]}
            onSelectGrade={grade => {
              set(
                values,
                `review_data.calculated_deliverables_ratings.recommended_rating`,
                grade.key,
              )
            }}
            isGradeSelectedRule={(field, grade) => {
              const ratingValue = get(values, field)?.recommended_rating
              return !!ratingValue && ratingValue === grade.key
            }}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
            headerRef={ref}
            onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
          />
        )}
      </>
    )
  },
)
