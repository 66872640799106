import {
  ActionButton,
  Button,
  InputGroup,
  Popup,
  VStack,
  Text,
  Token,
  StatusWidget,
  Flex,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  GoalDashboardDetails,
  GoalUpdateType,
  GoalsInterface,
} from '@src/interfaces/goals'
import React, { useEffect, useState } from 'react'
import { CellWithItem } from '../common/CellWithItem'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { TargetsWidget } from './Widgets/Targets/TargetsWidgetNew'
import { RoadmapsWidget } from './Widgets/Roadmaps/RoadmapsWidget'
import { DashboardsWidget } from './Widgets/Dashboards/DashboardsWidget'
import { OrgUnitSelector } from './OrgUnitSelector'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { ParentGoalField } from '../common/ParentGoalField'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { Id } from '@src/interfaces'
import { LinkRoadmapsForm } from './SidebarForms/LinkRoadmapsForm'
import { UnassignedRoadmapInterface } from '@src/interfaces/roadmaps'
import { addGoalRoadmap } from '@src/api/roadmaps'
import { captureException } from '@sentry/core'
import { useSubmitFlowHelpers } from '../common/utils'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  createRelatedCompany,
  createRelatedDepartments,
  createRelatedEmployees,
  createRelatedTeams,
  getAllLookerDashboards,
  updateDashboard,
} from '@src/api/analyticsDashboards'
import SideBar from '@src/components/SideBar/SideBar'
import { useGoalRoadmapsTable } from '@src/features/Goals/common/useGoalRoadmapsTable'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import styled from 'styled-components'

type RightSideContent =
  | {
      type: 'target'
      item: Partial<KpiInterface>
    }
  | {
      type: 'roadmap'
    }
  | {
      type: 'dashboard'
      item?: GoalDashboardDetails
    }
  | null

export const GoalForm = ({
  enforceParentKpi,
  defaultReviewCycle,
}: {
  enforceParentKpi: boolean
  defaultReviewCycle?: ReviewCyclesInterface
}) => {
  const { values } = useLapeContext<GoalsInterface>()
  const [parentKpiRequired, setParentKpiRequired] = useState(enforceParentKpi)

  const roadmapsTable = useGoalRoadmapsTable()

  useEffect(() => {
    // this is the simplest way to refetch roadmaps when target removed
    roadmapsTable.refresh()
  }, [values.kpis.length])

  const [rightSide, setRightSide] = useState<RightSideContent>(null)

  useEffect(() => {
    if (values.update_type?.id === 'cascaded') {
      setParentKpiRequired(true)
    } else if (parentKpiRequired !== enforceParentKpi) {
      setParentKpiRequired(enforceParentKpi)
    }
  }, [values.update_type?.id])

  const syncUpdateType = (type?: UpdateTypes) => {
    const updateForm = (goalType: GoalUpdateType) => {
      if (values.update_type?.id !== goalType) {
        values.update_type = { id: goalType, name: goalType }
      }
    }
    switch (type) {
      case UpdateTypes.cascaded:
        updateForm('cascaded')
        break
      case UpdateTypes.aggregated:
        updateForm('aggregated')
        break
      default:
        updateForm('target_based')
    }
  }

  return (
    <VStack space="s-24">
      <CellWithItem
        icon="Target"
        title="Define your goal"
        description="What is the outcome you want to achieve?"
      >
        <InputGroup data-testid="goal-form-general">
          <LapeNewInput name="name" label="Goal name" required />
          <LapeRadioSelectInput
            selector={selectorKeys.employee}
            name="owner"
            label="Owner"
            required
          />
          <OrgUnitSelector />
          {values.is_company ? null : (
            <ParentGoalField
              contentType={values.content_type?.model}
              required={parentKpiRequired}
              value={values.parent}
              onChange={parent => {
                values.parent = parent
              }}
            />
          )}
          <LapeNewTextArea name="description" label="Description" required rows={1} />
        </InputGroup>
      </CellWithItem>
      <TargetsWidget
        reviewCycle={defaultReviewCycle}
        onTypeChanged={syncUpdateType}
        onAfterModified={result => {
          if (result.target_epics?.length) {
            roadmapsTable.refresh()
          }
        }}
      />
      <RoadmapsWidget onAdded={() => setRightSide({ type: 'roadmap' })} />
      <DashboardsWidget
        onAdd={() => setRightSide({ type: 'dashboard' })}
        onSelected={dash => setRightSide({ type: 'dashboard', item: dash })}
      />
      {rightSide?.type === 'roadmap' ? (
        <AddRoadmapsSide
          reviewCycle={defaultReviewCycle}
          onAferAdded={() => {
            setRightSide(null)
            roadmapsTable.refresh()
          }}
          onClose={() => setRightSide(null)}
        />
      ) : rightSide?.type === 'dashboard' ? (
        <AddDashboardSide
          dashboard={rightSide.item}
          onAfterAdded={({
            id,
            name,
            content_url,
            embed_url,
          }: AnalyticsDashboardInterface) => {
            setRightSide(null)
            values.dashboards.push({ id, name, content_url, embed_url })
          }}
          onClose={() => setRightSide(null)}
        />
      ) : null}
    </VStack>
  )
}

function AddRoadmapsSide({
  reviewCycle,
  onAferAdded,
  onClose,
}: {
  reviewCycle?: ReviewCyclesInterface
  onAferAdded: () => void
  onClose: () => void
}) {
  const { values } = useLapeContext<GoalsInterface>()
  const { showError } = useSubmitFlowHelpers()
  const [pendingSubmit, setPendingSubmit] = useState(false)

  const submit = async (
    cycle: ReviewCyclesInterface,
    epics: UnassignedRoadmapInterface[],
  ): Promise<void> => {
    try {
      setPendingSubmit(true)
      if (values.is_company) {
        await addGoalRoadmap({
          keys: epics.map(epic => epic.key),
          review_cycle: cycle,
          goal: { id: values.id },
          is_company: true,
        })
      } else {
        const entityKey: 'employee' | 'team' | 'department' =
          values.content_type?.model === 'employees'
            ? 'employee'
            : values.content_type?.model === 'teams'
            ? 'team'
            : 'department'

        await addGoalRoadmap({
          keys: epics.map(epic => epic.key),
          review_cycle: cycle,
          goal: { id: values.id },
          [entityKey]: { id: values.object_id },
        })
      }
    } catch (err) {
      captureException(err)
      showError('Failed to add roadmap', 'Please, try again.')
    } finally {
      setPendingSubmit(false)
    }
  }

  return (
    <SideBar useLayout onClose={onClose} title="Add roadmap" data-testid="roadmaps-form">
      <LinkRoadmapsForm
        pending={pendingSubmit}
        initialCycle={reviewCycle}
        ownerId={values.owner.id}
        submit={async ({ cycle, epics }) => {
          await submit(cycle, epics)
          onAferAdded()
        }}
      />
    </SideBar>
  )
}

function AddDashboardSide({
  onAfterAdded,
  dashboard,
  onClose,
}: {
  onAfterAdded: (dashboard: AnalyticsDashboardInterface) => void
  dashboard?: GoalDashboardDetails
  onClose: () => void
}) {
  const { values } = useLapeContext<GoalsInterface>()
  const { showError, showSuccess } = useSubmitFlowHelpers()
  const [pendingSubmit, setPendingSubmit] = useState(false)
  const { navigateWithEntity, entity } = useOrgEntity()
  const [selected, setSelected] = useState<AnalyticsDashboardInterface | undefined>(
    dashboard as AnalyticsDashboardInterface | undefined,
  )

  const submit = async (dash: AnalyticsDashboardInterface): Promise<void> => {
    const { id, related_goals } = dash
    const payload: Id & Partial<AnalyticsDashboardInterface> = {
      id,
      related_goals: [...(related_goals || []), { id: values.id }],
    }

    const afterSubmit = (result: Id) => {
      try {
        switch (entity?.type) {
          case EntityTypes.employee:
          case EntityTypes.employees:
            createRelatedEmployees(result.id, entity.data.id)
            break

          case EntityTypes.team:
          case EntityTypes.teams:
            createRelatedTeams(result.id, entity.data.id)
            break

          case EntityTypes.department:
            createRelatedDepartments(result.id, entity.data.id)
            break

          case EntityTypes.company:
            createRelatedCompany(result.id)
            break
        }
      } catch (err) {
        captureException(err)
      }
    }

    try {
      setPendingSubmit(true)
      await updateDashboard(payload)
      showSuccess('Dashboard added.')
      afterSubmit(payload)
      onAfterAdded(dash)
    } catch (err) {
      captureException(err)
      showError('Failed to link dashboard', 'Please, try again.')
    } finally {
      setPendingSubmit(false)
    }
  }

  const dashboardFormUrl = pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS, {})
  const isNotIncluded = ({ id }: Id) => {
    return !values.dashboards.find(d => d.id === id)
  }

  return (
    <Popup
      size="lg"
      onClose={onClose}
      data-testid="dashboard-form"
      open
      shouldKeepMaxHeight
    >
      <VStack gap="s-16" height={dashboard ? '100%' : 'calc(100% - 100px)'}>
        {dashboard ? null : (
          <ActionButton
            useIcon="ArrowThinRight"
            onClick={() =>
              navigateWithEntity(dashboardFormUrl, {
                goalId: values.id,
                entityType: entity?.type,
                entityId: entity?.data.id,
                entityName: entity?.data.name,
              })
            }
          >
            Create new dashboard
          </ActionButton>
        )}
        {dashboard ? null : (
          <RadioSelectInput<AnalyticsDashboardInterface>
            label="Select dashboard"
            value={selected}
            onChange={d => setSelected(d || undefined)}
            selector={() =>
              getAllLookerDashboards().then(response =>
                response.data.results.filter(isNotIncluded),
              )
            }
          >
            {option => (
              <VStack m="-s-2">
                <Text variant="primary">{option.label}</Text>
                <Text variant="caption" color={Token.color.greyTone50}>
                  {option.value.description}
                </Text>
              </VStack>
            )}
          </RadioSelectInput>
        )}
        <DashboardPreview dashboard={selected} />
      </VStack>
      {dashboard ? null : (
        <Popup.Actions>
          <Button
            pending={pendingSubmit}
            disabled={!selected}
            onClick={() => submit(selected!)}
          >
            Add dashboard
          </Button>
        </Popup.Actions>
      )}
    </Popup>
  )
}

const StretchedStatusWidget = styled(StatusWidget)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`

export function DashboardPreview({
  dashboard,
  minHeight = 'auto',
}: {
  dashboard?: GoalDashboardDetails
  minHeight?: number | 'auto'
}) {
  return (
    <Flex
      data-testid="test"
      border={`1px solid ${Token.color.greyTone20}`}
      borderRadius={Token.radius.r16}
      flex={1}
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      {dashboard && dashboard.embed_url ? (
        <iframe
          data-testid="looker-iframe"
          height="100%"
          src={dashboard.embed_url}
          style={{
            border: 0,
            borderRadius: '16px',
            minHeight,
          }}
          title="Dashboard"
          width="100%"
        />
      ) : !dashboard ? (
        <StretchedStatusWidget>
          <StatusWidget.Description>
            Select an internal dashboard
          </StatusWidget.Description>
        </StretchedStatusWidget>
      ) : (
        <StretchedStatusWidget>
          <StatusWidget.Description>Preview is not available</StatusWidget.Description>
        </StretchedStatusWidget>
      )}
    </Flex>
  )
}
