import React, { useEffect, useState } from 'react'
import { Box, VStack } from '@revolut/ui-kit'
import {
  ReviewCategory,
  ReviewDataInterface,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getCleanValuesBeforeSave } from '@src/utils/performance'
import { postPerformanceReviewForSummary } from '@src/api/performanceReview'
import SidebarSkeleton from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SidebarSkeleton'
import { BarRaiserSummary } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserSummary'
import { SummaryFeedbackSection } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryFeedbackSection'
import { EmployeeInterface } from '@src/interfaces/employees'
import { SummaryReviewTables } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryReviewTables'

export const CurrentReview = ({ employee }: { employee?: EmployeeInterface }) => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const [summaryData, setSummaryData] = useState<ReviewDataInterface>()

  const fetchSummary = async () => {
    let cleanValues = values

    if (values.category === ReviewCategory.Performance) {
      cleanValues = getCleanValuesBeforeSave(values)
    }

    const result = await postPerformanceReviewForSummary(cleanValues, values.category)
    setSummaryData(result.data)
  }

  useEffect(() => {
    fetchSummary()
  }, [])

  return (
    <VStack space="s-16" mt="s-8">
      <Box>
        {summaryData ? (
          <VStack space="s-32">
            <SummaryReviewTables reviewData={summaryData} />
            <BarRaiserSummary data={summaryData} />
            {employee && <SummaryFeedbackSection employee={employee} />}
          </VStack>
        ) : (
          <SidebarSkeleton />
        )}
      </Box>
    </VStack>
  )
}
