import React, { ReactNode, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { Id, IdAndName } from '@src/interfaces'
import { CellWithItem } from '../../../common/CellWithItem'
import AddGoalTargetForm from '../../SidebarForms/AddGoalTargetForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import {
  Avatar,
  Box,
  Caption,
  ErrorWidget,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  TextButton,
  TextSkeleton,
  Token,
  VStack,
  VisuallyHidden,
} from '@revolut/ui-kit'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { captureException } from '@sentry/react'
import { useFormObserver } from '../FormObserverProvider'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useGetGoal } from '@src/api/goals'
import { TargetCell } from './TargetCell'
import { BorderedCell } from './PersistentExpandableCell'
import { InfoIconWithTooltip } from '@src/components/Icon/InfoIconWithTooltip'
import { checkFormErrors } from './helpers'

interface TargetsWidgetProps {
  reviewCycle?: ReviewCyclesInterface
  onTypeChanged: (type?: UpdateTypes) => void
  onAfterModified: (kpi: KpiInterface) => void
}

export const TargetsWidget = ({
  reviewCycle,
  onTypeChanged,
  onAfterModified,
}: TargetsWidgetProps) => {
  const { values } = useLapeContext<GoalsInterface>()
  const [newFormIdx, setNewFormIdx] = useState(1)
  const { getFormById, getFormValidator } = useFormObserver()
  const [pendingSubmit, setPendingSubmit] = useState(false)

  const form = getFormById(undefined)

  const { getEntityProps } = useOrgEntity()
  const emptyTarget = {
    ...getEntityProps(),
    owner: values.owner as EmployeeOptionInterface & { team: IdAndName },
    goal: { id: values.id },
    update_type:
      values.update_type?.id === 'cascaded'
        ? UpdateTypes.cascaded
        : values.update_type?.id === 'aggregated'
        ? UpdateTypes.aggregated
        : UpdateTypes.manual,
    targets: [{ review_cycle: reviewCycle }],
  } as unknown as GoalKpiDetails

  const isCascaded = values.update_type?.id === 'cascaded'

  const editTitle = isCascaded ? 'Parent targets' : 'Add targets'
  const [expandedIndex, setExpandedIndex] = useState<number>()
  const [parentExpandedIndex, setParentExpandedIndex] = useState<number>()

  const onAddClicked = async () => {
    if (expandedIndex !== undefined) {
      setExpandedIndex(undefined)
      return
    }
    if (!form) {
      captureException(`Create goal target is broken for ${values.id}`)
      return
    }
    const validator = getFormValidator(form)
    const submit = validator?.validate ? validator.validate(form.submit) : form.submit

    setPendingSubmit(true)
    await submit()
    setPendingSubmit(false)

    if (!form.submitFailed) {
      // @ts-expect-error
      values.kpis = [...values.kpis, form.values]
      onAfterModified(form.values)
      setNewFormIdx(newFormIdx + 1)
    }
  }

  if (!reviewCycle) {
    // todo: make a proper loading state
    return null
  }

  const isAddAllowed = () => {
    switch (values.update_type?.id) {
      case 'target_based':
        return expandedIndex === undefined && (!form || !form.dirty)
      case 'aggregated':
      case 'cascaded':
        return true
      default:
        return false
    }
  }

  const renderCards = (cards: GoalKpiDetails[]) => {
    return cards.map((target, index) => {
      return (
        <TargetCell
          allowDelete
          target={target}
          index={index + 1}
          key={target.id}
          expanded={expandedIndex === index}
          onToggle={() =>
            expandedIndex === index
              ? setExpandedIndex(undefined)
              : setExpandedIndex(index)
          }
        >
          <AddGoalTargetForm
            onTypeChanged={onTypeChanged}
            hideAggregated={
              !!values.kpis.length || values.content_type?.model === 'employees'
            }
            hideCascaded={!!values.kpis?.length || values.is_company}
            initialValues={target}
          />
        </TargetCell>
      )
    })
  }

  const hasFormErrors = checkFormErrors(form)

  return (
    <CellWithItem
      icon="TurboTransfer"
      title={editTitle}
      description="What are the success criteria and how is progress measured?"
    >
      {renderCards(values.kpis)}
      <BorderedCell>
        <VStack width="100%" space="s-12" mb="s-16">
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Caption color={Token.color.greyTone50}>New target</Caption>
            <HStack space="s-12" align="center">
              {hasFormErrors && (
                <InfoIconWithTooltip
                  content="This target is invalid"
                  color={Token.color.red}
                />
              )}
              {expandedIndex !== undefined ? (
                <IconButton
                  color={Token.color.greyTone20}
                  useIcon={expandedIndex === undefined ? 'ChevronUp' : 'ChevronDown'}
                  onClick={() => setExpandedIndex(undefined)}
                />
              ) : null}
            </HStack>
          </Flex>
          <Box style={{ display: expandedIndex === undefined ? undefined : 'none' }}>
            <AddGoalTargetForm
              key={newFormIdx}
              onTypeChanged={onTypeChanged}
              hideAggregated={
                !!values.kpis.length || values.content_type?.model === 'employees'
              }
              hideCascaded={!!values.kpis?.length || values.is_company}
              initialCycle={reviewCycle}
              initialValues={emptyTarget}
            />
            <VisuallyHidden>
              <LapeNewInput name="targets" />
            </VisuallyHidden>
            {values.update_type?.id === 'cascaded' && (
              <ParentTargetsWidget
                parent={values.parent}
                renderTarget={(target, index) => (
                  <TargetCell
                    allowDelete={false}
                    key={target.id}
                    target={target}
                    index={index + 1}
                    expanded={parentExpandedIndex === index}
                    onToggle={() =>
                      parentExpandedIndex === index
                        ? setParentExpandedIndex(undefined)
                        : setParentExpandedIndex(index)
                    }
                  >
                    <AddGoalTargetForm
                      onTypeChanged={onTypeChanged}
                      hideAggregated={
                        !!values.kpis.length || values.content_type?.model === 'employees'
                      }
                      hideCascaded={!!values.kpis?.length || values.is_company}
                      initialValues={target}
                    />
                  </TargetCell>
                )}
              />
            )}
            {values.update_type?.id === 'aggregated' && (
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                p="s-24"
              >
                <Icon name="TurboTransfer" color={Token.color.greyTone20} />
                <ErrorWidget.Description
                  color={Token.color.greyTone20}
                  maxWidth={300}
                  textAlign="center"
                >
                  Progress will track the total from all child goals linked to this one
                </ErrorWidget.Description>
              </Flex>
            )}
          </Box>
        </VStack>
      </BorderedCell>

      {values.update_type?.id === 'target_based' ? (
        pendingSubmit ? (
          <TextSkeleton height={32} width={125} />
        ) : (
          <TextButton onClick={onAddClicked} disabled={isAddAllowed()}>
            <HStack align="center" space="s-12">
              <Avatar useIcon="16/Plus" size={32} />
              <Text>Add a target</Text>
            </HStack>
          </TextButton>
        )
      ) : null}
    </CellWithItem>
  )
}

function ParentTargetsWidget({
  parent,
  renderTarget,
}: {
  parent?: (Id & Partial<GoalsInterface>) | null
  renderTarget: (target: GoalKpiDetails, index: number) => ReactNode
}) {
  const { data, isLoading } = useGetGoal(parent?.id)

  if (isLoading) {
    // todo: add a proper loading state
    return null
  }

  return data?.kpis.length ? (
    <>
      <Caption color={Token.color.greyTone50}>Filled from parent goal</Caption>
      {data?.kpis.map(renderTarget)}
    </>
  ) : (
    <Flex justifyContent="center" alignItems="center" flexDirection="column" p="s-24">
      <Icon name="TurboTransfer" color={Token.color.greyTone20} />
      <ErrorWidget.Description color={Token.color.greyTone20}>
        {parent ? 'Set parent goal targets' : 'Select a parent goal to preview targets'}
      </ErrorWidget.Description>
    </Flex>
  )
}
