import React, { useEffect, useState } from 'react'
import { Button, Side, VStack } from '@revolut/ui-kit'

import SideBar from '@components/SideBar/SideBar'
import { IdAndName } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'
import NewMultiSelect from '@components/Inputs/NewMultiSelect/NewMultiSelect'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (selectedTemplates: IdAndName[]) => void
  initTemplates: IdAndName[]
}
export const SelectTemplatesSidebar = ({
  isOpen,
  onClose,
  onSubmit,
  initTemplates,
}: Props) => {
  const getTemplates = (initValue: IdAndName[]) =>
    initValue.map(template => ({
      key: template.id,
      label: template.name,
      value: template,
    }))

  const [selectedTemplates, setSelectedTemplates] = useState<
    RadioSelectOption<IdAndName>[]
  >(getTemplates(initTemplates))

  useEffect(() => {
    setSelectedTemplates(getTemplates(initTemplates))
  }, [initTemplates])

  const templatesValue = selectedTemplates.length
    ? selectedTemplates
    : initTemplates.map(template => ({
        key: template.id,
        label: template.name,
        value: template,
      }))
  const templatesToSubmit = selectedTemplates.length
    ? selectedTemplates.map(({ value }) => value)
    : initTemplates

  return (
    <SideBar
      variant="wide"
      title="Select document templates"
      isOpen={isOpen}
      onClose={onClose}
    >
      <VStack>
        <NewMultiSelect
          placeholder="Templates to add"
          selector={selectorKeys.document_templates}
          value={templatesValue}
          onChange={(newValue: RadioSelectOption<IdAndName>[]) => {
            setSelectedTemplates(newValue)
          }}
        />
      </VStack>
      <Side.Actions>
        <Button
          elevated
          onClick={() => {
            onSubmit(templatesToSubmit)
            setSelectedTemplates([])
            onClose()
          }}
        >
          Submit selected
        </Button>
      </Side.Actions>
    </SideBar>
  )
}
