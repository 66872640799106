import React, { useMemo, useState } from 'react'
import { refreshRoadmap } from '@src/api/roadmaps'
import { EntityTypes } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { Flex, TextSkeleton } from '@revolut/ui-kit'
import { TabBar } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useGetReviewCycle } from '@src/api/reviewCycles'
import { PermissionTypes } from '@src/store/auth/types'
import { useGetRoadmapSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { RoadmapTab } from '@src/features/Roadmaps/RoadmapTab'
import { commonRoadmapSorting } from '@src/features/Roadmaps/table'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { foreignGoalColumn } from '@src/constants/columns/goals'
import {
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapJiraLabelColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapStartDate,
  roadmapStatusColumn,
  getDeleteRoadmapColumn,
} from '@src/constants/columns/roadmap'
import { RowInterface } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

export const readOnlyRows: RowInterface<RoadmapInterface> = {
  cells: [
    {
      ...roadmapGenericNameColumn,
    },
    {
      ...foreignGoalColumn,
      width: 100,
    },
    {
      ...roadmapPriorityColumn,
      width: 100,
    },
    {
      ...roadmapProgressColumn,
      width: 170,
    },
    {
      ...roadmapStatusColumn,
      width: 120,
    },
    {
      ...roadmapStartDate,
      width: 130,
    },
    {
      ...roadmapDueDate,
      width: 130,
    },
    {
      ...roadmapJiraLabelColumn,
      width: 130,
    },
    {
      ...roadmapEpicUrlColumn,
      width: 120,
    },
  ],
}

export const getEditableRows = (
  handleDelete: (id: number | string) => Promise<void>,
): RowInterface<RoadmapInterface> => {
  return {
    cells: [
      ...readOnlyRows.cells,
      {
        ...getDeleteRoadmapColumn({ handleDelete }),
        width: 100,
      },
    ],
  }
}

interface RoadmapProps {
  data: EmployeeInterface
  cycle: ReviewCyclesInterface
}

export const getRoadmapFilters = (
  data: EmployeeInterface,
  type: RoadmapType,
  cycle: ReviewCyclesInterface,
) => [
  {
    filters: [{ name: data.id.toString(), id: data.id }],
    columnName: type === 'personal' ? 'employee__id' : 'owner__id',
    nonResettable: true,
  },
  {
    filters: [{ id: String(cycle.id), name: cycle.name }],
    columnName: 'review_cycle__id',
    nonResettable: true,
  },
]

type RoadmapType = 'personal' | 'teamAndDepartment'

export const EmployeeRoadmaps = ({ data, cycle }: RoadmapProps) => {
  const { query } = useQuery()
  const [tab, setTab] = useState<RoadmapType>('personal')
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const isNewLayout = useIsNewLayout()

  const cycleId = query.review_cycle__id || cycle.id
  const { data: reviewCycle } = useGetReviewCycle(String(cycleId))
  const triggerRefresh = async () => {
    await refreshRoadmap(`EMPLOYEE-${data.id}`, cycleId ? { id: +cycleId } : undefined)
  }
  const tableInfoMessage = useMemo<React.ReactNode>(() => {
    if (!roadmapSettings) {
      return <TextSkeleton />
    }
    if (tab === 'teamAndDepartment') {
      return roadmapSettings.jira_epics_enabled
        ? 'The Jira items shown above are team or department roadmap items where the employee is the assignee.'
        : 'The roadmaps shown above are team or department items where the employee is the assignee.'
    }

    return roadmapSettings.jira_epics_enabled
      ? 'Use the "add roadmap" button above to add epics that you want to display here. Epics labelled in Jira will also be shown.'
      : 'Use the button above to add roadmap items you want to track for this cycle.'
  }, [roadmapSettings?.jira_epics_enabled, tab])

  const allowEdit =
    !!data.field_options.permissions?.includes(PermissionTypes.CanCreateRoadmaps) &&
    tab === 'personal'

  const navigation = (
    <Flex>
      <TabBar variant="segmented fit">
        <TabBar.Item
          onClick={() => setTab('personal')}
          aria-selected={tab === 'personal'}
        >
          Personal
        </TabBar.Item>
        <TabBar.Item
          onClick={() => setTab('teamAndDepartment')}
          aria-selected={tab === 'teamAndDepartment'}
        >
          {isNewLayout ? 'Relevant' : 'Team & Department'}
        </TabBar.Item>
      </TabBar>
    </Flex>
  )

  return (
    <>
      {!isNewLayout && navigation}

      <RoadmapTab
        allowRefresh={tab === 'personal'}
        row={readOnlyRows}
        tableProps={{ name: TableNames.EmployeeRoadmap }}
        key={tab}
        reviewCycle={reviewCycle}
        defaultFilters={getRoadmapFilters(data, tab, cycle)}
        defaultSorting={commonRoadmapSorting}
        triggerRefresh={triggerRefresh}
        roadmapSettings={roadmapSettings}
        allowEdit={allowEdit}
        addRoadmapProps={{
          entityType: EntityTypes.employee,
          id: data.id,
          backUrl: pathToUrl(ROUTES.FORMS.EMPLOYEE.ROADMAP, { id: data.id }),
          name: data.display_name,
        }}
        tableInfoMessage={tableInfoMessage}
        showTableInfo={tab === 'teamAndDepartment' || allowEdit}
        newLayoutNavigation={navigation}
      />
    </>
  )
}
