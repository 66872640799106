import React, { useMemo } from 'react'
import {
  ManagerRecommendationInterface,
  PipQuestionInterface,
  ReviewInterface,
  ReviewerRelation,
} from '@src/interfaces/performance'
import styled from 'styled-components'
import { MatrixCellInsertParams } from '@components/Table/MatrixTable'
import { CellTypes } from '@src/interfaces/data'
import {
  Box,
  Text,
  Link as UIKitLink,
  Flex,
  Ellipsis,
  chain,
  createChain,
} from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'
import RecommendationGrade from '@src/pages/Forms/EmployeePerformance/components/RecommendationGrade'
import { ScorecardRecommendationAlertBanner } from '@components/ScorecardRecommendation/ScorecardRecommendationBanners'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Link, useLocation } from 'react-router-dom'
import RecommendationTooltip from '@src/pages/EmployeeProfile/Preview/Performance/Common/RecommendationTooltip'
import AdjustableMatrixTable from '@components/Table/AdjustableMatrixTable'
import KeeperGradeLabel from '@components/KeeperGradeLabel/KeeperGradeLabel'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { Warning } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/Cards'
import SummaryCommentsPopup from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummaryCommentsPopup'
import { relationToString } from '@src/features/Scorecard/constants'
import pluralize from 'pluralize'

const TooltipStyled = styled(Tooltip)`
  justify-content: flex-start;
`

type RowData = {
  name?: string
  fmValue?: React.ReactNode
  lmValue?: React.ReactNode
  peersValue?: React.ReactNode
  pipValue?: React.ReactNode
}

type Props = {
  lmData?: ManagerRecommendationInterface
  fmData?: ManagerRecommendationInterface
  peersData?: ManagerRecommendationInterface[]
  pipData?: ManagerRecommendationInterface[]
  isViewMode?: boolean
  id?: string | number
  employeeId?: number
  hideBanner?: boolean
  onlyKeeper?: boolean
  type: RecommendationTypes | null
  wide?: boolean
}

type CardData = {
  data: PipQuestionInterface
  relation?: string
  reviewer?: EmployeeOptionInterface
}

interface PipCardsInterface {
  [x: string]: CardData[]
}

const RecommendationSummary = ({
  lmData,
  fmData,
  peersData,
  pipData,
  id,
  employeeId,
  isViewMode = true,
  hideBanner,
  onlyKeeper,
  type,
  wide = true,
}: Props) => {
  const location = useLocation()
  const user = useSelector(selectUser)
  const existingData = lmData || fmData || peersData?.[0] || pipData?.[0]
  const checkpointNum = existingData?.checkpoint_number

  const getLink = (hash: string) => {
    const viewPath =
      type === 'pip'
        ? ROUTES.FORMS.PIP_REVIEW_VIEW.SUMMARY
        : ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.RECOMMENDATION
    const path =
      type === 'pip'
        ? ROUTES.FORMS.PIP_REVIEW.SUMMARY
        : ROUTES.FORMS.EMPLOYEE_PERFORMANCE.RECOMMENDATION
    return {
      ...getLocationDescriptor(
        isViewMode
          ? pathToUrl(viewPath, { id, employeeId })
          : pathToUrl(path, { id, employeeId }),
      ),
      hash: `#${hash}`,
      search: location.search,
    }
  }

  const peerDataPerformance = peersData?.map(item => ({
    value: item.employee_project_performance.value,
    reviewer: (item.reviewer || user).display_name || '',
  }))

  const getPeerDataKeeperGrades = (idx: number) =>
    peersData?.map(item => ({
      value: item.keeper_test_section.questions[idx]?.value,
      reviewer: (item.reviewer || user).display_name || '',
    }))

  const rows: RowData[] = onlyKeeper
    ? []
    : [
        {
          name: existingData?.employee_project_performance.name,
          lmValue: lmData?.employee_project_performance.value ? (
            <UIKitLink use={Link} to={getLink('a')}>
              <RecommendationGrade
                value={lmData?.employee_project_performance.value}
                textDecoration="underline"
              />
            </UIKitLink>
          ) : (
            <Text color="grey-tone-20">-</Text>
          ),
          fmValue: fmData?.employee_project_performance.value ? (
            <UIKitLink use={Link} to={getLink('a')}>
              <RecommendationGrade
                value={fmData?.employee_project_performance.value}
                textDecoration="underline"
              />
            </UIKitLink>
          ) : (
            <Text color="grey-tone-20">-</Text>
          ),
          peersValue: peerDataPerformance?.length ? (
            <Flex>
              <RecommendationTooltip grades={peerDataPerformance} placement="left">
                <UIKitLink
                  use={Link}
                  to={getLink('a')}
                  textDecoration="underline"
                  color="foreground"
                >
                  {chain('View', peerDataPerformance.length)}
                </UIKitLink>
              </RecommendationTooltip>
            </Flex>
          ) : (
            <Text color="grey-tone-20">-</Text>
          ),
        },
      ]

  existingData?.keeper_test_section?.questions.forEach((data, idx) => {
    const perfDataKeeperGrades = getPeerDataKeeperGrades(idx)
    rows.push({
      name: data.name,
      lmValue: lmData?.keeper_test_section?.questions[idx]?.value ? (
        <UIKitLink use={Link} to={getLink('b')}>
          <KeeperGradeLabel
            grade={lmData?.keeper_test_section?.questions[idx]?.value || undefined}
            textDecoration="underline"
            inverse
          />
        </UIKitLink>
      ) : (
        <Text color="grey-tone-20">-</Text>
      ),
      fmValue: fmData?.keeper_test_section?.questions[idx]?.value ? (
        <UIKitLink use={Link} to={getLink('b')}>
          <KeeperGradeLabel
            grade={fmData?.keeper_test_section?.questions[idx]?.value || undefined}
            textDecoration="underline"
            inverse
          />
        </UIKitLink>
      ) : (
        <Text color="grey-tone-20">-</Text>
      ),
      peersValue: perfDataKeeperGrades?.length ? (
        <Flex>
          <RecommendationTooltip grades={perfDataKeeperGrades} placement="left">
            <UIKitLink
              use={Link}
              to={getLink('b')}
              textDecoration="underline"
              color="foreground"
            >
              {chain('View', perfDataKeeperGrades.length)}
            </UIKitLink>
          </RecommendationTooltip>
        </Flex>
      ) : (
        <Text color="grey-tone-20">-</Text>
      ),
    })
  })

  const cards: PipCardsInterface = {}

  pipData?.forEach(item =>
    item?.pip_recommendation?.forEach(data => {
      if (data.title in cards) {
        cards[data.title].push({ data, relation: item.relation, reviewer: item.reviewer })
      } else {
        cards[data.title] = []
        cards[data.title].push({ data, relation: item.relation, reviewer: item.reviewer })
      }
    }),
  )

  for (const [key, value] of Object.entries(cards)) {
    const lm = value?.find(item => item.relation === 'line_manager')
    const fm = value?.find(item => item.relation === 'functional_manager')

    rows.push({
      name: key,
      lmValue: lm?.data.value ? (
        <UIKitLink use={Link} to={getLink('b')}>
          <KeeperGradeLabel
            grade={lm.data.value || undefined}
            inverse
            textDecoration="underline"
          />
        </UIKitLink>
      ) : (
        <Text color="grey-tone-20">-</Text>
      ),
      fmValue: fm?.data.value ? (
        <UIKitLink use={Link} to={getLink('b')}>
          <KeeperGradeLabel
            grade={fm.data.value || undefined}
            inverse
            textDecoration="underline"
          />
        </UIKitLink>
      ) : (
        <Text color="grey-tone-20">-</Text>
      ),
    })
  }

  const getViewTitle = (prefix: string) =>
    checkpointNum ? `${prefix} CP(${checkpointNum})` : prefix

  const cells = useMemo(() => {
    const result = [
      {
        type: CellTypes.insert,
        title: 'Questions',
        insert: ({ data: row }: MatrixCellInsertParams<RowData>) => (
          <Box overflow="hidden">
            <TooltipStyled placement="top" text={row.name}>
              <Ellipsis>{row.name}</Ellipsis>
            </TooltipStyled>
          </Box>
        ),
        width: isViewMode ? 200 : 390,
      },
    ]
    if (isViewMode || lmData) {
      result.push({
        type: CellTypes.insert,
        title: isViewMode ? getViewTitle('LM') : 'Answer',
        insert: ({ data: row }: MatrixCellInsertParams<RowData>) => <>{row.lmValue}</>,
        width: wide ? 115 : 90,
      })
    }
    if (isViewMode || fmData) {
      result.push({
        type: CellTypes.insert,
        title: isViewMode ? getViewTitle('FM') : 'Answer',
        insert: ({ data: row }: MatrixCellInsertParams<RowData>) => <>{row.fmValue}</>,
        width: wide ? 115 : 90,
      })
    }

    if ((!pipData && isViewMode) || peersData) {
      result.push({
        type: CellTypes.insert,
        title: 'Peers',
        insert: ({ data: row }: MatrixCellInsertParams<RowData>) => <>{row.peersValue}</>,
        width: 80,
      })
    }

    return result
  }, [])

  if (!existingData?.keeper_test_section && !existingData?.pip_recommendation) {
    return null
  }

  const getJustification = (
    recommendation: ManagerRecommendationInterface,
    relation: ReviewerRelation,
  ) => {
    if (
      !recommendation?.employee_project_performance.justification ||
      !recommendation?.reviewer
    ) {
      return null
    }

    return {
      value: recommendation.employee_project_performance.justification,
      review: {
        ...recommendation,
        reviewer_relation: relation,
      } as unknown as ReviewInterface,
    }
  }

  const fmJustification = fmData
    ? getJustification(fmData, ReviewerRelation.FunctionalManager)
    : null
  const lmJustification = lmData
    ? getJustification(lmData, ReviewerRelation.LineManager)
    : null

  const justifications = [lmJustification, fmJustification].filter(Boolean)

  const messageChain = createChain(' ')
  const relationString = (relation: ReviewerRelation) =>
    `(${relationToString(relation, true)})`

  return (
    <Box>
      {!hideBanner && (
        <Box mb="s-16">
          <ScorecardRecommendationAlertBanner />
        </Box>
      )}
      <AdjustableMatrixTable rows={{ cells }} data={rows} autoResize />
      {justifications.length ? (
        <Warning multiline>
          {messageChain(
            justifications[0].review.reviewer.display_name,
            relationString(justifications[0].review.reviewer_relation),
            justifications[1] && 'and',
            justifications[1] && justifications[1].review.reviewer.display_name,
            justifications[1] &&
              relationString(justifications[1].review.reviewer_relation),
            'added',
            pluralize('justification', justifications.length),
          )}
          <SummaryCommentsPopup
            data={justifications}
            title="Grade justification"
            subtitle=""
          />
        </Warning>
      ) : null}
    </Box>
  )
}

export default RecommendationSummary
