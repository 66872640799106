import {
  Caption,
  Cell,
  Flex,
  HStack,
  Icon,
  Text,
  Token,
  VStack,
  chain,
} from '@revolut/ui-kit'
import { GoalsTimelineEventInterface } from '@src/interfaces/goals'
import React, { useMemo } from 'react'
import { useTableReturnType } from '@src/components/Table/hooks'
import ButtonFilters, {
  ButtonFilterConfig,
} from '@src/components/ButtonFilters/ButtonFilters'
import { selectorKeys } from '@src/constants/api'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { formatDateTime } from '@src/utils/format'

const GoalTimelineEvent = ({ event }: { event: GoalsTimelineEventInterface }) => {
  const title = chain(
    <UserWithAvatar {...event.employee} />,
    <Text color={Token.color.greyTone50}>
      {formatDateTime(event.creation_date_time)}
    </Text>,
  )

  return (
    <Cell>
      <VStack space="s-8">
        <HStack align="center">{title}</HStack>

        <HStack align="center" space="s-12">
          <Icon size={16} name="InfoOutline" color={Token.color.greyTone50} />
          <Text variant="caption" color={Token.color.greyTone50}>
            {event.title}
          </Text>
        </HStack>
        {!!event.summary && <Text>{event.summary}</Text>}
      </VStack>
    </Cell>
  )
}

export const GoalTimeline = ({
  timeline,
}: {
  timeline: useTableReturnType<GoalsTimelineEventInterface>
}) => {
  const filtersConfig: ButtonFilterConfig = useMemo(
    () => ({
      creation_date_time: {
        title: 'Date',
        selector: selectorKeys.none,
        useIcon: 'Calendar',
        type: 'DateRange',
      },
      employee: {
        title: 'Employee',
        selector: selectorKeys.employee,
        useIcon: 'Profile',
        type: 'MultiSelect',
      },
      category: {
        title: 'Category',
        selector: selectorKeys.goal_timeline_categories,
        type: 'MultiSelect',
      },
    }),
    [],
  )
  return (
    <VStack space="s-16">
      <ButtonFilters
        filtersConfig={filtersConfig}
        onChange={timeline.onFilterChange}
        filters={timeline.filterBy}
      />
      <VStack space="s-12">
        {timeline.data.map(event => (
          <GoalTimelineEvent event={event} key={event.id} />
        ))}
        {timeline.data.length === 0 && (
          <Flex width="100%" justifyContent="center" minHeight={150} alignItems="center">
            <Caption color={Token.color.greyTone50}>No events so far</Caption>
          </Flex>
        )}
      </VStack>
    </VStack>
  )
}
