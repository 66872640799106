import React, { useEffect, useState } from 'react'
import { PerformanceRating, Ratings } from '@src/interfaces/performance'
import { connect } from 'lape'
import {
  Box,
  Cell,
  chain,
  Flex,
  InputGroup,
  TabBar,
  TableWidget,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import {
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiTargetColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { useTable } from '@components/Table/hooks'
import { AxiosResponse } from 'axios'
import { GetRequestInterface } from '@src/interfaces'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { KpiInterface } from '@src/interfaces/kpis'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import {
  roadmapGenericNameColumn,
  roadmapOwnerColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
} from '@src/constants/columns/roadmap'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { get, set } from 'lodash'
import { TableNames } from '@src/constants/table'
import KpiStatistic from '@src/pages/Forms/EmployeePerformance/components/KPIStatistic'
import {
  CommonSectionProps,
  getRoundedRating,
  kpiRating,
  PerformanceRatingToGraphNumber,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { DeliverablesGrade } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { relationToString } from '@src/features/Scorecard/constants'

const KPI_ROW: RowInterface<KpiInterface> = {
  cells: [
    {
      ...kpiGenericNameColumn,
      sortKey: null,
      filterKey: null,
      width: 150,
    },
    {
      ...kpiWeightColumn,
      sortKey: null,
      filterKey: null,
      width: 80,
    },
    {
      ...kpiPerformanceColumn,
      sortKey: null,
      filterKey: null,
      width: 80,
    },
    {
      ...kpiInitialValueColumn,
      width: 50,
    },
    {
      ...kpiCurrentValueColumn,
      width: 60,
    },
    {
      ...kpiTargetColumn,
      width: 50,
    },
  ],
}

export const ROADMAP_ROW: RowInterface<RoadmapInterface> = {
  cells: [
    {
      ...roadmapGenericNameColumn,
      notHoverable: false,
      width: 150,
    },
    {
      ...roadmapPriorityColumn,
      width: 100,
    },
    {
      ...roadmapProgressColumn,
      width: 120,
    },
    {
      ...roadmapOwnerColumn,
      width: 80,
    },
  ],
}

enum Tabs {
  KPI = 'KPI',
  Roadmap = 'Roadmap',
}

export const KPI = connect(({ reviewData, isViewMode = false }: CommonSectionProps) => {
  const [tab, setTab] = useState<Tabs>(Tabs.KPI)
  const ratingOptions = kpiRating

  const kpiSection = reviewData.kpis_section
  const roadmapSection = reviewData.roadmaps_section

  useEffect(() => {
    if (!kpiSection) {
      setTab(Tabs.Roadmap)
    }
  }, [kpiSection])

  const kpiTable = useTable<KpiInterface>({
    getItems: () =>
      new Promise(resolve => {
        resolve({
          data: {
            results: kpiSection?.kpi_items || [],
            count: kpiSection?.kpi_items.length,
          },
        } as AxiosResponse<GetRequestInterface<KpiInterface>>)
      }),
  })

  const roadmapTable = useTable<RoadmapInterface>({
    getItems: () =>
      new Promise(resolve => {
        resolve({
          data: {
            results: roadmapSection?.roadmap_items || [],
            count: roadmapSection?.roadmap_items.length,
          },
        } as AxiosResponse<GetRequestInterface<RoadmapInterface>>)
      }),
  })

  const onRowClick = (rowData: RoadmapInterface) => {
    if (rowData.epic_url) {
      window.open(rowData.epic_url, '_blank')
    }
  }

  if (!kpiSection && !roadmapSection) {
    return null
  }

  const getFieldDetails = (option: {
    key: string
    text: string
    description: string[]
  }) => {
    const ratingValue = get(reviewData, 'kpis_section.recommended_rating')
    const expectedValue = get(reviewData, 'kpis_section.rating_expectation')
    const isChecked =
      ratingOptions.find(item => item.key === ratingValue)?.key === option.key
    const exceedingExpectation =
      isChecked &&
      PerformanceRatingToGraphNumber[option.key as PerformanceRating] >
        PerformanceRatingToGraphNumber[expectedValue as PerformanceRating]

    return { isChecked, exceedingExpectation, ratings: [] }
  }

  const getViewFieldDetails = (option: {
    key: string
    text: string
    description: string[]
  }) => {
    const ratings =
      get(reviewData, 'kpis_section.recommended_ratings')?.filter((rating: Ratings) => {
        return getRoundedRating(rating.value) === option.key
      }) || []
    const expectedValue = get(reviewData, 'kpis_section.rating_expectation')
    const isChecked = !!ratings.length
    const exceedingExpectation =
      isChecked &&
      PerformanceRatingToGraphNumber[option.key as PerformanceRating] >
        PerformanceRatingToGraphNumber[expectedValue as PerformanceRating]

    return { isChecked, exceedingExpectation, ratings }
  }

  const renderStatisticWithTabBar = (statistic: JSX.Element) => {
    return (
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        {statistic}
        {kpiSection && roadmapSection && (
          <TabBar
            variant="segmented fit"
            defaultValue={kpiSection ? Tabs.KPI : Tabs.Roadmap}
            onChange={val => {
              if (val) {
                setTab(val as Tabs)
              }
            }}
            value={tab}
          >
            {!!kpiSection && <TabBar.Item to={Tabs.KPI}>KPIs</TabBar.Item>}
            {!!roadmapSection && <TabBar.Item to={Tabs.Roadmap}>Roadmaps</TabBar.Item>}
          </TabBar>
        )}
      </Flex>
    )
  }

  return (
    <Cell>
      <VStack space="s-16" width="100%">
        <Text variant="h4">Contribution and impact made towards deliverables</Text>
        {tab === Tabs.KPI && (
          <TableWidget style={{ padding: 0 }}>
            <TableWidget.Info>
              {renderStatisticWithTabBar(
                <KpiStatistic
                  performance={kpiSection?.performance}
                  rating={kpiSection?.rating}
                  label="KPI progress"
                />,
              )}
            </TableWidget.Info>
            <TableWidget.Table>
              <AdjustableTable<KpiInterface>
                name={TableNames.EmployeePerformanceKPI}
                dataType="KPI"
                row={KPI_ROW}
                {...kpiTable}
                noDataMessage="No KPI defined"
                onRowClick={onKPITableRowClick}
                noReset
                hideCountAndButtonSection
              />
            </TableWidget.Table>
          </TableWidget>
        )}
        {tab === Tabs.Roadmap && (
          <TableWidget style={{ padding: 0 }}>
            <TableWidget.Info>
              {renderStatisticWithTabBar(
                <KpiStatistic
                  performance={roadmapSection?.performance}
                  label="Roadmap progress"
                />,
              )}
            </TableWidget.Info>
            <TableWidget.Table>
              <AdjustableTable<RoadmapInterface>
                name={TableNames.EmployeePerformanceRoadmap}
                dataType="Roadmap"
                row={ROADMAP_ROW}
                {...roadmapTable}
                noDataMessage="No roadmap data"
                onRowClick={onRowClick}
                noReset
                hideCountAndButtonSection
              />
            </TableWidget.Table>
          </TableWidget>
        )}
        <Box mt="s-16">
          <InputGroup>
            {ratingOptions.map((option, optionIdx) => {
              const { isChecked, exceedingExpectation, ratings } = isViewMode
                ? getViewFieldDetails(option)
                : getFieldDetails(option)
              return (
                <DeliverablesGrade
                  key={optionIdx}
                  isViewMode={isViewMode}
                  isChecked={isChecked}
                  exceedingExpectation={exceedingExpectation}
                  ratings={ratings}
                  option={option}
                  onChange={() => {
                    set(reviewData, 'kpis_section.recommended_rating', option.key)
                  }}
                />
              )
            })}

            {isViewMode ? (
              <VStack space="s-8">
                {reviewData?.kpis_section?.comments?.map(comment => (
                  <Box
                    p="s-16"
                    key={comment.review.id}
                    border={`1px solid ${Token.color.greyTone10}`}
                    borderRadius={Token.radius.r16}
                  >
                    <VStack space="s-8">
                      <Text variant="primary">
                        {chain(
                          comment.review.reviewer.full_name,
                          relationToString(comment.review.reviewer_relation, true),
                        )}
                      </Text>
                      <Text variant="secondary">{comment.value}</Text>
                    </VStack>
                  </Box>
                ))}
              </VStack>
            ) : (
              <LapeNewTextArea
                rows={2}
                label="Justification / evidence or specific examples"
                name="review_data.kpis_section.comment"
                data-testid="comment"
              />
            )}
          </InputGroup>
        </Box>
      </VStack>
    </Cell>
  )
})
