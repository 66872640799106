import React, { useState } from 'react'
import {
  DetailsCell,
  VStack,
  Token,
  DataPoint,
  Flex,
  IconButton,
  useToggle,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalKpiDetails, GoalPreviewInterface } from '@src/interfaces/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { fetchGoalGraph, getGoalCommentsAPI, useGetGoal } from '@src/api/goals'
import {
  CycleFilter,
  CycleFilterType,
} from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { FilterByInterface } from '@src/interfaces/data'
import { OptionInterface } from '@src/interfaces/selectors'
import { FilterSelectType } from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { ParentTargetsWidget } from '../Widgets/Targets/ParentTargetsWidget'
import { RoadmapsWidget } from '../Widgets/Roadmaps/RoadmapsWidget'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { GoalTargetSidebarForm } from '@src/features/Goals/GoalTargetSidebarForm'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { PerformanceLayoutCycleFilter } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/PerformanceLayoutCycleFilter'
import { AggregatedGoalsWidget } from '../Widgets/Targets/AggregatedGoalsWidget'
import { useGoalRoadmapsTable } from '@src/features/Goals/common/useGoalRoadmapsTable'
import { GoalApprovalFlow } from './GoalApprovalFlow'
import { Statuses } from '@src/interfaces'
import { GoalStatusDropdown } from '@src/features/Goals/components/GoalStatusDropdown'
import { TargetsPreviewWidget } from './TargetsPreviewWidget'
import { AnalyticsPreviewWidget } from './AnalyticsPreviewWidget'
import { FormPreview } from '@src/components/FormPreview/FormPreview'

export const GoalsPreviewLeftSide = ({
  cycle,
  availableCycles,
  onCycleChanged,
}: {
  cycle: ReviewCyclesInterface
  availableCycles: ReviewCyclesInterface[]
  onCycleChanged: (cycleId: number | string) => void
}) => {
  const { values } = useLapeContext<GoalPreviewInterface>()
  const { data: parent } = useGetGoal(values.parent?.id)
  const [selectedTarget, setSelectedTarget] = useState<GoalKpiDetails>()
  const [generalInfoShown, toggleGeneralInfo] = useToggle({ defaultState: false })
  const roadmapsTable = useGoalRoadmapsTable()

  const onCycleChange = (filter: FilterByInterface) => {
    const cycleId = filter.filters[0].id
    onCycleChanged(cycleId)
  }
  const filter = [
    {
      columnName: 'review_cycle',
      filters: [{ id: cycle?.id, name: cycle?.name }],
    },
  ]

  const currentRoadmaps = values.roadmaps?.filter(
    roadmap => roadmap.review_cycle.id === cycle.id,
  )

  const goalCycles =
    (values.update_type?.id === 'cascaded' ? parent?.goal_cycles : values.goal_cycles) ||
    []

  const goalProgressDetails = goalCycles.find(
    cycleDetails => cycleDetails.review_cycle.id === cycle.id,
  )

  const progress =
    goalProgressDetails?.calibrated_progress ?? goalProgressDetails?.progress

  return (
    <VStack space="s-24">
      <DetailsCell>
        <DetailsCell.Title>
          <Flex width="100%" gap="s-24">
            {values.content_type?.model === 'employees' ? (
              <PerformanceLayoutCycleFilter
                onFilterChange={onCycleChange}
                columnName="review_cycle"
                filter={filter}
                selector={() =>
                  Promise.resolve({
                    options: availableCycles as unknown as OptionInterface[],
                  })
                }
              />
            ) : (
              <CycleFilter
                type={CycleFilterType.NewUI}
                onFilterChange={onCycleChange}
                columnName="review_cycle"
                filter={filter}
                selector={() =>
                  Promise.resolve({
                    options: availableCycles as unknown as OptionInterface[],
                  })
                }
                filterInputType={FilterSelectType.SingleSelect}
              />
            )}
            <OverallProgress value={progress} id={values.id} fetchData={fetchGoalGraph} />
            <DataPoint>
              <DataPoint.Value>
                <GoalStatusDropdown
                  goal={values}
                  onStatusChanged={status => {
                    values.status = status
                  }}
                />
              </DataPoint.Value>
              <DataPoint.Label>Status</DataPoint.Label>
            </DataPoint>
          </Flex>
        </DetailsCell.Title>
        <DetailsCell.Content alignSelf="center">
          <IconButton
            useIcon="ChevronDown"
            color={Token.color.greyTone50}
            onClick={() => toggleGeneralInfo(!generalInfoShown)}
          />
        </DetailsCell.Content>

        <DetailsCell.Note mx="-s-16">
          {generalInfoShown && (
            <FormPreview data={values}>
              {!!values.parent && (
                <FormPreview.Item
                  title="Parent"
                  field="parent.name"
                  href={() =>
                    pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, {
                      id: String(values.parent?.id),
                    })
                  }
                />
              )}
              {!!values.description && (
                <FormPreview.Details title="Description" field="description" />
              )}
            </FormPreview>
          )}
          {values.status.id !== Statuses.draft &&
            values.status.id !== Statuses.archived && (
              <GoalApprovalFlow
                id={values.id}
                approvalStatus={values.approval_status.id}
              />
            )}
        </DetailsCell.Note>
      </DetailsCell>
      {values.update_type?.id === 'aggregated' && <AggregatedGoalsWidget cycle={cycle} />}
      {values.update_type?.id === 'cascaded' && <ParentTargetsWidget />}
      {values.update_type?.id === 'target_based' && (
        <TargetsPreviewWidget onSelected={setSelectedTarget} />
      )}
      {currentRoadmaps && currentRoadmaps.length ? <RoadmapsWidget viewMode /> : null}
      {values.dashboards?.length ? <AnalyticsPreviewWidget /> : null}
      <GoalsCommentsSection goalId={values.id} />
      <GoalTargetSidebarForm
        target={selectedTarget}
        onClose={() => setSelectedTarget(undefined)}
        onAfterSubmit={data => {
          if (selectedTarget) {
            Object.assign(selectedTarget, data)
          }

          setSelectedTarget(undefined)
          roadmapsTable.refresh()
        }}
      />
    </VStack>
  )
}

function GoalsCommentsSection({ goalId }: { goalId: number }) {
  const api = getGoalCommentsAPI(goalId)

  return <CommentsSection api={api} />
}
