import { VStack, Text, Token, Flex, Box, VisuallyHidden } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import {
  EpicOption,
  KpiInterface,
  KpiReviewCycle,
  UpdateTypes,
} from '@src/interfaces/kpis'
import React, { useEffect, useMemo, useState } from 'react'
import {
  LapeFormInterface,
  useLapeContext,
  useLapeField,
} from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import { ReviewCycleCategory, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { JiraSearchWidget } from '@src/pages/OnboardingChecklist/Roadmaps/JiraSearch'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import { kpisRequestsNew } from '@src/api/kpis'
import InputsSkeleton from '@src/components/Skeletons/InputsSkeleton'
import Form from '@src/features/Form/Form'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { useFormObserver } from '../Widgets/FormObserverProvider'
import { TargetFormFields } from './TargetFormFields'
import toString from 'lodash/toString'
import { GoalTargetCycleSelector } from '../Widgets/Targets/GoalTargetCycleSelector'
import omit from 'lodash/omit'

export const TargetFieldsByType = ({
  type,
  cycle,
  variant = 'new',
}: {
  type?: UpdateTypes
  cycle?: ReviewCyclesInterface
  variant?: 'new' | 'side-edit'
}) => {
  const { values } = useLapeContext<KpiInterface>()
  const jiraEpicsField = useLapeField('target_epics.0.epics')

  const epics = useMemo(() => {
    return values.target_epics?.length
      ? values.target_epics[0].epics.reduce((acc, epicOption) => {
          if (epicOption.key) {
            return {
              ...acc,
              [epicOption.key]: {
                display_name: epicOption.name,
                epic_name: epicOption.name,
                epic_url: epicOption.url,
                id: epicOption.id,
                issue_type: 'Epic', // only epics could be selected
                key: epicOption.key,
              },
            }
          }
          return acc
        }, {})
      : undefined
  }, [])

  switch (type) {
    case UpdateTypes.roadmap:
      return (
        <>
          <VisuallyHidden>
            <LapeNewInput name="target_epics.0.epics" />
          </VisuallyHidden>
          <JiraSearchWidget
            message={jiraEpicsField?.error && toString(jiraEpicsField.error)}
            aria-invalid={!!jiraEpicsField?.error}
            forceSelected={epics}
            onSelectionChange={selected => {
              if (!values.target_epics) {
                values.target_epics = []
              }

              const target = values.target_epics[0] || {}
              target.epics = Object.values(selected).map(
                epic =>
                  ({
                    key: epic.key,
                    name: epic.epic_name,
                    owner: epic.owner,
                    url: epic.epic_url,
                    review_cycle: cycle as KpiReviewCycle,
                  } as EpicOption),
              )
              values.target_epics[0] = target
            }}
          />
        </>
      )

    case UpdateTypes.sql:
    case UpdateTypes.manual:
    case UpdateTypes.looker:
      return (
        <TargetFormFields
          variant={variant}
          hideLooker
          codeEditorProps={{
            responsive: true,
            width: '100%',
            height: 350,
            wrapResponsive: true,
          }}
        />
      )
    default:
      return null
  }
}

const AddGoalTargetForm = ({
  initialCycle,
  hideCascaded,
  hideAggregated,
  onTypeChanged,
}: {
  initialCycle?: ReviewCyclesInterface
  hideCascaded: boolean
  hideAggregated: boolean
  onTypeChanged: (type?: UpdateTypes) => void
}) => {
  const validator = useFormValidator()
  const form = useLapeContext<KpiInterface & { parent: GoalsInterface | null }>()
  const { values } = form
  const featureFlags = useSelector(selectFeatureFlags)
  const { registerForm, unregisterForm } = useFormObserver()

  useEffect(() => {
    registerForm(form, validator)

    return () => unregisterForm(values.id)
  }, [])

  const hasDeliverables = values.update_type === UpdateTypes.roadmap

  const target =
    (hasDeliverables
      ? values.target_epics && values.target_epics[0]
      : values.targets && values.targets[0]) || undefined

  const targetCycle = (target?.review_cycle ||
    target?.employee_cycle ||
    initialCycle) as ReviewCyclesInterface

  const [cycle, setCycle] = useState<ReviewCyclesInterface | undefined>(targetCycle)

  useEffect(() => {
    if (cycle && target && target.review_cycle?.id !== cycle?.id) {
      target.review_cycle = cycle
    }
  }, [cycle])

  useEffect(() => {
    if (!values.update_type) {
      values.update_type = UpdateTypes.manual
    }
  }, [])

  const updateTypesDictionary = {
    [UpdateTypes.manual]: {
      name: 'Manual',
      description: 'Manually update the current value',
      visible: true,
    },
    [UpdateTypes.sql]: {
      name: 'SQL',
      description: 'Use a SQL query to automatically update the current value',
      visible: true,
    },
    [UpdateTypes.roadmap]: {
      name: 'Roadmap',
      description: 'Track the total progress of linked roadmap items',
      visible: true,
    },
    [UpdateTypes.looker]: {
      name: 'Looker',
      description: 'Use a Look to automatically update the current value',
      visible: featureFlags.includes(FeatureFlags.Allowed_Looker),
    },
    [UpdateTypes.cascaded]: {
      name: 'Cascaded',
      description: 'Track the progress of the parent goal',
      visible: !hideCascaded,
    },
    [UpdateTypes.aggregated]: {
      name: 'Aggregated',
      description: 'Track the total progress of all child goals connected to this one',
      visible: !hideAggregated,
    },
  }

  const updateTypeOption = values.update_type
    ? { id: values.update_type, ...updateTypesDictionary[values.update_type] }
    : undefined

  // ToDo: move logic below in before submit hook

  // useEffect(() => {
  //   const isEpicBased = values.update_type === UpdateTypes.roadmap
  //   values.is_inherited = values.update_type === UpdateTypes.cascaded

  //   if (isEpicBased) {
  //     values.targets = []
  //   } else if (!values.targets?.length) {
  //     values.targets = [{ is_top_down: false } as KpiTargets]
  //   }

  //   if (values.update_type !== UpdateTypes.looker) {
  //     values.look_url = null
  //   }

  //   if (values.update_type !== UpdateTypes.sql) {
  //     values.sql_query = null
  //   }
  // }, [values.update_type])

  useEffect(() => {
    const opposites = {
      review_cycle: 'employee_cycle',
      employee_cycle: 'review_cycle',
    }
    if (cycle && target) {
      const cycleKey = [ReviewCycleCategory.Probation, ReviewCycleCategory.PIP].includes(
        cycle?.category,
      )
        ? 'employee_cycle'
        : 'review_cycle'
      const fieldToClear = opposites[cycleKey] as 'employee_cycle' | 'review_cycle'

      if (target[cycleKey]?.id !== cycle.id) {
        target[cycleKey] = cycle
        target[fieldToClear] = undefined
      }
    }
  }, [cycle, target])

  const showCycleAndName =
    !!values.update_type &&
    values.update_type !== UpdateTypes.cascaded &&
    values.update_type !== UpdateTypes.aggregated

  const detailField = useLapeField('detail')
  const cycleError =
    typeof detailField?.apiError === 'string' &&
    detailField?.apiError.includes('review cycle')
      ? detailField?.apiError
      : undefined

  return (
    <>
      <VStack space="s-16">
        {showCycleAndName && (
          <>
            <VisuallyHidden>
              <LapeNewInput name="detail" />
            </VisuallyHidden>
            <GoalTargetCycleSelector
              onSelect={selection => {
                detailField.cleanErrors()
                setCycle(selection)
              }}
              ownerId={values.owner?.id}
              isEmployee={values.is_employee}
              initialCycle={targetCycle}
              hasError={!!cycleError}
              message={cycleError}
            />
          </>
        )}
        {values.id ? null : (
          <LapeRadioSelectInput<IdAndName<UpdateTypes> & { description: string }>
            label="Target type"
            value={updateTypeOption}
            onChange={option => {
              values.update_type = option?.id
              onTypeChanged(option?.id)
            }}
            name="update_type"
            options={Object.entries(updateTypesDictionary)
              .filter(([_, { visible }]) => !!visible)
              .map(([id, value]) => ({
                value: { ...value, id: id as UpdateTypes },
                label: value.name,
              }))}
            searchable={false}
          >
            {option => (
              <VStack m="-s-2">
                <Text variant="primary">{option.label}</Text>
                <Text variant="caption" color={Token.color.greyTone50}>
                  {option.value.description}
                </Text>
              </VStack>
            )}
          </LapeRadioSelectInput>
        )}
        {showCycleAndName && (
          <>
            <LapeNewInput name="name" label="Target name" required />
          </>
        )}
        <TargetFieldsByType type={values.update_type} cycle={cycle} />
      </VStack>
    </>
  )
}

export type TargetFormInterface = LapeFormInterface<
  KpiInterface & { parent: GoalsInterface | null }
>

type AddGoalTargetFormProps = {
  initialValues: Partial<GoalKpiDetails>
  initialCycle?: ReviewCyclesInterface
  hideCascaded: boolean
  hideAggregated: boolean
  onTypeChanged: (type?: UpdateTypes) => void
}

export default ({
  initialValues,
  initialCycle,
  hideCascaded,
  hideAggregated,
  onTypeChanged,
}: AddGoalTargetFormProps) => {
  // todo: make loadingState dynamic based on initialValues.update_type and epics count
  const loadingState = (
    <Flex flexDirection="column" justifyContent="space-between" height="100%" gap="s-24">
      <VStack space="s-16" mt="s-16">
        <InputsSkeleton />
        <InputsSkeleton />
      </VStack>
    </Flex>
  )

  return (
    <Box>
      <Form<KpiInterface>
        api={kpisRequestsNew}
        forceParams={{ id: initialValues.id ? String(initialValues.id) : undefined }}
        initialValues={omit(initialValues, 'status')}
        disableLocalStorageCaching
        ignoreLocationState
        loadingState={loadingState}
      >
        <AddGoalTargetForm
          initialCycle={initialCycle}
          onTypeChanged={onTypeChanged}
          hideCascaded={hideCascaded}
          hideAggregated={hideAggregated}
        />
      </Form>
    </Box>
  )
}
