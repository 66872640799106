import React, { useEffect, useState } from 'react'
import {
  Group,
  Box,
  MoreBar,
  Popup,
  Button,
  chain,
  Text,
  Header,
  Token,
} from '@revolut/ui-kit'
import pluralize from 'pluralize'
import styled from 'styled-components'
import { timeOffPoliciesRequests } from '@src/api/timeOff'
import { PageBody } from '@src/components/Page/PageBody'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TimeOffPolicyInterface } from '@src/interfaces/timeOff'
import {
  archiveTimeOffPolicy,
  unarchiveTimeOffPolicy,
  useTimeOffPolicyProcessingStatus,
} from '@src/api/timeOff'
import { Statuses } from '@src/interfaces'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { getStatusColor } from '@src/components/CommonSC/General'
import { pathToUrl } from '@src/utils/router'
import SettingsButtons, { DeleteButton, EditButton } from '@src/features/SettingsButtons'
import { TimeOffPolicyCategory } from '@src/features/TimeOff/TimeOffPolicyCategory'
import { PoliciesUpdatingWarning } from './components/PoliciesUpdatingWarning'

const Ul = styled.ul`
  margin: 0;
  padding: 0 ${Token.space.s20};
`

const REFETCH_INTERVAL = 5000 * 60 // 5 minutes

const Preview = () => {
  const { values, initialValues, reset } = useLapeContext<TimeOffPolicyInterface>()
  const { data: processingStatus, refetch: refetchProcessingStatus } =
    useTimeOffPolicyProcessingStatus(values.id)

  const [changeStatus, setChangeStatus] = useState<
    'archive' | 'archive_with_assignments' | 'unarchive' | 'none'
  >('none')
  const [archiveWarningOpen, setArchiveWarningOpen] = useState(false)

  const isLimitedBalance = initialValues.balance_type?.id === 'limited'
  const isArchived = values.status?.id === Statuses.archived
  const isDraft = values.status?.id === Statuses.draft

  const canArchive =
    !isDraft &&
    values.field_options?.actions?.includes(EntityPermissions.Archive) &&
    values.field_options?.permissions?.includes(PermissionTypes.ArchiveTimeOffPolicy)

  const canEdit =
    ['active', 'draft'].includes(values.status.id) &&
    values.field_options?.actions?.includes(EntityPermissions.Change) &&
    values.field_options?.permissions?.includes(PermissionTypes.ChangeTimeOffPolicy)

  const onArchiveClick = () => {
    if (isArchived) {
      unarchive()
    } else {
      setArchiveWarningOpen(true)
    }
  }

  const archive = (archiveAssignments: boolean) => {
    setChangeStatus(archiveAssignments ? 'archive' : 'archive_with_assignments')

    archiveTimeOffPolicy(values.id, archiveAssignments)
      .then(result => {
        reset({ ...values, status: result.data.status })
      })
      .finally(() => {
        setArchiveWarningOpen(false)
        setChangeStatus('none')
      })
  }

  const unarchive = () => {
    setChangeStatus('unarchive')

    unarchiveTimeOffPolicy(values.id)
      .then(result => {
        reset({ ...values, status: result.data.status })
      })
      .finally(() => setChangeStatus('none'))
  }

  useEffect(() => {
    const interval = setInterval(() => {
      refetchProcessingStatus()
    }, REFETCH_INTERVAL)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <PageHeader
        pb="s-8"
        title={chain(
          initialValues?.name,
          <Text color={getStatusColor(initialValues.status?.id)}>
            {initialValues.status?.name}
          </Text>,
        )}
        backUrl={ROUTES.PEOPLE.TIME_OFF.POLICIES}
      />

      <PageBody>
        <SettingsButtons mb="s-16">
          <EditButton
            isVisible={canEdit}
            route={pathToUrl(ROUTES.FORMS.TIME_OFF_POLICY.EDIT.BASICS, { id: values.id })}
          />
          <DeleteButton
            backUrl={ROUTES.PEOPLE.TIME_OFF.POLICIES}
            deleteApi={timeOffPoliciesRequests.delete!}
            dialogProps={{
              body: `Are you sure you want to delete ${values.name} policy?`,
            }}
            isVisible={isDraft}
            title={values.name}
          />
          {canArchive ? (
            <MoreBar.Action
              onClick={onArchiveClick}
              useIcon={isArchived ? 'Unarchive' : 'Archive'}
              variant={isArchived ? 'primary' : 'negative'}
              pending={changeStatus !== 'none'}
            >
              {isArchived ? 'Unarchive' : 'Archive'}
            </MoreBar.Action>
          ) : null}
        </SettingsButtons>

        {processingStatus?.status?.id === 'creating_assignments' && (
          <PoliciesUpdatingWarning />
        )}

        <FormPreview title="General" data={initialValues}>
          <Group>
            <FormPreview.Item title="Policy name" field="name" />
            <FormPreview.Item
              title="Policy category"
              insert={() => {
                if (!initialValues.category) {
                  return '-'
                }
                return <TimeOffPolicyCategory category={initialValues.category} />
              }}
            />
            <FormPreview.Item<TimeOffPolicyInterface>
              title="Owner"
              field="owner"
              type="employee"
            />
            <FormPreview.Item title="Paid or unpaid" field="paid.name" />
            <FormPreview.Item title="Eligibility group" field="group.name" />
            <FormPreview.Item
              title="Automatic policy assignment"
              field="auto_assign_group"
              type="boolean"
            />
          </Group>
        </FormPreview>

        <Box mt="s-16">
          <FormPreview title="Request settings" data={initialValues}>
            <Group>
              <FormPreview.Item
                title="Approval required"
                field="is_approval_required"
                type="boolean"
              />
              <FormPreview.Item
                title="Attachment required"
                field="is_attachment_required"
                type="boolean"
              />
              <FormPreview.Item
                title="Comment required"
                field="is_comment_required"
                type="boolean"
              />
              <FormPreview.Details title="Instructions" field="request_instructions" />
              <FormPreview.Details<TimeOffPolicyInterface>
                title="Request types"
                insert={policy =>
                  policy.request_types?.length ? (
                    <Ul>
                      {policy.request_types.map((requestType, index) => (
                        <li key={index}>
                          {requestType.name} (
                          {pluralize(
                            policy.unit.name.toLowerCase(),
                            Number(requestType.amount),
                            true,
                          )}{' '}
                          allowed per cycle)
                        </li>
                      ))}
                    </Ul>
                  ) : (
                    '-'
                  )
                }
              />
              <FormPreview.Item
                title="Prorate requests with FTE"
                field="prorate_requests_with_fte"
                type="boolean"
              />
              <FormPreview.Item
                title="Maximum request duration"
                field="max_request_duration"
              />
            </Group>
          </FormPreview>
        </Box>

        <Box mt="s-16">
          <FormPreview title="Balance settings" data={initialValues}>
            <Group>
              <FormPreview.Item title="Unit" field="unit.name" />
              <FormPreview.Item title="Request minimum period" field="half_days.name" />
              <FormPreview.Item<TimeOffPolicyInterface>
                title="Balance type"
                field="balance_type.name"
                insert={policy => {
                  const type = policy.balance_type
                  switch (type.id) {
                    case 'limited':
                      return 'Tracked (limited)'
                    default:
                      return type?.name
                  }
                }}
              />
              {isLimitedBalance ? (
                <>
                  <FormPreview.Item
                    title="Negative balance allowed"
                    field="is_negative_balance_allowed"
                    type="boolean"
                  />
                  <FormPreview.Item
                    title="Can use leave not accrued"
                    field="can_use_leave_not_accrued"
                    type="boolean"
                  />
                  <FormPreview.Item title="Maximum balance" field="maximum_balance" />
                  <FormPreview.Item<TimeOffPolicyInterface>
                    title="Balance rounding"
                    insert={policy => {
                      return `${policy.balance_rounding_precision?.name} (${policy.balance_rounding_logic?.name})`
                    }}
                  />
                </>
              ) : null}
              <FormPreview.Item<TimeOffPolicyInterface>
                title="Balance closing"
                insert={policy => {
                  if (policy.balance_closing_period?.id === 'anniversary') {
                    return policy.balance_closing_period?.name
                  }
                  return `${policy.balance_closing_period?.name} on ${policy.balance_closing_period_month?.name} ${policy.balance_closing_period_day_of_month}`
                }}
              />
            </Group>
          </FormPreview>
        </Box>

        {isLimitedBalance ? (
          <>
            <Box mt="s-16">
              <FormPreview title="Accrual settings" data={initialValues}>
                <Group>
                  <FormPreview.Item<TimeOffPolicyInterface>
                    title="Accrual amount"
                    insert={policy => {
                      if (policy.unit?.name && policy.accrual_amount != null) {
                        return pluralize(
                          policy.unit.name.toLowerCase(),
                          policy.accrual_amount,
                          true,
                        )
                      }
                      return '-'
                    }}
                  />
                  <FormPreview.Item<TimeOffPolicyInterface>
                    title="Accrual cycle"
                    insert={policy => {
                      const period = (() => {
                        switch (policy.accrual_period?.id) {
                          case 'weekly':
                            return ` on ${policy.accrual_period_day_of_week?.name}`
                          case 'monthly':
                            return ` on ${policy.accrual_period_day_of_month}`
                          case 'yearly':
                            return ` on ${policy.accrual_period_month?.name} ${policy.accrual_period_day_of_month}`
                          default:
                            return ''
                        }
                      })()
                      return `${policy.accrual_period?.name}${period}`
                    }}
                  />
                  <FormPreview.Item title="First accrual" field="first_accrual.name" />
                  <FormPreview.Item
                    title="Prorate accrual with FTE"
                    field="prorate_accruals_with_fte"
                    type="boolean"
                  />
                  <FormPreview.Details<TimeOffPolicyInterface>
                    title="Additional accrual (based on group)"
                    insert={policy =>
                      policy.accrual_levels?.length ? (
                        <Ul>
                          {policy.accrual_levels
                            .filter(level => level.amount != null && level.group != null)
                            .map((level, index) => (
                              <li key={index}>
                                {level.amount} additional{' '}
                                {pluralize(
                                  policy.unit.name.toLowerCase(),
                                  Number(level.amount!),
                                )}{' '}
                                for members of {level.group?.name}
                              </li>
                            ))}
                        </Ul>
                      ) : (
                        '-'
                      )
                    }
                  />
                </Group>
              </FormPreview>
            </Box>

            <Box mt="s-16">
              <FormPreview title="Carry over settings" data={initialValues}>
                <Group>
                  <FormPreview.Item<TimeOffPolicyInterface>
                    title="Carry over limit"
                    insert={policy => {
                      switch (policy.carryover_limit?.id) {
                        case 'limited_number_of_days':
                          return `${policy.carryover_amount} days`
                        case 'limited_number_of_hours':
                          return `${policy.carryover_amount} hours`
                        default:
                          return policy.carryover_limit?.name
                      }
                    }}
                  />
                  <FormPreview.Item<TimeOffPolicyInterface>
                    title="Carry over expires"
                    insert={policy => {
                      if (
                        policy.carryover_expiry_month == null ||
                        policy.carryover_limit?.id === 'no_carryover'
                      ) {
                        return 'No'
                      }
                      return `Yes, on ${policy.carryover_expiry_month.name} ${policy.carryover_expiry_day_of_month}`
                    }}
                  />
                </Group>
              </FormPreview>
            </Box>
          </>
        ) : null}
      </PageBody>

      <Popup
        open={archiveWarningOpen}
        onClose={() => setArchiveWarningOpen(false)}
        variant="bottom-sheet"
      >
        <Header variant="item">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Archive policy</Header.Title>
        </Header>
        Do you also want to archive all policy assignments connected to this policy? If
        yes, all active assignments will be terminated and all open balances will be
        closed.
        <Popup.Actions horizontal>
          <Button
            variant="secondary"
            pending={changeStatus === 'archive_with_assignments'}
            onClick={() => archive(false)}
          >
            No
          </Button>
          <Button
            pending={changeStatus === 'archive'}
            elevated
            onClick={() => archive(true)}
          >
            Yes
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export default Preview
