import { Box } from '@revolut/ui-kit'
import React, { useEffect } from 'react'

import CareersApplicationCustomQuestions from './CareersApplicationCustomQuestions'
import SectionHead from '@src/pages/Careers/CareersApplication/components/SectionHead'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CareersApplicationInterface,
  CareersQuestionCheckbox,
  CareersQuestionOption,
} from '@src/interfaces/careers'
import { useParams } from 'react-router-dom'
import { getIdFromPositionSlug } from '@src/pages/Careers/utils'
import { useFetchAdditionalQuestions } from '@src/api/recruitment/careers'
import produce from 'immer'
import CareersQuestionsSkeleton from '@src/pages/Careers/CareersApplication/CareersQuestionsSkeleton'
import SectionLayout from './components/Section'

const CareersApplicationCustomSections = () => {
  const { id: slug } = useParams<{ id: string }>()
  const id = getIdFromPositionSlug(slug)
  const { values } = useLapeContext<CareersApplicationInterface>()
  const { data: questions, isLoading } = useFetchAdditionalQuestions(id)

  useEffect(() => {
    if (questions) {
      values.additional_questions = produce(questions, questionDraft => {
        questionDraft.sections = questionDraft.sections.filter(section =>
          section.questions.some(
            ({ locations }) =>
              locations.length === 0 ||
              locations.some(location =>
                values.preferred_locations?.some(
                  prefLocation => prefLocation.id === location,
                ),
              ),
          ),
        )

        questionDraft.sections.forEach(section => {
          const filledSection = values.additional_questions?.sections?.find(
            filledSectionItem =>
              filledSectionItem.internal_data_id === section.internal_data_id,
          )

          section.questions.forEach(question => {
            const filledQuestion = filledSection?.questions.find(
              filledQuestionItem =>
                filledQuestionItem.internal_data_id === question.internal_data_id,
            )

            if (filledQuestion) {
              if (question.internal_data_type === 'checkbox_application_form_question') {
                question.options = (filledQuestion as CareersQuestionCheckbox).options
              } else if (filledQuestion.answer) {
                question.answer = filledQuestion.answer as string | CareersQuestionOption
              }
            }
          })
        })
      })
    }
  }, [questions, values.preferred_locations])

  if (isLoading) {
    return <CareersQuestionsSkeleton />
  }

  return (
    <>
      {values.additional_questions?.sections?.map((section, index) => (
        <Box
          key={section.internal_data_id}
          data-testid={`section_${section.internal_data_id}`}
        >
          <SectionLayout
            header={<SectionHead title={section.title} text={section.description} />}
            body={
              <CareersApplicationCustomQuestions
                sectionId={index}
                questions={section.questions}
              />
            }
          />
        </Box>
      ))}
    </>
  )
}

export default connect(CareersApplicationCustomSections)
