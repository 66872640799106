import React, { useState } from 'react'
import { ActionButton, Bar, Box, VStack } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import EducationPopup from '@src/pages/Careers/CareersApplication/components/EducationPopup'
import {
  CareersApplicationEducationInterface,
  CareersApplicationInterface,
} from '@src/interfaces/careers'
import { useLapeContext } from '@src/features/Form/LapeForm'
import InformationSection from '@src/pages/Careers/CareersApplication/components/InformationSection'
import SectionHead from '@src/pages/Careers/CareersApplication/components/SectionHead'
import { useCareersApplicationSettings } from '@src/api/recruitment/careers'
import InputErrorMessage from '@components/InputErrorMessage/InputErrorMessage'
import SectionLayout from './components/Section'

type Props = {
  locked?: boolean
}

const CareersApplicationEducation = ({ locked }: Props) => {
  const { data: applicationSettings } = useCareersApplicationSettings()
  const { values, errors } = useLapeContext<CareersApplicationInterface>()
  const [popupOpen, setPopupOpen] =
    useState<{ idx?: number; data: Partial<CareersApplicationEducationInterface> }>()

  const closePopup = () => {
    setPopupOpen(undefined)
  }

  const onSave = (education: CareersApplicationEducationInterface) => {
    if (!values.educations) {
      values.educations = [education]
    } else if (popupOpen?.idx !== undefined) {
      values.educations[popupOpen.idx] = education
    } else {
      values.educations.push(education)
    }

    delete errors.educations
    closePopup()
  }

  return (
    <>
      {popupOpen && (
        <EducationPopup data={popupOpen.data} onClose={closePopup} onSubmit={onSave} />
      )}
      <Box data-testid="education" data-name="educations">
        <SectionLayout
          header={
            <SectionHead
              title="Education"
              subtitle={
                applicationSettings?.is_education_optional ? 'Optional' : undefined
              }
              text="Please enter the name of the universities you are affiliated with and select the major you graduated with"
              locked={locked}
            />
          }
          body={
            <>
              {!!values.educations?.length && (
                <VStack gap="s-16">
                  {values.educations.map((item, idx) => (
                    <InformationSection
                      key={idx}
                      title={item.university.name}
                      subtitle={item.major.name}
                      startDate={item.start_date}
                      endDate={item.end_date}
                      onRemove={() => {
                        if (values.educations?.length === 1) {
                          delete values.educations
                        } else {
                          values.educations = values.educations!.filter(
                            (_, itemIdx) => itemIdx !== idx,
                          )
                        }
                      }}
                      onEdit={() => {
                        setPopupOpen({ idx, data: item })
                      }}
                    />
                  ))}
                </VStack>
              )}

              <Bar>
                <ActionButton useIcon={Plus} onClick={() => setPopupOpen({ data: {} })}>
                  Add
                </ActionButton>
              </Bar>
              {errors.educations && <InputErrorMessage message={errors.educations} />}
            </>
          }
          locked={locked}
        />
      </Box>
    </>
  )
}

export default CareersApplicationEducation
