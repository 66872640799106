import React, { useRef, useState } from 'react'
import {
  ActionButton,
  ActionMenu,
  Avatar,
  Button,
  IconName,
  Item,
  ItemSkeleton,
  MoreBar,
  StatusPopup,
  Token,
  Tooltip,
  useStatusPopup,
  useTooltip,
} from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import { updateJobDescription } from '@src/api/jobPosting'
import { useGetJobPostingSettings, useGlobalSettings } from '@src/api/settings'
import PublishButton from '../Components/PublishButton'
import { useCareersSettings } from '@src/api/recruitment/careers'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useLapeContext } from '@src/features/Form/LapeForm'
import pluralize from 'pluralize'
import { RequisitionInterface } from '@src/interfaces/requisitions'

const PublishStatusIcon = ({ requisitionCount }: { requisitionCount: number }) => {
  const { values } = useLapeContext<JobPostingInterface>()
  if (!requisitionCount) {
    return <Avatar color={Token.color.red} useIcon="ExclamationTriangle" />
  }
  switch (values.status) {
    case PublishingStatuses.unpublished:
      return <Avatar useIcon="EyeHide" />
    case PublishingStatuses.closed:
      return <Avatar color={Token.color.orange} useIcon="ExclamationTriangle" />
    case PublishingStatuses.published_internally:
    case PublishingStatuses.published_externally:
    case PublishingStatuses.fully_published:
      return <Avatar color={Token.color.green} useIcon="CheckSuccess" />
    default:
      return null
  }
}

type PublishMenuProps = {
  label?: string
  useIcon?: IconName
}

const PublishMenu = ({ label, useIcon }: PublishMenuProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const statusPopup = useStatusPopup()
  const tooltip = useTooltip()
  const onSuccess = () => {
    statusPopup.show(
      <StatusPopup variant="success-result">
        <StatusPopup.Title>Updated job posting status</StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  const onError = (msg: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{msg}</StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  const handleChangeStage = async (status: PublishingStatuses) => {
    setLoading(true)
    try {
      const res = await updateJobDescription({ status }, { id: String(values.id) })
      values.status = res.data.status
      values.status_display = res.data.status_display
      onSuccess()
    } catch (error) {
      const errorsData = error?.response?.data
      const statusError = errorsData?.status?.[0]
      const compensationBandsError = errorsData?.salary_bands_by_location
        ? `Missing compensation data for: ${Object.keys(
            errorsData.salary_bands_by_location,
          ).join(', ')}`
        : undefined
      onError(
        statusError || compensationBandsError || 'Error updating job posting status',
      )
    } finally {
      setLoading(false)
    }
  }
  if (values.approval_status !== 'approved') {
    return (
      <>
        <MoreBar.Action
          useIcon={useIcon ?? 'EyeShow'}
          aria-disabled
          {...tooltip.getAnchorProps()}
        >
          {label ?? 'Publish'}
        </MoreBar.Action>
        <Tooltip {...tooltip.getTargetProps()}>
          Posting must be approved in order to be published
        </Tooltip>
      </>
    )
  }
  return (
    <>
      <MoreBar.Action
        useIcon={useIcon ?? 'EyeShow'}
        ref={anchorRef}
        onClick={() => {
          setOpen(!open)
        }}
        pending={loading}
        disabled={loading}
      >
        {label ?? 'Publish'}
      </MoreBar.Action>
      <ActionMenu open={open} anchorRef={anchorRef} onClose={() => setOpen(false)}>
        <ActionMenu.Group>
          <ActionMenu.Item
            useIcon="Globe"
            disabled={values.status === PublishingStatuses.fully_published}
            onClick={() => {
              handleChangeStage(PublishingStatuses.fully_published)
            }}
          >
            Publish Internally & Externally
          </ActionMenu.Item>
          <ActionMenu.Item
            useIcon="Services"
            disabled={values.status === PublishingStatuses.published_internally}
            onClick={() => {
              handleChangeStage(PublishingStatuses.published_internally)
            }}
          >
            Publish Internally
          </ActionMenu.Item>
          <ActionMenu.Item
            useIcon="16/LinkExternal"
            disabled={values.status === PublishingStatuses.published_externally}
            onClick={() => {
              handleChangeStage(PublishingStatuses.published_externally)
            }}
          >
            Publish Externally
          </ActionMenu.Item>
          {!(
            values.status === PublishingStatuses.closed ||
            values.status === PublishingStatuses.unpublished
          ) && (
            <ActionMenu.Item
              useIcon="EyeHide"
              color={Token.color.red}
              onClick={() => {
                handleChangeStage(PublishingStatuses.closed)
              }}
            >
              Unpublish
            </ActionMenu.Item>
          )}
        </ActionMenu.Group>
      </ActionMenu>
    </>
  )
}

type PublishActionProps = {
  label?: string
  useIcon?: IconName
}

export const PublishAction = ({ label, useIcon }: PublishActionProps) => {
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const { data: careerPageSettings } = useCareersSettings()
  if (!careerPageSettings?.publish_career_website) {
    return null
  }
  if (jobPostingSettings?.enable_all_publishing_statuses) {
    return <PublishMenu label={label} useIcon={useIcon} />
  }
  return <PublishButton />
}

type PublishStatusProps = {
  loading: boolean
  openRequisitionCount: number
  pendingRequisitions: RequisitionInterface[]
}

const PublishStatus = ({
  loading,
  openRequisitionCount,
  pendingRequisitions,
}: PublishStatusProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { isLoading: loadingGlobalSettings } = useGlobalSettings()
  const isCommercial = useIsCommercial()
  const { data, isLoading: loadingCareerSettings } = useCareersSettings()
  const pendingRequisitionCount = pendingRequisitions?.length ?? 0

  if (loadingGlobalSettings || loadingCareerSettings || loading) {
    return <ItemSkeleton />
  }

  if (isCommercial && !data?.publish_career_website) {
    return (
      <Item>
        <Item.Avatar>
          <Avatar color={Token.color.red} useIcon="ExclamationTriangle" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Careers website is not published</Item.Title>
          <Item.Description>
            The application form is currently unavailable to candidates. <br /> Please
            publish Careers website in the settings to start receiving applications.
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton
            useIcon="IndustrialGear"
            to={pathToUrl(ROUTES.SETTINGS.CAREER_PAGE)}
            use={InternalLink}
          >
            Go to Settings
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }
  const getDescription = () => {
    if (!openRequisitionCount) {
      if (!pendingRequisitionCount) {
        return 'Connect an open requisition to the job posting to publish it'
      }
      return 'Job posting requires at least one open requisition to be published'
    }
    switch (values.status) {
      case PublishingStatuses.unpublished:
        return 'Job posting is unpublished and not available for candidates'
      case PublishingStatuses.closed:
        return 'Job posting is not visible for candidates'
      case PublishingStatuses.published_internally:
      case PublishingStatuses.published_externally:
      case PublishingStatuses.fully_published:
        return values.status_display
      default:
        return null
    }
  }
  const isPublished =
    values.status !== PublishingStatuses.unpublished &&
    values.status !== PublishingStatuses.closed
  const getTitle = () => {
    if (!openRequisitionCount) {
      if (pendingRequisitionCount === 1) {
        return 'Connected requisition is not opened'
      }
      if (pendingRequisitionCount > 1) {
        return 'None of the connected requisitions are opened'
      }
      return 'No requisitions are connected'
    }
    return isPublished ? 'Published' : 'Not published'
  }
  const getAction = () => {
    if (openRequisitionCount) {
      return <PublishAction label="Update" useIcon="ChevronDown" />
    }
    if (pendingRequisitionCount) {
      const to =
        pendingRequisitionCount > 1
          ? ROUTES.FORMS.JOB_POSTING.REQUISITIONS
          : ROUTES.FORMS.REQUISITION.ROLE
      const params =
        pendingRequisitionCount > 1
          ? undefined
          : {
              id: pendingRequisitions[0]?.id,
            }
      return (
        <ActionButton
          useIcon="16/LinkExternal"
          size="sm"
          target="_blank"
          use={InternalLink}
          to={pathToUrl(to, params)}
        >
          {`View ${pluralize('requisition', pendingRequisitionCount)}`}
        </ActionButton>
      )
    }
    return (
      <ActionButton
        useIcon="16/LinkExternal"
        size="sm"
        target="_blank"
        use={InternalLink}
        to={pathToUrl(ROUTES.RECRUITMENT.REQUISITIONS)}
      >
        Go to requisitions
      </ActionButton>
    )
  }
  return (
    <Item>
      <Item.Avatar>
        <PublishStatusIcon requisitionCount={openRequisitionCount} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{getTitle()}</Item.Title>
        <Item.Description>{getDescription()}</Item.Description>
      </Item.Content>
      <Item.Side>{getAction()}</Item.Side>
    </Item>
  )
}

export default PublishStatus
