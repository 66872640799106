import React from 'react'
import { MoreBar, TableWidget } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import AdjustableTable from '@components/Table/AdjustableTable'
import Stat from '@components/Stat/Stat'
import { TableNames } from '@src/constants/table'
import { documentsBulkRequestsTableRequests } from '@src/api/documents'
import { DocumentsBulkRequestInterface } from '@src/interfaces/documents'
import {
  documentsBulkRequestsCreatedByColumn,
  documentsBulkRequestsCreatedOnColumn,
  documentsBulkRequestsEmployeesCountColumn,
  documentsBulkRequestsNameColumn,
  documentsBulkRequestsStatusColumn,
} from '@src/constants/columns/documentsBulkRequests'
import { InternalLink } from '@components/InternalLink/InternalLink'

const ROW: RowInterface<DocumentsBulkRequestInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.APPS.DOCUMENTS.BULK_REQUEST.PREVIEW, { id })),
  cells: [
    {
      ...documentsBulkRequestsNameColumn,
      width: 400,
    },
    {
      ...documentsBulkRequestsEmployeesCountColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestsCreatedByColumn,
      width: 300,
    },
    {
      ...documentsBulkRequestsCreatedOnColumn,
      width: 300,
    },
    {
      ...documentsBulkRequestsStatusColumn,
      width: 300,
    },
  ],
}

export const DocumentsBulkRequestsTable = () => {
  const permissions = useSelector(selectPermissions)
  const canAddBulkRequest = permissions?.includes(PermissionTypes.AddDocumentBulkRequest)

  const table = useTable<DocumentsBulkRequestInterface>(
    documentsBulkRequestsTableRequests,
  )

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat label="Total" val={table.loading ? undefined : table.count} mb="s-16" />
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          {canAddBulkRequest && (
            <MoreBar.Action
              useIcon="Plus"
              use={InternalLink}
              to={pathToUrl(ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.BASICS)}
            >
              Add new
            </MoreBar.Action>
          )}
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable
          name={TableNames.DocumentRules}
          useWindowScroll
          row={ROW}
          {...table}
          noDataMessage="All bulk requests will appear here"
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
