import React from 'react'
import { BaseWidget } from '../BaseWidget'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import { useGetGoal } from '@src/api/goals'
import { Spinner, TableWidget } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  goalTargetCurrentValueColumn,
  goalTargetInitialValueColumn,
  goalTargetNameColumn,
  goalTargetPerformanceColumn,
  goalTargetReviewCycleColumn,
  goalTargetTargetValueColumn,
  goalTargetUnitColumn,
  goalTargetUpdateTypeColumn,
} from '@src/constants/columns/goals'

export const ParentTargetsWidget = () => {
  const { values } = useLapeContext<GoalsInterface>()
  const { data: parent, isLoading } = useGetGoal(values.parent?.id)

  return (
    <BaseWidget
      title="Parent targets"
      icon="TurboTransfer"
      emptyState={{
        description: 'Progress will be measured based on parent goal performance',
        icon: 'TurboTransfer',
      }}
    >
      {isLoading && <Spinner />}
      {parent?.kpis?.length ? (
        <TableWidget style={{ padding: 0 }}>
          <TableWidget.Table>
            <AdjustableTable<GoalKpiDetails>
              rowHeight="medium"
              hideCountAndButtonSection
              childrenOpenByDefault
              expandableType="chevron"
              name={TableNames.KPIs}
              dataType="Target"
              row={{
                cells: [
                  {
                    ...goalTargetNameColumn,
                    width: 300,
                  },
                  {
                    ...goalTargetReviewCycleColumn,
                    width: 100,
                  },
                  {
                    ...goalTargetUpdateTypeColumn,
                    width: 100,
                  },
                  {
                    ...goalTargetPerformanceColumn,
                    width: 100,
                  },
                  {
                    ...goalTargetInitialValueColumn,
                    width: 80,
                  },
                  {
                    ...goalTargetCurrentValueColumn,
                    width: 80,
                  },
                  {
                    ...goalTargetTargetValueColumn,
                    width: 80,
                  },
                  {
                    ...goalTargetUnitColumn,
                    width: 100,
                  },
                ],
                noChildrenRequest: true,
              }}
              data={parent.kpis}
              count={parent.kpis.length}
            />
          </TableWidget.Table>
        </TableWidget>
      ) : null}
    </BaseWidget>
  )
}
