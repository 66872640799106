import React, { useState } from 'react'

import {
  documentDraftFormRequests,
  documentsBulkRequestFormRequests,
} from '@src/api/documents'
import { DocumentDraftInterface } from '@src/interfaces/documents'
import Form from '@src/features/Form/Form'
import { PageBody } from '@src/components/Page/PageBody'
import { CommonTabProps } from '../../common'
import { NewDocumentSidebar } from './NewDocumentSidebar'
import { BasicsTabContent } from './BasicsTabContent'

export const Basics = (props: CommonTabProps) => {
  const [activeSidebar, setActiveSidebar] = useState<'template' | 'document'>()

  const [pendingDocuments, setPendingDocuments] = useState<DocumentDraftInterface[]>([])

  return (
    <>
      <PageBody>
        <Form disableLocalStorageCaching api={documentsBulkRequestFormRequests}>
          <BasicsTabContent
            {...props}
            setIsNewDocumentSidebarOpen={() => setActiveSidebar('document')}
            isTemplatesSidebarOpen={activeSidebar === 'template'}
            setIsTemplatesSidebarOpen={() => setActiveSidebar('template')}
            onCloseTemplatesSidebar={() => setActiveSidebar(undefined)}
            pendingDocuments={pendingDocuments}
            removePendingDocumentById={(documentId: number) =>
              setPendingDocuments(pendingDocuments.filter(d => d.id !== documentId))
            }
          />
        </Form>
      </PageBody>
      {activeSidebar === 'document' && (
        <Form
          disableLocalStorageCaching
          forceParams={{ id: undefined }}
          api={documentDraftFormRequests}
        >
          <NewDocumentSidebar
            isOpen={activeSidebar === 'document'}
            onClose={() => setActiveSidebar(undefined)}
            onDocumentCreated={(newDocument: DocumentDraftInterface) =>
              setPendingDocuments([...pendingDocuments, newDocument])
            }
          />
        </Form>
      )}
    </>
  )
}
