import {
  Box,
  Cell,
  Flex,
  Tag,
  VStack,
  Text,
  HStack,
  MoreBar,
  Token,
  Icon,
  BottomSheet,
  TextButton,
  chain,
  TextSkeleton,
} from '@revolut/ui-kit'
import { getStatusColor } from '@src/components/CommonSC/General'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  approveGoal,
  archiveGoal,
  calibrateGoal,
  deleteGoal,
  goalsTimelineTableRequests,
  refreshGoalProgress,
  requestChange,
  unarchiveGoal,
  useGetGoals,
} from '@src/api/goals'
import { IdAndName, Statuses } from '@src/interfaces'
import { GoalPreviewInterface, GoalsInterface } from '@src/interfaces/goals'
import React, { ReactNode, useState } from 'react'
import { RequestChange } from './RequestChange'
import { SetFinalProgress } from './SetFinalProgress'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { PermissionTypes } from '@src/store/auth/types'
import { captureException } from '@sentry/react'
import { useLocation } from 'react-router-dom'
import { useSubmitFlowHelpers } from '../../common/utils'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { GoalTimeline } from './GoalTimeline'
import { useTable } from '@src/components/Table/hooks'
import SideBar from '@src/components/SideBar/SideBar'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const useGoalActionPermissions = (goal: GoalsInterface) => {
  const type = goal.content_type?.model

  const isAllowed = (key: keyof typeof PermissionTypes): boolean =>
    !!goal.field_options?.permissions?.includes(PermissionTypes[key])

  if (goal.is_company) {
    return {
      approve: isAllowed('CanApproveGoal'),
      requireChange: isAllowed('CanRejectGoal'),
      changeGoal: isAllowed('CanChangeCompanyGoal'),
      updateStatus: isAllowed('CanChangeCompanyGoal'),
      calibrate: isAllowed('CanChangeCompanyGoal'),
      refresh: isAllowed('CanChangeCompanyGoal'),
      archive: isAllowed('CanChangeCompanyGoal'),
      unarchive: isAllowed('CanChangeCompanyGoal'),
      delete: isAllowed('CanDeleteCompanyGoal'),
    }
  }

  switch (type) {
    case 'employees':
      return {
        approve: isAllowed('CanApproveGoal'),
        requireChange: isAllowed('CanRejectGoal'),
        changeGoal: isAllowed('CanChangeEmployeeGoal'),
        updateStatus: isAllowed('CanChangeEmployeeGoal'),
        calibrate: isAllowed('CanChangeEmployeeGoal'),
        refresh: isAllowed('CanChangeEmployeeGoal'),
        archive: isAllowed('CanChangeEmployeeGoal'),
        unarchive: isAllowed('CanChangeEmployeeGoal'),
        delete: isAllowed('CanDeleteEmployeeGoal'),
      }
    case 'teams':
      return {
        approve: isAllowed('CanApproveGoal'),
        requireChange: isAllowed('CanRejectGoal'),
        changeGoal: isAllowed('CanChangeTeamGoal'),
        updateStatus: isAllowed('CanChangeTeamGoal'),
        calibrate: isAllowed('CanChangeTeamGoal'),
        refresh: isAllowed('CanChangeTeamGoal'),
        archive: isAllowed('CanChangeTeamGoal'),
        unarchive: isAllowed('CanChangeTeamGoal'),
        delete: isAllowed('CanDeleteTeamGoal'),
      }
    case 'department':
      return {
        approve: isAllowed('CanApproveGoal'),
        requireChange: isAllowed('CanRejectGoal'),
        changeGoal: isAllowed('CanChangeDepartmentGoal'),
        updateStatus: isAllowed('CanChangeDepartmentGoal'),
        calibrate: isAllowed('CanChangeDepartmentGoal'),
        refresh: isAllowed('CanChangeDepartmentGoal'),
        archive: isAllowed('CanChangeDepartmentGoal'),
        unarchive: isAllowed('CanChangeDepartmentGoal'),
        delete: isAllowed('CanDeleteDepartmentGoal'),
      }
    default:
      return {
        approve: false,
        requireChange: false,
        changeGoal: false,
        updateStatus: false,
        calibrate: false,
        refresh: false,
        archive: false,
        unarchive: false,
        delete: false,
      }
  }
}

const HeaderBanner = ({
  title,
  status,
  subtitle,
  actions,
}: {
  title: ReactNode
  status: IdAndName<Statuses | ApprovalStatuses>
  subtitle: ReactNode
  actions: ReactNode
}) => {
  const statusBadge = (
    <Tag
      variant="outlined"
      color={getStatusColor(status.id) || Token.color.greyTone50}
      data-testid="goal-status"
    >
      {status.name}
    </Tag>
  )

  return (
    <Box mt="s-8">
      <Cell pt="s-24" px="s-16" pb="s-16">
        <VStack gap="s-16" overflow="hidden">
          <Flex flex={1} alignItems="center" gap="s-16">
            <VStack gap="s-4">
              <Text variant="h1" whiteSpace="pre-wrap">
                {title}
              </Text>
              <HStack space="s-8" align="center">
                {statusBadge}
                {subtitle}
              </HStack>
            </VStack>
          </Flex>
          {actions}
        </VStack>
      </Cell>
    </Box>
  )
}

const searchParamsToObject = (search: URLSearchParams) => {
  return [...search.entries()].reduce((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {} as Record<string, string>)
}

const GoalsSwitch = ({ currentGoalId }: { currentGoalId: number }) => {
  const location = useLocation()
  const search = location.search
  const { data, isLoading } = useGetGoals(search || undefined)
  const goals = data?.results || []
  const searchParams = searchParamsToObject(new URLSearchParams(search))

  const currentIndex = goals.findIndex(g => g.id === currentGoalId)

  const getNextGoal = (increment: 1 | -1) => {
    const nextIndex = currentIndex + increment

    if (nextIndex >= 0 && nextIndex < goals.length) {
      return goals[nextIndex]
    }

    return increment === 1 ? goals[0] : goals.at(-1)
  }

  const switchTo = (increment: 1 | -1) => {
    const nextGoal = getNextGoal(increment)

    if (nextGoal) {
      const url = pathToUrl(
        ROUTES.FORMS.GOAL.PREVIEW,
        { id: String(nextGoal.id) },
        searchParams,
      )
      navigateReplace(url)
    }
  }

  return goals.length > 1 ? (
    <Cell>
      <Flex justifyContent="space-between" width="100%">
        {isLoading ? (
          <TextSkeleton width={150} />
        ) : (
          <Text variant="primary" color={Token.color.greyTone50}>
            {chain('Goals', `${currentIndex + 1}/${goals.length}`)}
          </Text>
        )}

        <HStack space="s-8">
          <TextButton
            color={Token.color.greyTone50}
            aria-label="switch to previous goal"
            onClick={() => switchTo(-1)}
          >
            <Icon name="ChevronLeft" />
          </TextButton>
          <TextButton
            aria-label="switch to next goal"
            color={Token.color.greyTone50}
            onClick={() => switchTo(1)}
          >
            <Icon name="ChevronRight" />
          </TextButton>
        </HStack>
      </Flex>
    </Cell>
  ) : null
}

type SimpleAction = 'refresh' | 'archive' | 'unarchive'
type Action =
  | 'approve'
  | 'request_change'
  | 'update_status'
  | 'calibration'
  | 'delete'
  | SimpleAction
type AvailablePopups = 'request-change' | 'calibration'
type AvailableSidebars = 'timeline' | 'details'

export const GoalsPreviewHeader = ({ cycle }: { cycle: ReviewCyclesInterface }) => {
  const { values, reset } = useLapeContext<GoalPreviewInterface>()
  const [pending, setPending] = useState<Action>()
  const [shownPopup, setShownPopup] = useState<AvailablePopups>()
  const closePopup = () => setShownPopup(undefined)
  const { confirm, confirmationDialog, showError, showSuccess } = useSubmitFlowHelpers()
  const timeline = useTable(goalsTimelineTableRequests(values.id), [], [], {
    disableQuery: true,
  })
  const [sidebar, setSidebar] = useState<AvailableSidebars>()

  const { entity, getEntityIcon, getHomeRoute } = useOrgEntity()

  const getDefaultBackRoute = () => {
    if (values.is_company) {
      return ROUTES.FORMS.COMPANY.GOALS.GENERAL
    }

    switch (values.content_type?.model) {
      case 'employees':
        return ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.GENERAL
      case 'teams':
        return ROUTES.FORMS.TEAM.GOALS.GENERAL
      case 'department':
        return ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL
      default:
        return null
    }
  }

  const backRoute = getDefaultBackRoute()

  const backUrl = backRoute ? pathToUrl(backRoute, { id: values.object_id }) : ROUTES.MAIN

  const approvalStatus = values.approval_status
  const isPendingApproval = approvalStatus.id === ApprovalStatuses.Pending
  const isNotApproved = approvalStatus.id !== ApprovalStatuses.Approved
  const isApproved = approvalStatus.id === ApprovalStatuses.Approved
  const isArchived = values.status.id === Statuses.archived
  const isDraft = values.status.id === Statuses.draft

  const isNotArchivedOrDraft = !(isArchived || isDraft)

  const accessControl = useGoalActionPermissions(values)

  const simpleAction = (action: SimpleAction) => async () => {
    const actions = {
      refresh: {
        cb: refreshGoalProgress,
        messages: ['Progress refreshed', 'Failed to refresh progress'],
      },

      archive: {
        cb: archiveGoal,
        messages: ['Goal archived', 'Failed to archive goal'],
      },

      unarchive: {
        cb: unarchiveGoal,
        messages: ['Goal unarchived', 'Failed to unarchive goal'],
      },
    }

    const {
      cb,
      messages: [successMessage, errorMessage],
    } = actions[action]

    try {
      setPending(action)
      const response = await cb(values.id)

      timeline.refresh()

      reset({
        ...values,
        ...response.data,
      })

      showSuccess('Success', successMessage)
    } catch (err) {
      captureException(err)
      showError(errorMessage, 'Please, try again')
    } finally {
      setPending(undefined)
    }
  }

  const handleDelete = async () => {
    try {
      const confirmed = await confirm({
        variant: 'compact',
        label: 'Confirm delete',
        body: 'Are you sure you want to delete this goal?',
        yesBtnVariant: 'negative',
      })

      if (confirmed.status === 'confirmed') {
        setPending('delete')

        await deleteGoal(values.id)

        navigateReplace(backUrl, {}, true)
      }
    } catch (err) {
      captureException(err)
      showError('Failed to delete goal', 'Please, try again')
    }
  }

  const actions = (
    <MoreBar maxCount={5}>
      {accessControl.approve && isNotApproved && isNotArchivedOrDraft && (
        <MoreBar.Action
          variant="accent"
          useIcon="Check"
          pending={pending === 'approve'}
          disabled={!!pending}
          onClick={async () => {
            setPending('approve')
            try {
              await approveGoal(values.id)
              values.approval_status = { id: ApprovalStatuses.Approved, name: 'Approved' }
              showSuccess('Goal approved')
              timeline.refresh()
            } catch (err) {
              showError('Failed to approve', 'Something went wrong. Please try again.')
            } finally {
              setPending(undefined)
            }
          }}
        >
          Approve goal
        </MoreBar.Action>
      )}
      {accessControl.requireChange && isPendingApproval && isNotArchivedOrDraft && (
        <MoreBar.Action
          disabled={!!pending}
          onClick={() => setShownPopup('request-change')}
          pending={pending === 'request_change'}
          useIcon="Cross"
          variant="negative"
        >
          Request changes
        </MoreBar.Action>
      )}
      {accessControl.changeGoal && (
        <MoreBar.Action
          disabled={!!pending}
          pending={!entity}
          useIcon="Pencil"
          onClick={() => {
            navigateTo(pathToUrl(ROUTES.FORMS.GOAL.EDIT, { id: values.id }), { entity })
          }}
        >
          Edit goal
        </MoreBar.Action>
      )}
      {accessControl.refresh && isNotArchivedOrDraft && (
        <MoreBar.Action
          disabled={!!pending}
          pending={!entity || pending === 'refresh'}
          useIcon="ArrowExchange"
          onClick={simpleAction('refresh')}
        >
          Refresh progress
        </MoreBar.Action>
      )}
      {!!timeline.count && (
        <MoreBar.Action useIcon="History" onClick={() => setSidebar('timeline')}>
          {chain('Show history', timeline.count)}
        </MoreBar.Action>
      )}
      {accessControl.calibrate && isApproved && isNotArchivedOrDraft && (
        <MoreBar.Action
          useIcon="Pencil"
          pending={pending === 'calibration'}
          onClick={() => setShownPopup('calibration')}
        >
          Set final progress
        </MoreBar.Action>
      )}
      {accessControl.archive && isNotArchivedOrDraft && (
        <MoreBar.Action
          disabled={!!pending}
          pending={!entity}
          useIcon="Archive"
          variant="negative"
          onClick={simpleAction('archive')}
        >
          Archive
        </MoreBar.Action>
      )}
      {accessControl.unarchive && isArchived && (
        <MoreBar.Action
          disabled={!!pending}
          pending={!entity}
          useIcon="Unarchive"
          onClick={simpleAction('unarchive')}
        >
          Unarchive
        </MoreBar.Action>
      )}
      {accessControl.delete && (
        <MoreBar.Action
          disabled={!!pending}
          pending={!entity}
          useIcon="Delete"
          variant="negative"
          onClick={handleDelete}
        >
          Delete
        </MoreBar.Action>
      )}
    </MoreBar>
  )

  const homeEntityPath = getHomeRoute()
  const subtitle = (
    <HStack space="s-8" align="center">
      <TextButton
        color={Token.color.greyTone50}
        use={InternalLink}
        to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
          id: values.owner.id,
        })}
        target="_blank"
      >
        <HStack space="s-4" align="center">
          <Icon name="Profile" size={16} />
          <Text variant="caption">{values.owner.display_name}</Text>
        </HStack>
      </TextButton>

      {values.is_company || values.content_type?.model === 'employees' ? null : (
        <TextButton
          color={Token.color.greyTone50}
          use={InternalLink}
          to={
            homeEntityPath
              ? pathToUrl(homeEntityPath, {
                  id: values.content_object.id,
                })
              : undefined
          }
          target="_blank"
        >
          <HStack space="s-4" align="center">
            <Icon name={getEntityIcon()} size={16} />
            <Text variant="caption">{values.content_object.name}</Text>
          </HStack>
        </TextButton>
      )}
    </HStack>
  )

  return (
    <>
      <PageHeader
        backUrl={backUrl}
        title={
          <>
            <GoalsSwitch currentGoalId={values.id} />
            <HeaderBanner
              title={
                values.name || <Text color={Token.color.greyTone20}>[Unnamed goal]</Text>
              }
              status={values.status}
              actions={actions}
              subtitle={subtitle}
            />
          </>
        }
      />
      <BottomSheet open={shownPopup === 'request-change'} onClose={closePopup}>
        <RequestChange
          onCancel={closePopup}
          onSubmit={async comment => {
            closePopup()
            setPending('request_change')
            try {
              await requestChange(values.id, comment)
              values.approval_status = {
                id: ApprovalStatuses.RequiresChanges,
                name: 'Requires changes',
              }
              timeline.refresh()
            } catch (err) {
              showError(
                'Failed to request change',
                'Something went wrong. Please try again.',
              )
            } finally {
              setPending(undefined)
            }
          }}
        />
      </BottomSheet>
      <BottomSheet open={shownPopup === 'calibration'} onClose={closePopup}>
        <SetFinalProgress
          onCancel={closePopup}
          onSubmit={async (status, progress, comment) => {
            closePopup()
            setPending('calibration')
            try {
              const { data } = await calibrateGoal(values.id, {
                calibrated_progress: progress,
                status,
                comment,
                review_cycle: { id: String(cycle.id) },
              })
              values.status = data.status
              values.calibrated_progress = data.calibrated_progress
              values.goal_cycles = values.goal_cycles.map(item =>
                item.review_cycle.id === cycle.id
                  ? { ...item, calibrated_progress: data.calibrated_progress }
                  : item,
              )
              showSuccess('Progress updated.')
              timeline.refresh()
            } catch (err) {
              captureException(err)
              showError(
                'Failed to submit calibrated progress',
                'Something went wrong. Please try again.',
              )
            } finally {
              setPending(undefined)
            }
          }}
        />
      </BottomSheet>
      <SideBar
        useLayout
        title="Goal timeline"
        isOpen={sidebar === 'timeline'}
        onClose={() => setSidebar(undefined)}
      >
        <GoalTimeline timeline={timeline} />
      </SideBar>
      {confirmationDialog}
    </>
  )
}
