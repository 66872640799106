import { filterSortPageIntoQuery } from '../utils/table'
import { api, apiWithoutHandling } from './index'
import { API } from '../constants/api'
import { GetRequestData, RequestInterface, RequestInterfaceNew } from '../interfaces'
import { useFetch } from '@src/utils/reactQuery'
import { FullJobPostingInterface, JobPostingInterface } from '@src/interfaces/jobPosting'
import { SidebarJobDescription } from '@src/interfaces/jobDescription'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { FilterByInterface } from '@src/interfaces/data'

export const jobPostingsRequests: RequestInterface<JobPostingInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.JOB_POSTING, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.JOB_POSTING}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.JOB_POSTING}/${id}`, data),
  deleteItem: async id => api.delete(`${API.JOB_POSTING}/${id}`),
  postItem: async data => api.post(API.JOB_POSTING, data),
}

export const useGetJobPostings = (filters: FilterByInterface[], enabled?: boolean) => {
  const context = useFetch<GetRequestData<JobPostingInterface>>(
    API.JOB_POSTING,
    undefined,
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
    undefined,
    {
      enabled,
    },
  )

  return { ...context, data: context.data?.results }
}

export const useGetJobPosting = (id?: number | string) =>
  useFetch<JobPostingInterface>(id ? `${API.JOB_POSTING}/${id}` : null)

export const useGetRoleJobDescription = (id: number | null) =>
  useFetch<SidebarJobDescription[]>(id ? `${API.ROLES}/${id}/jobDescription` : null)

export const useGetSpecialisationJobDescription = (id: number | null) =>
  useFetch<FullJobPostingInterface>(
    id ? `${API.SPECIALISATIONS}/${id}/jobDescription` : null,
  )

export const useGetJobDescription = (id?: number) =>
  useFetch<FullJobPostingInterface>(
    id ? `${API.JOB_POSTING}/${id}/fullPosting` : null,
    undefined,
    undefined,
    undefined,
    {
      staleTime: 3000,
    },
  )

export const jobDescriptionFormRequest: RequestInterfaceNew<JobPostingInterface> = {
  get: async ({ id }) => apiWithoutHandling.get(`${API.JOB_POSTING}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.JOB_POSTING}/${id}`, data),
  submit: async data => apiWithoutHandling.post(`${API.JOB_POSTING}`, data),
}

export const updateJobDescription = jobDescriptionFormRequest.update

export const useGetJobDescriptionApprovals = (id?: number) => {
  return useFetch<ApprovalFlowResponse>(id ? `${API.JOB_POSTING}/${id}/approvals` : null)
}
