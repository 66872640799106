import React, { useState } from 'react'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import {
  Subheader,
  Status,
  Sticky,
  ProgressSteps,
  ProgressStep,
  Widget,
  Group,
  Box,
  BREAKPOINTS,
  Tag,
} from '@revolut/ui-kit'
import { PageWrapper } from '@components/Page/Page'
import { useGetTicket, getTicketsCommentsAPI } from '@src/api/tickets'
import { useParams } from 'react-router'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import styled from 'styled-components'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { TicketFullInterface, ticketStatuses } from '@src/interfaces/tickets'
import upperFirst from 'lodash/upperFirst'
import { formatDateTime } from '@src/utils/format'
import { statusColorMap } from '@src/pages/Tickets/common'
import { TicketFeedback } from '@src/pages/Tickets/Ticket/TicketFeedback'
import { TicketFeedbackRatePopup } from '@src/pages/Tickets/Ticket/TicketFeedbackRatePopup'
import CommentsSection from '@src/features/Comments/CommentsSection'

const StyledSticky = styled(Sticky)`
  max-height: calc(100vh - 80px);
  overflow: scroll;
  scrollbar-width: none;
`

export const Ticket = () => {
  const { id } = useParams<{ id?: string }>()
  const { data: ticket, refetch } = useGetTicket(id)

  const [ratePopupOpen, setRatePopupOpen] = useState(false)

  const commentsApi = getTicketsCommentsAPI(Number(id))

  if (!ticket) {
    return null
  }

  return (
    <>
      <PageWrapper>
        <PageHeader
          title={ticket.title}
          subtitle={
            <Status
              useIcon="16/StatusActive"
              iconColor={statusColorMap[ticket.current_status.status]}
              color={statusColorMap[ticket.current_status.status]}
            >
              {upperFirst(ticket.current_status.status)}
            </Status>
          }
          backUrl={ROUTES.TICKETS.ALL}
        />

        <TwoColumnsLayout
          left={
            <>
              <TicketFeedback
                ticket={ticket}
                refetch={refetch}
                openRatePopup={() => setRatePopupOpen(true)}
              />
              <Widget>
                <FormPreview<TicketFullInterface>
                  data={ticket}
                  customHeader={() => (
                    <Box px="s-16">
                      <Subheader variant="nested">
                        <Subheader.Title>Details</Subheader.Title>
                      </Subheader>
                    </Box>
                  )}
                >
                  <Group>
                    <FormPreview.Item field="url" title="URL" />
                    <FormPreview.Details field="text" title="Description" />
                    {!!ticket.jira_url && !!ticket.jira_id ? (
                      <FormPreview.Item
                        title="Jira ticket link"
                        insert={() => (
                          <>
                            <Tag
                              use="a"
                              target="_blank"
                              useIcon="Jira|image"
                              variant="faded"
                              href={ticket.jira_url!}
                            >
                              {ticket.jira_id}
                            </Tag>
                          </>
                        )}
                      />
                    ) : null}
                  </Group>
                </FormPreview>
              </Widget>

              <CommentsSection api={commentsApi} disableTodolistFeature />
            </>
          }
          right={
            <StyledSticky top={70}>
              <Widget>
                <Box px="s-16">
                  <Subheader variant="nested">
                    <Subheader.Title>Report status</Subheader.Title>
                  </Subheader>
                </Box>

                <ProgressSteps variant="vertical-compact">
                  {ticketStatuses.map(status => {
                    const ticketStatus = ticket.status_list.find(s => s.status === status)
                    return (
                      <ProgressStep
                        state={ticketStatus ? 'done' : undefined}
                        key={status}
                        indicatorColor={ticketStatus ? 'blue' : undefined}
                      >
                        <ProgressStep.Title>{upperFirst(status)}</ProgressStep.Title>
                        <ProgressStep.Description>
                          {ticketStatus
                            ? formatDateTime(ticketStatus.creation_date_time)
                            : '-'}
                        </ProgressStep.Description>
                      </ProgressStep>
                    )
                  })}
                </ProgressSteps>
              </Widget>
            </StyledSticky>
          }
          leftMaxWidth={BREAKPOINTS.md}
          rightMaxWidth={350}
        />
      </PageWrapper>

      <TicketFeedbackRatePopup
        ticketId={ticket.id}
        onClose={() => setRatePopupOpen(false)}
        isOpen={ratePopupOpen}
      />
    </>
  )
}
