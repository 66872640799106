import React, { useState } from 'react'
import { Box, TransitionCollapse, VStack, Widget } from '@revolut/ui-kit'

type SectionLayoutProps = {
  body: React.ReactNode
  header: React.ReactNode
  locked?: boolean
}

const Layout = ({ body, header }: Omit<SectionLayoutProps, 'locked'>) => {
  return (
    <Widget p="s-16">
      <VStack gap="s-16">
        {header}
        {body}
      </VStack>
    </Widget>
  )
}

const SectionLayout = ({ body, header, locked }: SectionLayoutProps) => {
  const [expand, setExpand] = useState(!locked)
  if (locked) {
    return (
      <Layout
        header={
          <Box
            onClick={() => {
              setExpand(!expand)
            }}
          >
            {header}
          </Box>
        }
        body={<TransitionCollapse in={expand}>{body}</TransitionCollapse>}
      />
    )
  }
  return <Layout header={header} body={body} />
}

export default SectionLayout
