import React, { useEffect, useRef } from 'react'
import {
  KeeperQuestionInterface,
  PipQuestionInterface,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { get, set } from 'lodash'
import { Avatar, FilterButton, Flex, Text, VStack, Widget } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { OptionInterface } from '@src/interfaces/selectors'
import BottomText from '@components/Inputs/partials/BottomText'
import { useGetProbationMissingValues } from '@src/pages/Forms/ProbationLayout/utils'
import { shouldScrollToError } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { notReachable } from '@src/utils/notReachable'

interface Props {
  isViewMode: boolean
  type: RecommendationTypes
  questions?: PipQuestionInterface[] | KeeperQuestionInterface[]
  field: string
  showBeforeSubmitCheckError?: boolean
}

export const BarRaiser = connect(
  ({ isViewMode, questions, type, field, showBeforeSubmitCheckError }: Props) => {
    const { values, errors, submitFailed } = useLapeContext<ReviewScorecardInterface>()
    const ref = useRef<HTMLDivElement>(null)
    const { options } = useFetchOptions<OptionInterface>(
      selectorKeys.yes_no_range_options,
    )
    const { missingBarRaiser, barRaiserValues } = useGetProbationMissingValues(values)
    const showMissingError = showBeforeSubmitCheckError && missingBarRaiser

    useEffect(() => {
      const shouldScroll = shouldScrollToError(errors, field)
      if (shouldScroll || showMissingError) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data, field, showMissingError])

    if (!questions?.length) {
      return null
    }

    const onKeeperChange = (value: string, num: number) => {
      let key

      switch (type) {
        case RecommendationTypes.LM:
          key = `review_data.line_manager_extra_section.keeper_test_section.questions.${num}.value`
          break

        case RecommendationTypes.FM:
          key = `review_data.functional_manager_extra_section.keeper_test_section.questions.${num}.value`
          break

        case RecommendationTypes.PEER:
          key = `review_data.peer_extra_section.keeper_test_section.questions.${num}.value`
          break

        case RecommendationTypes.PIP:
          key = `review_data.recommendation_extra_section.recommendation.${num}.value`
          break

        case RecommendationTypes.PIP_V1:
          key = `review_data.pip_extra_section.pip_recommendation.${num}.value`
          break

        default:
          notReachable(type)
      }

      if (key) {
        set(values, key, value)
      }
    }

    const renderError = (num: number) => {
      if (!submitFailed && !showMissingError) {
        return null
      }

      let key

      switch (type) {
        case RecommendationTypes.LM:
          key = `review_data.line_manager_extra_section.keeper_test_section.questions.${num}.value`
          break

        case RecommendationTypes.FM:
          key = `review_data.functional_manager_extra_section.keeper_test_section.questions.${num}.value`
          break

        case RecommendationTypes.PEER:
          key = `review_data.peer_extra_section.keeper_test_section.questions.${num}.value`
          break

        case RecommendationTypes.PIP:
          key = `review_data.recommendation_extra_section.recommendation.${num}.value`
          break

        case RecommendationTypes.PIP_V1:
          key = `review_data.pip_extra_section.pip_recommendation.${num}.value`
          break

        default:
          return notReachable(type)
      }

      if (showMissingError && !barRaiserValues?.[num]) {
        return <BottomText error="This field may not be empty." />
      }

      if (!get(errors, key) || get(values, key)) {
        return null
      }

      return <BottomText error="This field may not be empty." />
    }

    return (
      <>
        <Widget p="s-16">
          <VStack space="s-32" ref={ref}>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex alignItems="center" gap="s-16" width="30%">
                <Avatar useIcon="HelpChat" />
                <Text variant="primary">Bar raiser</Text>
              </Flex>
            </Flex>
            {questions.map((question, num) => {
              const questionTitle = question.text || question.name || question.title
              const questionOptions =
                'options' in question
                  ? question.options.map(option => ({ value: option }))
                  : options
              return (
                <VStack space="s-16" key={num} data-testid={`bar-raiser-${num}`}>
                  <Text variant="primary">{questionTitle}</Text>
                  {renderError(num)}
                  <Flex justifyContent="left" gap="s-8">
                    {questionOptions.map(option => (
                      <FilterButton
                        onClick={() =>
                          !isViewMode && onKeeperChange(String(option.value.id), num)
                        }
                        active={question.value === option.value.id}
                        style={{ height: '30px' }}
                        key={option.value.id}
                      >
                        {option.value.name}
                      </FilterButton>
                    ))}
                  </Flex>
                </VStack>
              )
            })}
          </VStack>
        </Widget>
      </>
    )
  },
)
